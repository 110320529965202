import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import ListItemCourse from "../../components/widgets/list/ListItemCourse";
import {useSelector} from "react-redux";
import {getInputValue} from "../../components/functions/FuncForm";
import {apiPost} from "../../services/ServiceAPI";
import {hasKeys} from "../../components/functions/FuncObjects";
import FormLoad from "../../components/elements/form/FormLoad";
import Infobox from "../../components/elements/message/Infobox";
import {PostCourseObj} from "../../obj/Course/PostCourse.obj";
import ArchiveCourseLB from "../lightboxes/courses/ArchiveCourseLB";
import InactiveCourseLB from "../lightboxes/courses/InactiveCourseLB";
import ActiveCourseLB from "../lightboxes/courses/ActiveCourseLB";
import ListGroupCourse from "../../components/widgets/list/ListGroupCourse";
import CourseAddLB from "../lightboxes/courses/CourseAddLB";
import Trans from "../../components/elements/trans/Trans";
import FormInput from "../../components/elements/form/FormInput";
import {getSearchInString} from "../../services/ServiceData";
import {getTrans} from "../../services/ServiceTrans";

const ComplexCourseList = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [loadingAddCourse, setLoadingAddCourse] = useState(false)

    /************** form **************/
    const [addCourseSubmit, setAddCourseSubmit] = useState(false)
    const [validated, setValidated] = useState({})
    const [validationShow, setValidationShow] = useState(false)

    /************** lightbox **************/
    const [courseAddLB, setCourseAddLB] = useState(false)
    const [courseArchiveLB, setCourseArchiveLB] = useState(false)
    const [courseInactiveLB, setCourseInactiveLB] = useState(false)
    const [courseActiveLB, setCourseActiveLB] = useState(false)
    //const [courseDuplicateLB, setCourseDuplicateLB] = useState(false)

    /************** objects **************/
    const [newCourse, setNewCourse] = useState(PostCourseObj)
    const [courseId, setCourseId] = useState(0)

    /************** global **************/
    const [searchValue, setSearchValue] = useState('')
    const coursesState = useSelector((state) => state.courses)
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    useEffect(() => {
        setAddCourseSubmit(!hasKeys(validated))
    }, [validated])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** input events **************/
    /* change */
    const handleNewCourseChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        setNewCourse({
            ...newCourse,
            [input.name]: input.value
        })
    }

    const onCourseSearch = (e) => {
        setSearchValue(e.target.value)
    }

    /* submit */
    const handleLBAddCourseBtn = () => {
        setValidationShow(true)

        if (addCourseSubmit) {
            setLoadingAddCourse(true)
            apiPost('courses', p, newCourse)
                .then(() => {
                    setCourseAddLB(false)
                    p.setOpenAddCourseLB(false)
                    setLoadingAddCourse(false)
                    p.getCourseList()
                    setValidationShow(false)
                })
        }
    }

    /************** button events **************/
    const addCourseHandler = () => {
        setCourseAddLB(true)
    }

    const closeLightbox = () => {
        setCourseAddLB(false)
        setCourseArchiveLB(false)
        p.setOpenAddCourseLB(false)
        setCourseInactiveLB(false)
        setCourseActiveLB(false)
    }

    const handleLightboxClose = () => {
        setCourseAddLB(false)
        p.setOpenAddCourseLB(false)
    }

    const handleArchiveCourse = (id) => {
        setCourseId(id)
        setCourseArchiveLB(true)
    }

    const handleInaktiveCourse = (id, status) => {
        setCourseId(id)
        if (status) {
            setCourseActiveLB(true)
        }else {
            setCourseInactiveLB(true)
        }
    }

    const handleLBArchiveCourseBtn = () => {
        setCourseArchiveLB(false)
        p.archiveCourse(courseId)
    }

    const handleLBInactiveCourseBtn = () => {
        setCourseInactiveLB(false)
        p.inactiveCourse(courseId, false)
    }

    const handleLBActiveCourseBtn = () => {
        setCourseActiveLB(false)
        p.inactiveCourse(courseId, true)
    }

    /************** general **************/
    const getCourseListItems = (filter, status) => {
        return coursesState.courses.filter(course => course.courseStatus[filter].status).map((course) => {
            return (
               <>
                   {getSearchInString(searchValue, [
                       course.title,
                       course.courseNumber
                   ]) &&
                       <ListItemCourse
                           course={course}
                           key={course.id}
                           onInactive={handleInaktiveCourse}
                           onArchive={handleArchiveCourse}
                           onclick={p.onItemClick}
                           search={searchValue}
                           selected={(coursesState.actCourse.id === course.id)? 'is--selected' : ''}
                           status={status}
                       />
                   }
               </>
            )
        })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** general **************/
    const courseActiveListItems = getCourseListItems('published', 'active')
    const courseNotPublishedListItems = getCourseListItems('activeNotPublished', 'not-published')
    const courseInactiveListItems = getCourseListItems('inactive', 'inactive')

    return (
        <>
            <ContentTabs
                body={<>
                    {p.loadingCourseList
                        ? <FormLoad a={true}/>
                        : <>
                            {coursesState.courses.length === 0
                                ? <Infobox
                                    addClasses={'c-bg-pr-2-dark is--no-border is--icon-left c-w child-c-w'}
                                    content={<Trans id={719}/>}
                                    html={true}
                                    icon={'info'}
                                    tid='719'
                                />
                                : <>
                                    <ListGroupCourse
                                        colorClass={'c-status-ok'}
                                        status={<Trans id={393}/>}
                                        info={getTrans(transState, 720)}
                                        listItems={courseActiveListItems}
                                        tid='393, 720'
                                    />
                                    <ListGroupCourse
                                        colorClass={'c-status-warn'}
                                        status={<Trans id={394}/>}
                                        info={getTrans(transState, 721)}
                                        listItems={courseNotPublishedListItems}
                                        tid='394, 721'
                                    />
                                    <ListGroupCourse
                                        colorClass={'is--c-b-grey'}
                                        status={<Trans id={395}/>}
                                        info={getTrans(transState, 722)}
                                        listItems={courseInactiveListItems}
                                        tid='395, 722'
                                    />
                                </>
                            }
                        </>
                    }
                </>}
                head={<>
                    <div className='c-tab__head-top'>
                        <div>
                            <p className='c-tab__head-title' tid='392'>
                                <Trans id={392}/>
                                {coursesState.courses.length !== 0 &&
                                    <span className={'e-text__tag is--filled'}>{coursesState.courses.length}</span>
                                }
                            </p>
                        </div>
                        <div>
                            <nav className='c-tab__head-actions'>
                                <ul>
                                    <li>
                                        <button
                                            className={`e-btn__action ico-plus-circled`}
                                            onClick={addCourseHandler}
                                        />
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className='c-tab__head-bottom'>
                        <form autoComplete="off">
                            <FormInput
                                addClasses='is--visible-border'
                                addClassesWrap={'p-none'}
                                name='searchList'
                                onChange={onCourseSearch}
                                placeholder={<Trans id={391}/>}
                                tid='391'
                                type='text'
                                value={searchValue}
                            />
                        </form>
                    </div>
                </>}
                onOpen={p.onTabOpen}
                open={p.openCourseList}
                tid='723'
                title={getTrans(transState, 723)}
                width={p.width}
            />
            <CourseAddLB
                addCourseSubmit={addCourseSubmit}
                closeLightbox={closeLightbox}
                handleLBAddCourseBtn={handleLBAddCourseBtn}
                handleLightboxClose={handleLightboxClose}
                handleNewCourseChange={handleNewCourseChange}
                loadingAddCourse={loadingAddCourse}
                newCourse={newCourse}
                open={p.openAddCourseLB || courseAddLB}
                setValidated={setValidated}
                validated={validated}
                validationShow={validationShow}
            />
            <ArchiveCourseLB
                closeLightbox={closeLightbox}
                courseArchiveLB={courseArchiveLB}
                handleLBArchiveCourseBtn={handleLBArchiveCourseBtn}
            />
            <ActiveCourseLB
                closeLightbox={closeLightbox}
                courseActiveLB={courseActiveLB}
                handleLBActiveCourseBtn={handleLBActiveCourseBtn}
            />
            <InactiveCourseLB
                closeLightbox={closeLightbox}
                courseInactiveLB={courseInactiveLB}
                handleLBInactiveCourseBtn={handleLBInactiveCourseBtn}
            />
        </>
    )
}

export default withGlobalState(ComplexCourseList)
