import React, {useEffect, useState} from "react";
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import {getInputValue} from "../../../components/functions/FuncForm";
import {hasKeys} from "../../../components/functions/FuncObjects";
import FormLoad from "../../../components/elements/form/FormLoad";
import {CourseServicePost} from "../../../obj/CourseService/CourseServicePost.obj";
import ComplexCourseServiceForm from "../../forms/courseService/ComplexCourseServiceForm";
import {useSelector} from "react-redux";
import {getTrans} from "../../../services/ServiceTrans";
import Trans from "../../../components/elements/trans/Trans";

const AddCourseServiceLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    /************** form **************/
    const [validated, setValidated] = useState({})
    const [addCourseServiceSubmit, setAddCourseServiceSubmit] = useState(false)

    /************** objects **************/
    const [newCourseService, setNewCourseService] = useState(CourseServicePost)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setAddCourseServiceSubmit(!hasKeys(validated))
    }, [validated])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    /* change */
    const handleInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        setNewCourseService({
            ...newCourseService,
            [input.name]: input.value
        })
    }

    const handleLBAddCourseServiceBtn = () => {
        p.handleLBAddCourseServiceBtn(newCourseService, addCourseServiceSubmit)
    }

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn'
                            disabled={!addCourseServiceSubmit}
                            onClick={handleLBAddCourseServiceBtn}
                            tid={'641'}
                        ><Trans id={641}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeLightbox}
                            tid={'642'}
                        ><Trans id={642}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <div>
                    <ComplexCourseServiceForm
                        courseService={newCourseService}
                        handleInputChange={handleInputChange}
                        validated={validated}
                        setValidated={setValidated}
                        validationShow={p.validationShowNew}
                    />
                    <FormLoad a={p.addCourseServiceLoading}/>
                </div>
            }
            onClose={p.closeLightbox}
            open={p.addCourseServiceLB}
            tid={'640'}
            title={getTrans(transState, 640)}
        />
    )
}

export default withGlobalState(AddCourseServiceLB)
