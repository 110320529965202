import React, {useEffect, useRef, useState} from 'react';
import {withGlobalState} from "react-globally";
import {buildDateStringNoTime, buildTimeString} from "../../../services/ServiceDate";
import ActiveCourseProductLB from "../../../complex/lightboxes/courseProduct/ActiveCourseProductLB";
import {getDoubleString, showSearchResult} from "../../../services/ServiceData";
import Trans from "../../elements/trans/Trans";

const ListItemCourseProduct = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** states **************/
    const [showActions, setShowActions] = useState(false)
    const refActions = useRef(null)
    const q = p.search

    /************** lightbox **************/
    const [courseProductInactiveLB, setCourseProductInactiveLB] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
    }, [])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleClickOutside = (event) => {
        if (refActions.current !== null && !refActions.current.contains(event.target)) setShowActions(false)
    }

    const handleActionsClick = (id, e) => {
        setShowActions(!showActions)
        e.stopPropagation();
    }

    const handleListItemClick = (courseId, id, e) => {
        if (p.selected === '' && refActions.current !== null && !refActions.current.contains(e.target)) p.onclick(courseId, id, e)
    }

    const handleCourseProductInactive = () => {
        setCourseProductInactiveLB(true)
    }

    const closeLightbox = () => {
        setCourseProductInactiveLB(false)
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const product = p.product

    return (
        <>
            <ActiveCourseProductLB
                cid={p.courseId}
                pid={product.id}
                courseProductInactiveLB={courseProductInactiveLB}
                closeLightbox={closeLightbox}
                readyToPublished={product.readyToPublished}
                inactiveCourseProduct={p.inactiveCourseProduct}
            />
            <li
                className={`is--${p.status} ${p.selected}`}
                onClick={e => handleListItemClick(p.courseId, product.id, e)}
            >
                <div className='c-itemlist__content'>
                    <div className='fl fl-jc-sb'>
                        <p className='f-s-12 f-w-7 is--c-f-bgd' tid={product.startdate !== null? '495' : '577'}>
                            <>
                                {product.startdate !== null
                                    ? <>{buildDateStringNoTime(new Date(product.startdate))}</>
                                    : <><Trans id={577}/></>
                                }
                                {(product.coursePlace && product.coursePlace.city) &&
                                    <>, {product.coursePlace.city.substring(0, 20)}</>
                                }
                            </>
                        </p>
                    </div>
                    {(product.courseType && product.courseType.title) &&
                        <div>
                            <p className={'f-s-12 is--c-f-bgd'}>{showSearchResult(q, product.courseType.title, 'f-w-7 c-pri-2-dark')}</p>
                        </div>
                    }
                    <div className='fl fl-jc-sb'>
                        <p className={'f-s-12 is--c-f-bgd'}>{showSearchResult(q, product.productNumber, 'f-w-7 c-pri-2-dark')} {
                            product.productNumberPrivate &&
                            <>
                                / {showSearchResult(q, product.productNumberPrivate, 'f-w-7 c-pri-2-dark')}
                            </>
                        }</p>
                        <p className='f-s-12 is--c-f-bgd' tid={'578'}>{product.bookingCount === null
                            ? product.contingent
                            : (product.contingent - product.bookingCount)
                        } / {product.contingent} <Trans id={578}/></p>
                    </div>
                    <div className='fl fl-jc-sb'>
                        {product.totPrice &&
                            <p className='f-s-12 is--c-f-bgd'>{getDoubleString(product.totPrice)} &euro;</p>
                        }
                        {product.startdate !== null &&
                        <p className='f-s-12 is--c-f-bgd' tid={'447, 495'}><Trans id={447}/> {buildTimeString(new Date(product.startdate))} <Trans id={495}/></p>
                        }
                    </div>
                </div>
                <div className='c-itemlist__actions c-optionlist__holder' ref={refActions}>
                    <button className='e-btn__action ico-list-bullet' onClick={e => handleActionsClick(product.id, e)}/>
                    {showActions &&
                    <ul className='c-optionlist'>
                        <li onClick={e => {
                            if (p.onDuplicate) p.onDuplicate(product.id, e)
                            setShowActions(false)
                        }} tid={'579'}><Trans id={579}/></li>
                        <li onClick={() => {
                            handleCourseProductInactive()
                            setShowActions(false)
                        }} tid={'574, 575'}>{product.readyToPublished
                            ? <Trans id={574}/>
                            : <Trans id={575}/>
                        }</li>
                        <li onClick={() => {
                            if (p.onArchive) p.onArchive(product.id)
                            setShowActions(false)
                        }} tid={'580'}><Trans id={580}/></li>
                    </ul>
                    }
                </div>
            </li>
        </>
    )
}

export default withGlobalState(ListItemCourseProduct)
