import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'trans',
    initialState: {
        trans: []
    },
    reducers: {
        setTrans: (state, payload) => {
            state.trans = payload.payload
        }
    }
});

export const {
    setTrans
} = slice.actions;

export default slice.reducer;
