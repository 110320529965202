import React, {useState, useEffect} from 'react';
import Page from "../Page";
import {withGlobalState} from "react-globally";
import FormError from "../../components/elements/form/FormError";
import FormRow from "../../components/elements/form/FormRow";
import FormLoad from "../../components/elements/form/FormLoad";
import {NavLink, useHistory} from "react-router-dom";
import {apiPost} from "../../services/ServiceAPI";
import {hasKeys} from "../../components/functions/FuncObjects";
import Trans from "../../components/elements/trans/Trans";
import FormInput from "../../components/elements/form/FormInput";

const PageForgot = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const history = useHistory();

    /************** form **************/
    const [loadingForgot, setLoadingForgot] = useState(false);
    const [submit, setSubmit] = useState(false)
    const [submitNew, setSubmitNew] = useState(false)
    const [hasToken, setHasToken] = useState(false)
    const [loadingPass, setLoadingPass] = useState(false);
    const [forgot, setForgot] = useState({
        accountMail: ''
    })
    const [newPass, setNewPass] = useState({
        token: '',
        accountPassword: '',
        accountPasswordRepeat: ''
    })

    /************** validation **************/
    const [validated, setValidated] = useState({})
    const [validatedPass, setValidatedPass] = useState({})
    const [validationShow, setValidationShow] = useState(false)
    const [validationShowPass, setValidationShowPass] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        if (searchParams.get('token') && newPass.token === '') {
            setNewPass({
                ...newPass,
                token: searchParams.get('token')
            })
            setHasToken(true)
        }

        setSubmit(!hasKeys(validated))
        setSubmitNew(!hasKeys(validatedPass))
    }, [newPass, validated, validatedPass])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleInputChangeNew = (event) => {
        setNewPass({
            ...newPass,
            [event.target.name]: event.target.value
        })
        setSubmitNew(!hasKeys(validatedPass))
    }

    const handleInputChange = (e) => {
        setForgot({
            ...forgot,
            [e.target.name]: e.target.value
        })
        setSubmit(!hasKeys(validated))
    }

    const handleFormSubmitPass = async (e, p) => {
        e.preventDefault()
        setValidationShowPass(true)

        if (submitNew) {
            setLoadingPass(true)

            apiPost('account/reset-password', p, newPass, true, false)
                .then(() => {
                    history.push('/')
                })
                .finally(() => {
                    setLoadingPass(false)
                })
        }
    }

    const handleFormSubmitForgot = async (e, p) => {
        e.preventDefault()
        setValidationShow(true)

        if (submit) {
            setLoadingForgot(true)
            apiPost('account/forgot-password', p, forgot, true, false)
                .then(() => {
                    history.push('/')
                })
                .finally(() => {
                    setLoadingForgot(false)
                })
        }
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const message = ''
    const messageContentForgot = message !== ''? <FormError error={message}/> : ''
    return (
        <Page
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <div className='c-c__cols'>
                        <div
                            className='p-70 c-bg-body'
                            style={{width: "50%"}}
                            tid='208'
                        ><Trans id={208} html={true}/></div>
                        <div
                            className='c-box is--cc p-t-70'
                            style={{width: "50%"}}
                        >
                            <div style={{width: "50%"}}>
                                {hasToken
                                    ? <form onSubmit={e => handleFormSubmitPass(e, p)}>
                                        <h3 className='f-s-36 f-w-9'>
                                            <span className='c-pri-2-dark' tid='205'><Trans id={205}/></span>
                                        </h3>
                                        <FormRow
                                            adClasses={'p-t-16'}
                                            childs={
                                                <FormInput
                                                    label={<Trans id={248}/>}
                                                    name='accountPassword'
                                                    onChange={handleInputChangeNew}
                                                    placeholder={<Trans id={247}/>}
                                                    required
                                                    tid='247, 248'
                                                    htmlType='password'
                                                    value={newPass.accountPassword}
                                                    validationChecks={[['required', ['minLength', 8], ['passwordStrength', 2]]]}
                                                    validationShow={validationShowPass}
                                                    validationAct={validatedPass}
                                                    validationSet={setValidatedPass}
                                                />
                                            }
                                        />
                                        <FormRow childs={
                                            <FormInput
                                                label={<Trans id={250}/>}
                                                name='accountPasswordRepeat'
                                                onChange={handleInputChangeNew}
                                                placeholder={<Trans id={249}/>}
                                                required
                                                tid='249, 250'
                                                htmlType='password'
                                                value={newPass.accountPasswordRepeat}
                                                validationChecks={[['required', ['passwordEqual', newPass.accountPassword]]]}
                                                validationShow={validationShowPass}
                                                validationAct={validatedPass}
                                                validationSet={setValidatedPass}
                                            />
                                        }
                                        />
                                        <FormRow
                                            adClasses={'p-t-16'}
                                            childs={
                                                <>
                                                    <div className='w-100'>
                                                        <FormLoad
                                                            a={loadingPass}
                                                        />
                                                        {!loadingPass&&
                                                            <button
                                                                className={`f-lh-de c-bg-pr-3 c-w rad-6 w-100 p-lr-16 p-tb-10 a-c outline outline-w-2 outline-c-pr-3 hover-c-bg-pr-3-darker hover-outline-c-pr-3-darker ${p.loading? 'is--loading' : ''}`}
                                                                tid='312'
                                                                type='submit'
                                                            >
                                                                <Trans id={312}/>
                                                            </button>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        />
                                        <FormLoad a={loadingPass}/>
                                        <FormRow
                                            adClasses={'p-t-16'}
                                            childs={
                                                <>
                                                    <NavLink
                                                        className='c-bg-w c-pr-3 hover-c-pr-2-dark c-w rad-6 w-100 p-lr-16 p-tb-10 a-c outline hover-outline outline-w-2 hover-outline-w-2 outline-c-pr-3 hover-outline-c-pr-2-dark'
                                                        tid='207'
                                                        to="/login"
                                                    ><Trans id={207}/></NavLink>
                                                </>
                                            }
                                        />
                                    </form>
                                    : <form onSubmit={e => handleFormSubmitForgot(e, p)}>
                                        <h3 className='f-s-36 f-w-9'>
                                            <span className='c-pri-2-dark' tid='205'><Trans id={205}/></span>
                                        </h3>
                                        <FormRow adClasses={'p-t-16'} childs={
                                            <FormInput
                                                label={<Trans id={199}/>}
                                                name='accountMail'
                                                onChange={handleInputChange}
                                                placeholder={<Trans id={198}/>}
                                                required
                                                tid='198, 199'
                                                htmlType='email'
                                                value={forgot.accountMail}
                                                validationChecks={[['required', 'email', ['maxLength', 128]]]}
                                                validationShow={validationShow}
                                                validationAct={validated}
                                                validationSet={setValidated}
                                            />
                                        }/>
                                        <FormRow
                                            adClasses={'p-t-16'}
                                            childs={
                                                <>
                                                    <div className='w-100'>
                                                        <FormLoad
                                                            a={loadingForgot}
                                                        />
                                                        {!loadingForgot&&
                                                            <button
                                                                className={`f-lh-de c-bg-pr-3 c-w rad-6 w-100 p-lr-16 p-tb-10 a-c outline outline-w-2 outline-c-pr-3 hover-c-bg-pr-3-darker hover-outline-c-pr-3-darker ${p.loading? 'is--loading' : ''}`}
                                                                tid={submit?'203':'204'}
                                                                title={submit?<Trans id={203}/>:<Trans id={204}/>}
                                                                type='submit'
                                                            >
                                                                {submit?<Trans id={203}/>:<Trans id={204}/>}
                                                            </button>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        />
                                        <FormRow
                                            adClasses={'p-t-16'}
                                            childs={
                                                <>
                                                    <NavLink
                                                        className='c-bg-w c-pr-3 hover-c-pr-2-dark c-w rad-6 w-100 p-lr-16 p-tb-10 a-c outline hover-outline outline-w-2 hover-outline-w-2 outline-c-pr-3 hover-outline-c-pr-2-dark'
                                                        tid='207'
                                                        to="/login"
                                                    ><Trans id={207}/></NavLink>
                                                </>
                                            }
                                        />
                                        {messageContentForgot}
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            title=''
        />
    )
}

export default withGlobalState(PageForgot)