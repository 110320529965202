import React, {useEffect, useState} from 'react';
import Page from "../Page";
import {withGlobalState} from "react-globally";
import {apiPost} from "../../services/ServiceAPI";
import {useHistory} from "react-router-dom";
import {hasKeys} from "../../components/functions/FuncObjects";
import Trans from "../../components/elements/trans/Trans";
import ComplexRegistrationForm from "../../complex/forms/login/ComplexRegistrationForm";
import {getInputValue} from "../../components/functions/FuncForm";
import {validation} from "../../components/functions/FuncValidation";

const PageRegistration = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const [registration, setRegistration] = useState({
        accountMail: '',
        accountPassword: '',
        accountPasswordRepeat: '',
        agb: false
    })
    const history = useHistory();

    /************** loading **************/
    const [loadingRegistration, setLoadingRegistration] = useState(false);

    /************** validation **************/
    const [validatedRegistration, setValidatedRegistration] = useState({})

    /************** form **************/
    const [submitRegistration, setSubmitRegistration] = useState(false)
    const [inputs] = useState({
        accountMail: [['required', 'email', ['maxLength', 128]]],
        accountPassword: [['required', ['minLength', 8], ['passwordStrength', 2]]],
        accountPasswordRepeat: [['required']]
    })
    const [validationShow, setValidationShow] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setSubmitRegistration(!hasKeys(validatedRegistration))
    }, [validatedRegistration])

    useEffect(() => {
        let vali = {}
        for (const [key, value] of Object.entries(inputs)) {
            const status = validation(value, registration[key], p)
            if (!status.status) vali[key] = status
        }
        setValidatedRegistration(vali)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleRegistrationInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        setRegistration({
            ...registration,
            [input.name]: input.value
        })
        setSubmitRegistration(!hasKeys(validatedRegistration))
    }

    const handleFormSubmitRegistration = async (event) => {
        event.preventDefault()
        setValidationShow(true)

        if (submitRegistration) {
            if (!hasKeys(validatedRegistration)) {
                setSubmitRegistration(true)
                setLoadingRegistration(true)

                apiPost('account/register', p, registration, true, false)
                    .then(() => {
                        history.push('/?action=mailapr')
                    })
                    .finally(() => {
                        setLoadingRegistration(false)
                    })
            }else {
                setSubmitRegistration(false)
                setLoadingRegistration(false)
            }
        }
    }

    return (
        <Page
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <div className='c-c__cols'>
                        <h1
                            className='p-70 c-bg-body'
                            style={{width: "50%"}}
                            tid='216'
                        ><Trans id={216} html={true}/></h1>
                        <div className='c-box is--cc p-t-70' style={{width: "50%"}}>
                            <div style={{width: "50%"}}>
                                <ComplexRegistrationForm
                                    handleFormSubmitRegistration={handleFormSubmitRegistration}
                                    handleRegistrationInputChange={handleRegistrationInputChange}
                                    inputs={inputs}
                                    loadingRegistration={loadingRegistration}
                                    registration={registration}
                                    setValidatedRegistration={setValidatedRegistration}
                                    submitRegistration={submitRegistration}
                                    validatedRegistration={validatedRegistration}
                                    validationShow={validationShow}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
            title=''
        />
    )
}

export default withGlobalState(PageRegistration)
