import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import Trans from "../../../components/elements/trans/Trans";
import FormInput from "../../../components/elements/form/FormInput";
import FormGrouping from "../../../components/elements/form/FormGrouping";
import FormSubmit from "../../../components/elements/form/FormSubmit";

const ComplexBaseDataRegistrationForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const hIC = p.onInputChange
    const valSet = p.setVal
    const u = p.user
    const valAct = p.validated
    const valSh = p.validationShow

    /************** form **************/
    const [i] = useState({
        accountProfession: [[['maxLength', 32]]],
        accountFirstName: [['required'], [['maxLength', 64]], [['minLength', 2]]],
        accountLastName: [['required'], [['maxLength', 64]], [['minLength', 2]]],
        companyName: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        companyStreet: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        companyHouseNumber: [['required'], [['maxLength', 30]]],
        companyZip: [['required']],
        companyCity: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        companyWebsite: [['required'], ['url'], [['maxLength', 255]]],
        contactMail: [['email'], [['maxLength', 128]]],
        contactTel: [['tel'], [['maxLength', 128]]]
    })

    return (
        <form onSubmit={e => p.handleFormSubmit(e)}>
            <FormGrouping
                title={<span tid='745'><Trans id={745}/></span>}
            >
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={254}/>}
                            name='accountSalutation'
                            onChange={hIC}
                            options={p.globalState.general.salutations}
                            selected={u.accountSalutation}
                            tid='254'
                            type={'select'}
                        />
                        <FormInput
                            label={<Trans id={256}/>}
                            name='accountProfession'
                            onChange={hIC}
                            placeholder={<Trans id={255}/>}
                            tid='255, 256'
                            value={u.accountProfession}
                            validationChecks={i.accountProfession}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                    </>
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={258}/>}
                            name='accountFirstName'
                            onChange={hIC}
                            placeholder={<Trans id={257}/>}
                            required
                            tid='257, 258'
                            validationChecks={i.accountFirstName}
                            value={u.accountFirstName}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                        <FormInput
                            label={<Trans id={260}/>}
                            name='accountLastName'
                            onChange={hIC}
                            placeholder={<Trans id={259}/>}
                            required
                            tid='259, 260'
                            validationChecks={i.accountLastName}
                            value={u.accountLastName}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                    </>
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={276}/>}
                            name='contactMail'
                            onChange={hIC}
                            placeholder={<Trans id={275}/>}
                            tid='275, 276'
                            type='email'
                            validationChecks={i.contactMail}
                            value={u.contactMail}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                        <FormInput
                            label={<Trans id={278}/>}
                            name='contactTel'
                            onChange={hIC}
                            placeholder={<Trans id={277}/>}
                            tid='277, 278'
                            type='tel'
                            value={u.contactTel}
                            validationChecks={i.contactTel}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                    </>
                }/>
            </FormGrouping>
            <FormGrouping
                title={<span tid='743'><Trans id={743}/></span>}
            >
                <FormRow childs={
                    <FormInput
                        label={<Trans id={262}/>}
                        name='companyName'
                        onChange={hIC}
                        placeholder={<Trans id={261}/>}
                        required
                        tid='261, 262'
                        validationChecks={i.companyName}
                        value={u.companyName}
                        validationShow={valSh}
                        validationAct={valAct}
                        validationSet={valSet}
                    />
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={264}/>}
                            name='companyStreet'
                            onChange={hIC}
                            placeholder={<Trans id={263}/>}
                            required
                            tid='263, 264'
                            validationChecks={i.companyStreet}
                            value={u.companyStreet}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                        <FormInput
                            label={<Trans id={266}/>}
                            name='companyHouseNumber'
                            onChange={hIC}
                            placeholder={<Trans id={265}/>}
                            required
                            tid='265, 266'
                            validationChecks={i.companyHouseNumber}
                            value={u.companyHouseNumber}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                    </>
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={268}/>}
                            name='companyZip'
                            onChange={hIC}
                            placeholder={<Trans id={267}/>}
                            required
                            tid='267, 268'
                            validationChecks={i.companyZip}
                            value={u.companyZip}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                        <FormInput
                            label={<Trans id={270}/>}
                            name='companyCity'
                            onChange={hIC}
                            placeholder={<Trans id={269}/>}
                            required
                            tid='269, 270'
                            validationChecks={i.companyCity}
                            value={u.companyCity}
                            validationShow={valSh}
                            validationAct={valAct}
                            validationSet={valSet}
                        />
                    </>
                }/>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={272}/>}
                        name='companyUstid'
                        onChange={hIC}
                        placeholder={<Trans id={271}/>}
                        tid='271, 272'
                        value={u.companyUstid}
                    />
                }/>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={274}/>}
                        name='companyWebsite'
                        onChange={hIC}
                        placeholder={<Trans id={273}/>}
                        required
                        tid='273, 274'
                        validationChecks={i.companyWebsite}
                        value={u.companyWebsite}
                        validationShow={valSh}
                        validationAct={valAct}
                        validationSet={valSet}
                    />
                }/>
            </FormGrouping>
            <FormRow childs={
                <FormSubmit
                    loading={p.loading}
                    tid='744'
                    text={<Trans id={744}/>}
                />
            }/>
        </form>
    )
}

export default withGlobalState(ComplexBaseDataRegistrationForm)
