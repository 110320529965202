import React from 'react'

const Logo = (p) => {
    return (
        <svg className={`complex-logo h-60px p-r-16 w-auto ${p.nopadding&& 'p-r-none'}`} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 442.33527 165.40426">
            <g id="Ebene_2" data-name="Ebene 2">
                <g id="Ebene_4" data-name="Ebene 4">
                    <g id="Ebene_2-2" data-name="Ebene 2">
                        <rect className="svgbg" width="442.33527" height="165.40426"/>
                    </g>
                    <g id="Ebene_3" data-name="Ebene 3">
                        <g>
                            <path className="svgw"
                                  d="M74.54124,79.25566V52.06816H69.51v-6.625h5.03125v-2.875q0-5.68725,3.26563-8.82812,3.26514-3.14062,9.14062-3.14063a21.12269,21.12269,0,0,1,4.59375.625l-.09375,7a11.51575,11.51575,0,0,0-2.75-.28125q-5.094,0-5.09375,4.78125v2.71875h6.71875v6.625H83.60374v27.1875Z"/>
                            <path className="svgw"
                                  d="M93.71263,62.03691a20.049,20.049,0,0,1,1.9375-8.96875,14.18157,14.18157,0,0,1,5.57813-6.09375,16.27284,16.27284,0,0,1,8.45312-2.15625,15.39816,15.39816,0,0,1,11.17188,4.1875q4.32788,4.188,4.82812,11.375l.0625,2.3125q0,7.78125-4.34375,12.48438-4.344,4.70361-11.65625,4.70312-7.3125,0-11.67187-4.6875t-4.35938-12.75Zm9.03125.64112q0,4.82006,1.8125,7.3706a6.51126,6.51126,0,0,0,10.3125.03125q1.8435-2.51954,1.84375-8.05908,0-4.72559-1.84375-7.32373a5.99473,5.99473,0,0,0-5.1875-2.59766,5.89532,5.89532,0,0,0-5.125,2.582Q102.74363,57.264,102.74388,62.678Z"/>
                            <path className="svgw"
                                  d="M162.57494,62.66191q0,8.12549-3.46875,12.67188a11.49014,11.49014,0,0,1-9.6875,4.54687,10.55637,10.55637,0,0,1-8.78125-4.21875l-.40625,3.59375h-8.125v-48h9.03125V48.47441a10.28479,10.28479,0,0,1,8.21875-3.65625,11.58494,11.58494,0,0,1,9.70312,4.54688q3.51563,4.54688,3.51563,12.79687Zm-9.03125-.65625q0-5.12475-1.625-7.48437a5.4958,5.4958,0,0,0-4.84375-2.35938q-4.3125,0-5.9375,3.53125V69.03691q1.656,3.5625,6,3.5625a5.576,5.576,0,0,0,5.75-4.3125A22.24658,22.24658,0,0,0,153.54369,62.00566Z"/>
                            <path className="svgw"
                                  d="M168.70238,36.69316a4.4475,4.4475,0,0,1,1.35937-3.34375,5.85827,5.85827,0,0,1,7.39063,0,4.772,4.772,0,0,1-.01563,6.71875,5.7925,5.7925,0,0,1-7.34375,0A4.43339,4.43339,0,0,1,168.70238,36.69316Zm9.59375,42.5625h-9.0625V45.44316h9.0625Z"/>
                            <path className="svgw"
                                  d="M194.842,45.44316l.28125,3.78125a11.88214,11.88214,0,0,1,9.71875-4.40625q6.531,0,8.96875,5.15625A11.60947,11.60947,0,0,1,223.967,44.81816q5.49975,0,8.1875,3.20313,2.68725,3.20361,2.6875,9.64062V79.25566h-9.0625V57.69316a6.43909,6.43909,0,0,0-1.125-4.20312q-1.125-1.32789-3.96875-1.32813a5.6369,5.6369,0,0,0-5.625,3.875l.03125,23.21875h-9.03125V57.72441a6.36265,6.36265,0,0,0-1.15625-4.25q-1.1565-1.3125-3.9375-1.3125a5.85239,5.85239,0,0,0-5.5625,3.1875V79.25566h-9.03125V45.44316Z"/>
                            <path className="svgw"
                                  d="M261.93675,79.25566a10.01846,10.01846,0,0,1-.90625-3.03125,10.9276,10.9276,0,0,1-8.53125,3.65625,12.00309,12.00309,0,0,1-8.23437-2.875,9.24521,9.24521,0,0,1-3.26563-7.25,9.556,9.556,0,0,1,3.98438-8.25q3.98438-2.87475,11.51562-2.90625h4.15625v-1.9375a5.57366,5.57366,0,0,0-1.20312-3.75,4.73481,4.73481,0,0,0-3.79688-1.40625,5.38,5.38,0,0,0-3.57812,1.09375,3.716,3.716,0,0,0-1.29688,3h-9.03125a9.07234,9.07234,0,0,1,1.8125-5.4375,11.9817,11.9817,0,0,1,5.125-3.92187,18.67928,18.67928,0,0,1,7.4375-1.42188q6.249,0,9.92188,3.14063,3.67089,3.14063,3.67187,8.82812V71.44316a16.15914,16.15914,0,0,0,1.34375,7.28125v.53125ZM254.468,72.97441a7.77921,7.77921,0,0,0,3.6875-.89062,5.85147,5.85147,0,0,0,2.5-2.39063v-5.8125h-3.375q-6.78149,0-7.21875,4.6875l-.03125.53125a3.6142,3.6142,0,0,0,1.1875,2.78125A4.611,4.611,0,0,0,254.468,72.97441Z"/>
                            <path className="svgw"
                                  d="M296.42992,53.91191a24.56759,24.56759,0,0,0-3.25-.25q-5.1255,0-6.71875,3.46875v22.125h-9.03125V45.44316h8.53125l.25,4.03125q2.71875-4.656,7.53125-4.65625a9.47364,9.47364,0,0,1,2.8125.40625Z"/>
                            <path className="svgw"
                                  d="M313.70726,65.69316l-3.25,3.25v10.3125H301.426v-48h9.03125V57.84941l1.75-2.25,8.65625-10.15625h10.84375L319.48851,59.53691l13.28125,19.71875h-10.375Z"/>
                            <path className="svgw"
                                  d="M348.97288,37.13066v8.3125h5.78125v6.625h-5.78125v16.875a4.02732,4.02732,0,0,0,.71875,2.6875,3.60857,3.60857,0,0,0,2.75.8125,14.3853,14.3853,0,0,0,2.65625-.21875v6.84375a18.59559,18.59559,0,0,1-5.46875.8125q-9.4995,0-9.6875-9.59375V52.06816h-4.9375v-6.625h4.9375v-8.3125Z"/>
                            <path className="svgg"
                                  d="M220.83421,131.25566a4.33949,4.33949,0,0,1,3.28125,1.20313,4.181,4.181,0,0,1,1.15625,3.01562,4.03365,4.03365,0,0,1-1.15625,2.95313,5.11886,5.11886,0,0,1-6.5.01562,3.96088,3.96088,0,0,1-1.1875-2.96875,4.179,4.179,0,0,1,1.15625-3.01562A4.29611,4.29611,0,0,1,220.83421,131.25566Z"/>
                            <path className="svgg"
                                  d="M246.89915,133.81816a6.865,6.865,0,0,0,4.71875-1.65625,5.6563,5.6563,0,0,0,1.99952-4.09375h7.15625a11.14716,11.14716,0,0,1-1.96875,5.89063,12.91093,12.91093,0,0,1-5,4.32812,14.89782,14.89782,0,0,1-6.812,1.59375q-7.09423,0-11.25-4.59375-4.15648-4.59375-4.15625-12.6875v-.78125q0-7.71827,4.125-12.35937,4.125-4.64062,11.25-4.64063a13.89158,13.89158,0,0,1,9.82764,3.51563,12.59307,12.59307,0,0,1,3.98438,9.23437h-7.15625a6.95336,6.95336,0,0,0-1.98389-4.78125,6.38523,6.38523,0,0,0-4.73438-1.875,6.682,6.682,0,0,0-5.6875,2.67188q-2.00023,2.67188-2.03125,8.10937v1.21875q0,5.50048,1.98438,8.20313A6.67609,6.67609,0,0,0,246.89915,133.81816Z"/>
                            <path className="svgg"
                                  d="M265.925,122.03691a19.93761,19.93761,0,0,1,1.96875-8.95312,14.37964,14.37964,0,0,1,5.53125-6.125,15.58607,15.58607,0,0,1,8.1875-2.14063,14.7694,14.7694,0,0,1,11.10938,4.40625q4.26563,4.40625,4.60937,11.6875l.03125,1.78125a20.11545,20.11545,0,0,1-1.92187,8.9375,14.20989,14.20989,0,0,1-5.5,6.09375,15.7,15.7,0,0,1-8.26563,2.15625,14.6655,14.6655,0,0,1-11.45312-4.76562q-4.29786-4.76514-4.29688-12.70313Zm7.59375.65625q0,5.21923,2.15625,8.17188a7.00964,7.00964,0,0,0,6,2.95312,6.93421,6.93421,0,0,0,5.98438-3q2.14014-3,2.14062-8.78125a13.44917,13.44917,0,0,0-2.20312-8.125,7.42589,7.42589,0,0,0-11.89063-.04687Q273.51781,116.81817,273.51878,122.69316Z"/>
                            <path className="svgg"
                                  d="M311.66038,105.44316l.21875,3.53125a12.16793,12.16793,0,0,1,9.75-4.15625q6.78077,0,9.28125,5.1875a11.99332,11.99332,0,0,1,10.375-5.1875q5.59425,0,8.32813,3.09375,2.73486,3.09375,2.79687,9.125v22.21875h-7.59375v-22a6.72537,6.72537,0,0,0-1.40625-4.71875q-1.40625-1.5-4.65625-1.5a6.32345,6.32345,0,0,0-4.23437,1.39063,7.33438,7.33438,0,0,0-2.29688,3.64062l.03125,23.1875h-7.59375v-22.25q-.15673-5.9685-6.09375-5.96875a6.68445,6.68445,0,0,0-6.46875,3.71875v24.5h-7.59375v-33.8125Z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Logo
