import {useSelector} from "react-redux";
import {hasKeys} from "../../functions/FuncObjects";
import React from "react";
import {withGlobalState} from "react-globally";
import {getTrans} from "../../../services/ServiceTrans";

const Trans = (p) => {
    const transState = useSelector((state) => state.trans)
    if (p.id) {
        let transString = getTrans(transState, p.id)
        if (hasKeys(p.param)) Object.keys(p.param).forEach((key) => {
            transString = transString.replace('{%' + key + '%}', p.param[key]);
        });
        if (p.html === true) return (<div dangerouslySetInnerHTML={{__html: transString}}/>)
        return (<>{transString}</>)
    }
    return (<></>)
}

export default withGlobalState(Trans)
