import React, {useEffect, useRef, useState} from 'react';
import {withGlobalState} from "react-globally";
import {showSearchResult} from "../../../services/ServiceData";
import Trans from "../../elements/trans/Trans";

const ListItemCourse = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const [showActions, setShowActions] = useState(false)
    const refActions = useRef(null)
    const course = p.course
    const q = p.search

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
    }, [])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleClickOutside = (event) => {
        if (refActions.current !== null && !refActions.current.contains(event.target)) setShowActions(false)
    }

    const handleActionsClick = (id, e) => {
        setShowActions(!showActions)
        e.stopPropagation();
    }

    const handleListItemClick = (id, e) => {
        if (p.selected === '' && refActions.current !== null && !refActions.current.contains(e.target)) p.onclick(id, e)
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    let actions = '';
    if (showActions) {
        actions = <ul className='c-optionlist'>
            <li onClick={e => {
                if (p.onInactive) p.onInactive(course.id, !course.readyToPublished, e)
                setShowActions(false)
            }} tid={'574, 575'}>{course.readyToPublished? <Trans id={574}/> : <Trans id={575}/>}</li>
            <li onClick={e => {
                if (p.onArchive) p.onArchive(course.id, e)
                setShowActions(false)
            }} tid={'580'}><Trans id={580}/></li>
        </ul>;
    }

    return (
        <li
            className={`is--${p.status} ${p.selected}`}
            onClick={e => handleListItemClick(course.id, e)}
        >
            <div className='c-itemlist__content'>
                <div>
                    <p className={'f-s-12 f-w-5 is--c-f-bgd'}>{showSearchResult(q, course.title, 'f-w-7 c-pri-2-dark')}</p>
                </div>
                <div className='fl fl-jc-sb'>
                    <p className='f-s-12 is--c-f-bgd'>{showSearchResult(q, course.courseNumber, 'f-w-7 c-pri-2-dark')}</p>
                    <p className='f-s-12 is--c-f-bgd'>
                        <span className='c-status-ok'>{course.productStatusInformation.bookable}</span>
                        <span> / </span>
                        <span className='c-status-warn'>{course.productStatusInformation.notBookable}</span>
                        <span> / </span>
                        <span className='c-status-er'>{course.productStatusInformation.notActive}</span>
                    </p>
                </div>
            </div>
            <div className='c-itemlist__actions c-optionlist__holder' ref={refActions}>
                <button
                    className='e-btn__action ico-list-bullet'
                    onClick={e => handleActionsClick(course.id, e)}
                />
                {actions}
            </div>
        </li>
    )
}

export default withGlobalState(ListItemCourse)
