import React, {useMemo, useState} from 'react';
import {useDropzone} from "react-dropzone";
import FormWrap from "./FormWrap";
import FormRow from "./FormRow";
import {hasKeys} from "../../functions/FuncObjects";
import {withGlobalState} from "react-globally";
import Trans from "../trans/Trans";
import FormLabel from "./FormLabel";

const baseStyle = {
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
}

const activeStyle = {
    borderColor: '#2196f3'
}

const acceptStyle = {
    borderColor: '#00e676'
}

const rejectStyle = {
    borderColor: '#ff1744'
}

const FileUpload = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    const type = (p.type)? p.type : 'image/*'
    const maxFiles = (p.maxFiles)? p.maxFiles : 1
    const [files, setFiles] = useState({})

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: type,
        maxFiles: maxFiles,
        onDrop: (acceptedFiles) => {
            if (p.onChange) p.onChange(acceptedFiles)
            setFiles(acceptedFiles)
        }
    })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    const handleSubmit = (event, files) => {
        if (p.upload !== undefined) p.upload(files)
        setFiles({})
    }

    return (
        <div className='e-form__group'>
            <FormRow childs={
                <FormWrap childs={
                    <>
                        <FormLabel
                            label={p.label}
                            name={p.name}
                            required={p.required}
                            tid={p.tid}
                        />
                        <div className="e-form__fileupload">
                            <div {...getRootProps({style})}>
                                <input {...getInputProps()}/>
                                <p className='c-grey'>{hasKeys(files)
                                    ?<span tid='304'>
                                        <Trans id={304}/>
                                    </span>
                                    : <span tid='303'>
                                        <Trans id={303}/>
                                    </span>
                                }</p>
                                {hasKeys(files)&&
                                <div>
                                    <br/>
                                    <p className='c-pri-2' tid='305'>
                                        <Trans id={305}/>
                                    </p>
                                    <br/>
                                    <ul>
                                        {Object.keys(files).map(file => (
                                            <li className='c-pri-2' key={files[file].path}>
                                                {files[file].path}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                }
                            </div>
                        </div>
                    </>
                }/>
            }/>
            {type === 'image/*' && p.value &&
            <FormRow childs={<div>
                <FormWrap childs={
                    <>
                        <img
                            alt={<Trans id={302}/>}
                            className='e-form__fileupload-image'
                            src={`${process.env.REACT_APP_ROOT_URL}/uploads/${p.value}`}
                            tid='302'
                        />
                    </>
                }/>
            </div>}/>
            }
            <FormRow childs={
                <>
                    <button
                        className='e-btn'
                        disabled={!((p.submit !== undefined)? p.submit : true)}
                        onClick={(e) => handleSubmit(e, files)}
                        tid='306'
                        type='submit'
                    >
                        <Trans id={306}/>
                    </button>
                    {p.addActions}
                </>
            }/>
        </div>
    )
}

export default withGlobalState(FileUpload)
