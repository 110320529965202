import React from 'react';
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import Infobox from "../../../components/elements/message/Infobox";
import FormRow from "../../../components/elements/form/FormRow";
import FormInput from "../../../components/elements/form/FormInput";
import Trans from "../../../components/elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

const CourseProductAddTimesLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const actProduct = p.actProduct
    const productStartValidated = p.productStartValidated
    const setProductStartValidated = p.setProductStartValidated
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeTimeLightbox}
                            tid='845'
                        ><Trans id={845}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            disabled={!p.timeSubmit}
                            onClick={p.handleSaveTimes}
                            tid='844'
                        ><Trans id={844}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <>
                    <ul className='fl'>
                        <li className='p-lr-16 border border-w-r-1 border-c-lightgrey m-r-16'>
                            <h3 className={'c-pri-2-dark'} tid='837'><Trans id={837}/></h3>
                            <p className={'f-s-de f-s-16'} tid='846'><Trans id={846}/></p>
                            <div className={'p-t-16'}>
                                <ul>
                                    <li>
                                        <button
                                            className={`e-btn__bigaction ${p.timePage === 'start' ? 'is--active' : ''}`}
                                            onClick={() => p.handleChangePage('start')}
                                            tid='847'
                                        ><Trans id={847}/></button>
                                    </li>
                                    <li className={'p-t-6'}>
                                        <button
                                            className={`e-btn__bigaction ${p.timePage === 'nostart' ? 'is--active' : ''}`}
                                            onClick={() => p.handleChangePage('nostart')}
                                            tid='848'
                                        ><Trans id={848}/></button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className='p-lr-16 border border-w-r-1 border-c-lightgrey m-r-16'>
                            <h3 className={'c-pri-2-dark'} tid='838'><Trans id={838}/></h3>
                            {p.timePage === 'start'
                            ? <>
                                <p className={'f-s-de f-s-16'} tid='849'><Trans id={849}/></p>
                                <div className={'p-t-16'}>
                                    <form name='ProductFormTime'>
                                        <FormRow childs={
                                            <>
                                                <FormInput
                                                    label={getTrans(transState, 850)}
                                                    name='startdate'
                                                    onChange={p.handleProductChange}
                                                    required
                                                    tid='850'
                                                    type={'datetime'}
                                                    validation={
                                                        [['greaterNow', ['greaterThanDate', actProduct.activeUntil]], productStartValidated, setProductStartValidated]
                                                    }
                                                    value={actProduct.startdate}
                                                    visible={true}
                                                />
                                                <FormInput
                                                    label={getTrans(transState, 447)}
                                                    name='activeUntil'
                                                    onChange={p.handleProductChange}
                                                    required
                                                    tid='447'
                                                    type={'datetime'}
                                                    validation={
                                                        [['greaterNow', ['smallerThanDate', actProduct.startdate]], productStartValidated, setProductStartValidated]
                                                    }
                                                    value={actProduct.activeUntil}
                                                    visible={true}
                                                />
                                            </>
                                        }
                                        />
                                        <FormRow childs={
                                            <>
                                                <FormInput
                                                    label={getTrans(transState, 851)}
                                                    name='enddate'
                                                    onChange={p.handleProductChange}
                                                    tid='851'
                                                    type={'datetime'}
                                                    validation={
                                                        [['greaterNow', ['greaterThanDate', actProduct.startdate]], productStartValidated, setProductStartValidated]
                                                    }
                                                    value={actProduct.enddate}
                                                    visible={true}
                                                />
                                            </>
                                        }
                                        />
                                        <FormRow childs={
                                            <>
                                                <FormInput
                                                    examples={getTrans(transState, 854)}
                                                    label={getTrans(transState, 852)}
                                                    name='timeInfo'
                                                    onChange={p.handleProductChange}
                                                    tid='852, 854'
                                                    type={'textarea'}
                                                    validation={
                                                        [[['maxLength', 350]], productStartValidated, setProductStartValidated]
                                                    }
                                                    value={actProduct.timeInfo}
                                                    visible={true}
                                                />
                                            </>
                                        }
                                        />
                                    </form>
                                </div>
                            </>
                                : <>
                                    <p className={'f-s-de f-s-16'} tid='853'><Trans id={853}/></p>
                                    <div className={'p-t-16'}>
                                        <form name='ProductFormTime'>
                                            <FormRow childs={
                                                <>
                                                    <FormInput
                                                        label={getTrans(transState, 447)}
                                                        name='activeUntil'
                                                        onChange={p.handleProductChange}
                                                        required
                                                        tid='447'
                                                        type={'datetime'}
                                                        validation={
                                                            [['greaterNow'], p.productNoStartValidated, p.setProductNoStartValidated]
                                                        }
                                                        value={actProduct.activeUntil}
                                                        visible={true}
                                                    />
                                                </>
                                            }
                                            />
                                            <FormRow childs={
                                                <>
                                                    <FormInput
                                                        examples={<Trans html={true} id={854}/>}
                                                        label={getTrans(transState, 852)}
                                                        name='timeInfo'
                                                        onChange={p.handleProductChange}
                                                        tid='852, 854'
                                                        type={'textarea'}
                                                        validation={
                                                            [[['maxLength', 350]], p.productNoStartValidated, p.setProductNoStartValidated]
                                                        }
                                                        value={actProduct.timeInfo}
                                                        visible={true}
                                                    />
                                                </>
                                            }
                                            />
                                        </form>
                                    </div>
                                </>
                            }
                        </li>
                        <li className='p-lr-16'>
                            <h3 className={'c-pri-2-dark'} tid='839'><Trans id={839}/></h3>
                            <p className={'f-s-defaul f-s-16'} tid='841'><Trans id={841}/></p>
                            <Infobox
                                addClasses={'is--no-border is--icon-left m-t-16'}
                                content={getTrans(transState, 842)}
                                html={true}
                                icon={'info'}
                                tid='842, 843'
                                title={getTrans(transState, 843)}
                            />
                        </li>
                    </ul>
                </>
            }
            onClose={p.closeTimeLightbox}
            open={p.addTimesLB}
            tid='840'
            title={getTrans(transState, 840)}
        />
    )
}

export default withGlobalState(CourseProductAddTimesLB)
