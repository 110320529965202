import React from "react";

const SidebarLinks = (p) => {
    return (
        <nav>
            <ul>
                {p.children}
            </ul>
        </nav>
    )
}

export default SidebarLinks
