import axios from "axios";
import Popup from "react-popup";
import {hasKeys} from "../components/functions/FuncObjects";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ROOT_URL + 'api/retailer/',
    withCredentials: true
})

/**
 *
 * @param er
 * @param p
 * @param showMessage
 * @returns {*}
 */
export function catchError(er, p, showMessage = true) {
    const res = er.response

    if (hasKeys(res)) {
        switch (res.status) {
            case 400: {
                if (showMessage) Popup.plugins().prompt(res.data.title, res.data.detail, 5)
                break
            }
            case 401: {
                if (showMessage) Popup.plugins().prompt(res.data.title, res.data.detail, 5)

                if (p.globalState.auth.logedin) {
                    p.setGlobalState({
                        auth: {
                            logedin: false
                        }
                    })
                }

                break
            }
            case 404: {
                if (showMessage) Popup.plugins().prompt(res.data.title, res.data.detail, 5)
                break
            }
            case 500: {
                if (showMessage) Popup.plugins().prompt(res.data.title, res.data.detail, 5)
                break
            }
            default: {
                break
            }
        }
    }

    return er
}

/**
 *
 * @param res
 * @param showMessage
 */
const handleMessage = (res, showMessage = true) => {
    if (showMessage) {
        if (
            res.data !== undefined
            && hasKeys(res.data)
        ) {
            if (
                res.data.title !== undefined
                && res.data.title.length !== 0
                && res.data.detail !== undefined
                && res.data.detail.length !== 0
            ) {
                Popup.plugins().prompt(res.data.title, res.data.detail, 5)
            }
        }
    }
}

/**
 *
 * @param p
 */
const handleAuth = (p) => {
    if (!p.globalState.auth.logedin) {
        p.setGlobalState({
            auth: {
                logedin: true
            }
        })
    }
}

/**
 *
 * @param url
 * @param p
 * @param showMessage
 * @returns {Promise<unknown>}
 */
export async function apiGet(url, p, showMessage = false) {
    return await new Promise((resolve, reject) => {
        axiosInstance.get(url)
            .then(res => {
                handleMessage(res, showMessage)
                handleAuth(p)
                resolve(res);
            })
            .catch(er => {
                reject(catchError(er, p, showMessage))
            });
    })
}

/**
 *
 * @param url
 * @param p
 * @param data
 * @param showMessage
 * @returns {Promise<unknown>}
 */
export async function apiPut(url, p, data = {}, showMessage = true) {
    return await new Promise((resolve, reject) => {
        axiosInstance.put(url, data)
            .then((res) => {
                handleMessage(res, showMessage)
                handleAuth(p)
                resolve(res)
            })
            .catch((er) => {
                reject(catchError(er, p, showMessage))
            })
    })
}

/**
 *
 * @param url
 * @param p
 * @param data
 * @param showMessage
 * @returns {Promise<unknown>}
 */
export async function apiPatch(url, p, data = {}, showMessage = true) {
    return await new Promise((resolve, reject) => {
        axiosInstance.patch(url, data)
            .then((res) => {
                if (showMessage) Popup.plugins().prompt(res.data.title, res.data.detail, 5)
                handleAuth(p)
                resolve(res)
            })
            .catch((er) => {
                reject(catchError(er, p, showMessage))
            })
    })
}

/**
 *
 * @param url
 * @param p
 * @param data
 * @param showMessage
 * @param auth
 * @returns {Promise<unknown>}
 */
export async function apiPost(url, p, data = {}, showMessage = true, auth = true) {
    return await new Promise((resolve, reject) => {
        axiosInstance.post(url, data)
            .then((r) => {
                if (showMessage && showMessage !== 'onlyerr') Popup.plugins().prompt(r.data.title, r.data.detail, 5)
                if (auth) handleAuth(p)
                resolve(r)
            })
            .catch((e) => {
                reject(catchError(e, p, showMessage))
            })
    })
}

/**
 *
 * @param url
 * @param p
 * @param showMessage
 * @returns {Promise<unknown>}
 */
export async function apiDel(url, p, showMessage = true) {
    return await new Promise((resolve, reject) => {
        axiosInstance.delete(url)
            .then((r) => {
                if (showMessage) Popup.plugins().prompt(r.data.title, r.data.detail, 5)
                resolve(r)
            })
            .catch((e) => {
                reject(catchError(e, p, showMessage))
            });
    })
}
