import React from 'react'
import Page from "./Page";
import {withGlobalState} from "react-globally";
import Trans from "../components/elements/trans/Trans";

const PageOrders = () => {
    return (
        <Page
            tid='246'
            title={<Trans id={246}/>}
            contentElements={
                (
                    <div className='c-c__cols'>
                        <div className='c-tiles__grid12 is--grid-a-s'>
                            <div className='is--grid-6'>
                                <p className='c-pri-2-dark f-s-16 f-w-7' tid='504'><Trans id={504}/></p>
                                <div className='p-t-16'>
                                    <ul className='e-list__tiles is--message'>
                                        <li>
                                            <div>
                                                <p className='e-list__title' tid='507'><span className='e-list__icon ico-info'/><Trans id={507}/></p>
                                                <p className='e-list__text' tid='508'><Trans id={508}/></p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='is--grid-6'>
                                <p className='c-pri-2-dark f-s-16 f-w-7' tid='505'><Trans id={505}/></p>
                                <div className='p-t-16'>
                                    <ul className='e-list__tiles is--message'>
                                        <li>
                                            <div>
                                                <p className='e-list__text' tid='506'><Trans id={506}/></p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        />
    )
}

export default withGlobalState(PageOrders)