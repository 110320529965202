export const CourseProductObj = {
    activeUntil: null,
    archived: false,
    azav: null,
    courseCategories: [],
    contingent: 0,
    coursePlaceId: null,
    courseSpeaker: [],
    courseTypeId: 1,
    courseKindId: 1,
    courseType: {
        title: null
    },
    enddate: null,
    extendedCost: null,
    id: null,
    totPrice: null,
    productNumber: '',
    readyToPublished: false,
    publishedProduct: false,
    startdate: null,
    supportText: null,
    timeInfo: null
}