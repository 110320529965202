import React from 'react';
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import FormRow from "../../../components/elements/form/FormRow";
import {useSelector} from "react-redux";
import ComplexCourseSpeakerForm from "../../forms/courseSpeaker/ComplexCourseSpeakerForm";
import ButtonMenu from "../../../components/elements/menu/ButtonMenu";
import FormLoad from "../../../components/elements/form/FormLoad";
import FormInput from "../../../components/elements/form/FormInput";
import Infobox from "../../../components/elements/message/Infobox";
import {getTrans} from "../../../services/ServiceTrans";
import Trans from "../../../components/elements/trans/Trans";

const CourseProductSpeakerLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const transState = useSelector((state) => state.trans)
    const speaker = p.speaker
    const speakerOptions = p.selectedCourseSpeaker.map(speaker => {return {
        value: speaker,
        label: speaker.firstName + ' ' + speaker.lastName
    }})

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    const handleSelectChange = (e, params) => {
        p.handleCourseSpeakerSelectChange(params.value)
    }

    /************** button events **************/
    const handleSaveSpeaker = () => {
        p.handleSaveSpeaker()
    }

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeLightbox}
                            tid={'624'}
                        ><Trans id={624}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={handleSaveSpeaker}
                            tid={'625'}
                        ><Trans id={625}/></button>
                    </li>
                </>
            }
            addClasses='is--big is--fullwidth-lb'
            content={
                <>
                    <ul className='fl'>
                        <li className='p-lr-16 border border-w-r-1 border-c-lightgrey m-r-16'>
                            <div>
                                <p className={'f-s-de f-s-16'} tid={'626'}><Trans id={626}/></p>
                                <div className={'p-t-16'}>
                                    <div className={'p-t-16'}>
                                        <h4 className={'p-b-6'} tid={'627'}><Trans id={627}/></h4>
                                        <FormRow childs={
                                            <FormInput
                                                label={getTrans(transState, 628)}
                                                name='courseSpeaker'
                                                onChange={handleSelectChange}
                                                options={coursesState.courseSpeakerOptions}
                                                placeholder={getTrans(transState, 628)}
                                                type={'multiselect-by-objects'}
                                                value={speakerOptions}
                                            />
                                        }/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='p-lr-16'>
                            <p className={'f-s-de f-s-16'} tid={'629'}><Trans id={629}/></p>
                            <div className={'p-t-16'}>
                                <ComplexCourseSpeakerForm
                                    courseSpeaker={speaker}
                                    handleInputChange={p.handleInputChangeSpeaker}
                                    validated={p.validated}
                                    setValidated={p.setValidated}
                                />
                                <FormLoad a={p.addCourseSpeakerLoading}/>
                                <FormRow childs={
                                    <ButtonMenu items={[
                                        <button
                                            className='e-btn'
                                            disabled={!p.speakerSubmit}
                                            onClick={p.handleCourseSpeakerNewBtn}
                                            tid={'630'}
                                            type='submit'
                                        ><Trans id={630}/></button>
                                    ]}/>
                                }/>
                            </div>
                            {p.storedSpeaker &&
                                <div className='p-t-16'>
                                    <Infobox
                                        addClasses={'is--bg-ok is--no-border is--icon-left c-w'}
                                        icon={'info'}
                                        tid={'631'}
                                        title={getTrans(transState, 631)}
                                    />
                                </div>
                            }
                        </li>
                    </ul>
                </>
            }
            onClose={p.closeLightbox}
            open={p.addSpeakerLB}
            tid={'623'}
            title={getTrans(transState, 623)}
        />
    )
}

export default withGlobalState(CourseProductSpeakerLB)
