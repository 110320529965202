import React, {useEffect, useState} from 'react';
import Page from ".././Page";
import {withGlobalState} from "react-globally";
import FormRow from "../../components/elements/form/FormRow";
import {apiPatch} from "../../services/ServiceAPI";
import FormLoad from "../../components/elements/form/FormLoad";
import {hasKeys} from "../../components/functions/FuncObjects";
import Trans from "../../components/elements/trans/Trans";
import FormInput from "../../components/elements/form/FormInput";
import FormGrouping from "../../components/elements/form/FormGrouping";

const PagePassword = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form **************/
    const [validatedPassword, setValidatedPassword] = useState({})
    const [submitPassword, setSubmitPassword] = useState(false);
    const [passwords, setPasswords] = useState({
        accountPassword: '',
        accountPasswordRepeat: ''
    })

    /************** loading **************/
    const [loadingPassword, setLoadingPassword] = useState(false);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setSubmitPassword(!hasKeys(validatedPassword))
    }, [validatedPassword])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form events **************/
    const onPasswordChanges = (e) => {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value
        })
    }

    const handleFormSubmitLogin = (event) => {
        event.preventDefault()
        setLoadingPassword(true)
        setSubmitPassword(false)

        apiPatch('account/accessdata', p, {
            accountPassword: passwords.accountPassword,
            accountPasswordRepeat: passwords.accountPasswordRepeat
        })
            .finally(() => {
                setLoadingPassword(false)
            })
    }

    return (
        <Page
            contentElements={
                (
                    <div className='c-c__cols'>
                        <div className='mw-350px'>
                            <form onSubmit={e => handleFormSubmitLogin(e)}>
                                <FormGrouping
                                    title={<span tid='252'><Trans id={252}/></span>}
                                >
                                    <FormRow childs={
                                        <FormInput
                                            label={<Trans id={248}/>}
                                            name='accountPassword'
                                            onChange={e => {onPasswordChanges(e)}}
                                            onlyFocusValidation
                                            placeholder={<Trans id={247}/>}
                                            tid='247, 248'
                                            htmlType='password'
                                            validation={
                                                [['required', ['minLength', 8], ['passwordStrength', 2]], validatedPassword, setValidatedPassword]
                                            }
                                            value={passwords.accountPassword}
                                        />
                                    }
                                    />
                                    <FormRow childs={
                                        <FormInput
                                            label={<Trans id={250}/>}
                                            name='accountPasswordRepeat'
                                            onChange={e => onPasswordChanges(e)}
                                            onlyFocusValidation
                                            placeholder={<Trans id={249}/>}
                                            tid='249, 250'
                                            htmlType='password'
                                            validation={
                                                [['required', ['passwordEqual', passwords.accountPassword]], validatedPassword, setValidatedPassword]
                                            }
                                            value={passwords.accountPasswordRepeat}
                                        />
                                    }
                                    />
                                    <FormLoad a={loadingPassword}/>
                                    <FormRow childs={
                                        <button
                                            className='e-btn'
                                            disabled={!submitPassword}
                                            tid='251'
                                            title={<Trans id={251}/>}
                                            type='submit'
                                        ><Trans id={251}/></button>
                                    }/>
                                </FormGrouping>
                            </form>
                        </div>
                    </div>
                )
            }
            tid='374'
            title={<Trans id={374}/>}
        />
    )
}

export default withGlobalState(PagePassword)
