import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import {useDispatch, useSelector} from "react-redux";
import FormLoad from "../../components/elements/form/FormLoad";
import {getInputValue} from "../../components/functions/FuncForm";
import {
    setActCoursePlace,
    setActUnit, setCourseSpeaker,
    updateActCoursePlace, updateActCourseSpeaker,
    updateActProduct,
    updateActProductUnit
} from "../../store/reducer/courseSlice";
import {hasKeys} from "../../components/functions/FuncObjects";
import {apiDel, apiGet, apiPatch, apiPost} from "../../services/ServiceAPI";
import {buildDateStringWithTime, getDateString} from "../../services/ServiceDate";
import {CourseUnitObj} from "../../obj/Unit/CourseUnit.obj";
import ListItemUnit from "../../components/widgets/list/ListItemUnit";
import {PatchProductObj} from "../../obj/Product/PatchProduct.obj";
import DuplicateCourseProductLB from "../lightboxes/courseProduct/DuplicateCourseProductLB";
import CourseProductPlaceLB from "../lightboxes/coursePlace/CourseProductPlaceLB";
import UnitAddLB from "../lightboxes/courseUnit/UnitAddLB";
import UnitChangeLB from "../lightboxes/courseUnit/UnitChangeLB";
import CourseProductAddTimesLB from "../lightboxes/courseProduct/CourseProductAddTimesLB";
import {CoursePlacePost} from "../../obj/CoursePlace/CoursePlacePost.obj";
import CourseProductSpeakerLB from "../lightboxes/courseSpeaker/CourseProductSpeakerLB";
import ComplexCourseProductForm from "../forms/courseProduct/ComplexCourseProductForm";
import Trans from "../../components/elements/trans/Trans";
import {getTrans} from "../../services/ServiceTrans";

const ComplexCourseProduct = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const transState = useSelector((state) => state.trans)
    const dispatch = useDispatch()

    /************** loading **************/
    const [loadingProductDetail, setLoadingProductDetail] = useState(false)
    const [loadingAddUnit, setLoadingAddUnit] = useState(false)
    const [addCourseSpeakerLoading, setAddCourseSpeakerLoading] = useState(false)
    const [unitLoading, setUnitLoading] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [unitSaved, setUnitSaved] = useState(false)

    /************** form **************/
    /* states */
    const [productSubmit, setProductSubmit] = useState(false)
    const [addUnitSubmit, setAddUnitSubmit] = useState(false)
    const [unitSubmit, setUnitSubmit] = useState(false)
    const [timeSubmit, setTimeSubmit] = useState(false)
    const [placeSubmit, setPlaceSubmit] = useState(false)
    const [speakerSubmit, setSpeakerSubmit] = useState(false)

    /* validation */
    const [productValidated, setProductValidated] = useState({})
    const [addUnitValidated, setAddUnitValidated] = useState({})
    const [unitValidated, setUnitValidated] = useState({})
    const [placeValidated, setPlaceValidated] = useState({})
    const [productStartValidated, setProductStartValidated] = useState({})
    const [productNoStartValidated, setProductNoStartValidated] = useState({})
    const [speakerValidated, setSpeakerValidated] = useState({})
    const [validationShowProduct, setValidationShowProduct] = useState(false)
    const [validationShowUnitNew, setValidationShowUnitNew] = useState(false)
    const [validationShowUnitChange, setValidationShowUnitChange] = useState(false)

    /************** lightbox **************/
    const [unitAddLB, setUnitAddLB] = useState(false)
    const [unitChangeLB, setUnitChangeLB] = useState(false)
    const [addTimesLB, setAddTimesLB] = useState(false)
    const [addPlaceLB, setAddPlaceLB] = useState(false)
    const [addSpeakerLB, setAddSpeakerLB] = useState(false)
    const [courseProductDuplicateLB, setCourseProductDuplicateLB] = useState(false)

    /************** objects **************/
    const actProduct = coursesState.actProduct
    const actUnit = coursesState.actUnit
    const actCoursePlace = coursesState.actCoursePlace
    const actCourseSpeaker = coursesState.actCourseSpeaker
    const [oldProduct, setOldProduct] = useState({})
    const [unitAmount, setUnitAmount] = useState(0)
    const [units, setUnits] = useState([])

    /************** states **************/
    const [timePage, setTimePage] = useState('start')
    const [placePage, setPlacePage] = useState('place')
    const [placePageData, setPlacePageData] = useState('select')
    const [selectedCoursePlaceId, setSelectedCoursePlaceId] = useState(actProduct.coursePlaceId)
    const [selectedCourseSpeaker, setSelectedCourseSpeaker] = useState(actProduct.courseSpeaker)
    const [storedSpeaker, setStoredSpeaker] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setUnitAmount(coursesState.productUnits.length)

        let units = coursesState.productUnits

        setUnits(units.map((unit, i) => {
            let newUnit = {...unit}
            newUnit.sequence = i + 1
            return newUnit
        }))
    }, [coursesState.productUnits])

    useEffect(() => {
        setSpeakerSubmit(!hasKeys(speakerValidated))
        // eslint-disable-next-line
    }, [speakerValidated])

    useEffect(() => {
        setSelectedCoursePlaceId(actProduct.coursePlaceId)
    }, [actProduct.coursePlaceId])

    useEffect(() => {
        setSelectedCourseSpeaker(actProduct.courseSpeaker)
    }, [actProduct.courseSpeaker])

    useEffect(() => {
        setTimeSubmitStatus()
        // eslint-disable-next-line
    }, [productStartValidated, productNoStartValidated])

    useEffect(() => {
        setPlaceSubmitStatus()
        // eslint-disable-next-line
    }, [placeValidated])

    useEffect(() => {
        setProductSubmit(!hasKeys(productValidated))
    }, [productValidated])

    useEffect(() => {
        setAddUnitSubmit(!hasKeys(addUnitValidated))
    }, [addUnitValidated])

    useEffect(() => {
        setUnitSubmit(!hasKeys(unitValidated))
    }, [unitValidated])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** general **************/
    const getUnitById = (id) => {
        return coursesState.productUnits.filter(unit => {
            return unit.id === id
        })[0]
    }

    const getCoursePlaceById = (coursePlaceId) => {
        return coursesState.coursePlaces.filter(place => {
            return place.id === coursePlaceId
        })[0]
    }

    const setTimeSubmitStatus = () => {
        if (timePage === 'start') {
            setTimeSubmit(!hasKeys(productStartValidated))
        }else {
            setTimeSubmit(!hasKeys(productNoStartValidated))
        }
    }

    const setPlaceSubmitStatus = () => {
        if (placePage === 'place') {
            setPlaceSubmit(!hasKeys(placeValidated))
        }else {
            setPlaceSubmit(true)
        }
    }

    /************** input events **************/
    /* change */
    const handleProductChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        dispatch(updateActProduct({[input.name]: input.value}))
    }

    const handleUnitChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        dispatch(updateActProductUnit({[input.name]: input.value}))
    }

    const handleNewUnitChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        dispatch(updateActProductUnit({[input.name]: input.value}))
    }

    const handleInputChangePlace = (event, params = {}) => {
        const input = getInputValue(event, params)
        dispatch(updateActCoursePlace({[input.name]: input.value}))
    }

    const handleInputChangeSpeaker = (event, params = {}) => {
        const input = getInputValue(event, params)
        dispatch(updateActCourseSpeaker({[input.name]: input.value}))
    }

    const handleCoursePlaceSelectChange = (coursePlaceId) => {
        setSelectedCoursePlaceId(coursePlaceId)
    }

    const handleCourseSpeakerSelectChange = (courseSpeaker) => {
        setSelectedCourseSpeaker(courseSpeaker)
    }

    /* submit */
    const handleProductFormSubmit = (e = null, callback = null) => {
        if (e !== null) e.preventDefault()
        setValidationShowProduct(true)

        if (productSubmit) {
            setLoadingProductDetail(true)

            let patchProductObj = {...PatchProductObj}

            Object.keys(patchProductObj).forEach((key) => {
                patchProductObj[key] = actProduct[key]
            })
            patchProductObj.courseTypeId = actProduct.courseTypeId;
            apiPatch('courses/' + coursesState.actCourse.id + '/products/' + actProduct.id, p, patchProductObj)
                .finally(() => {
                    setValidationShowProduct(false)
                    setLoadingProductDetail(false)
                    if (callback === null) {
                        p.getCourseProductList(coursesState.actCourse.id)
                        p.onCourseProductItemClick(coursesState.actCourse.id, actProduct.id)
                    }else {
                        callback()
                    }
                })
        }
    }

    /************** button events **************/
    const addProductModuleHandler = () => {
        let actUnitPrepare = {...CourseUnitObj}
        actUnitPrepare.sequence = unitAmount + 1
        setUnitAmount(unitAmount + 1)
        dispatch(setActUnit(actUnitPrepare))
        setUnitAddLB(true)
    }

    const closeLightbox = () => {
        setUnitAddLB(false)
        setUnitChangeLB(false)
        setCourseProductDuplicateLB(false)
        setAddPlaceLB(false)
    }

    const handleLBAddUnitBtn = () => {
        setValidationShowUnitNew(true)
        if (addUnitSubmit) {
            setLoadingAddUnit(true)

            apiPost('courses/' + coursesState.actCourse.id + '/products/' + actProduct.id + '/units', p, actUnit)
                .then(() => {
                    setUnitAddLB(false)
                })
                .finally(() => {
                    setLoadingAddUnit(false)
                    p.getUnitsList(coursesState.actCourse.id, actProduct.id)
                    setValidationShowUnitNew(false)
                })
        }
    }

    const handleLBChangeUnitBtn = () => {
        setValidationShowUnitChange(true)
        if (unitSubmit) {
            setLoadingAddUnit(true)
            apiPatch('courses/' + coursesState.actCourse.id + '/products/' + actProduct.id + '/units/' + actUnit.id, p, actUnit)
                .finally(() => {
                    setLoadingAddUnit(false)
                    setUnitChangeLB(false)
                    p.getUnitsList(coursesState.actCourse.id, actProduct.id)
                    setValidationShowUnitChange(false)
                })
        }
    }

    const handleChangePosBtn = (sequence, change) => {
        let unitSequences = []
        if (change === 'up') {
            unitSequences = units.map(unit => {
                let changed = false
                if (unit.sequence === sequence + 1) {
                    unit.sequence = sequence
                    changed = true
                }
                if (unit.sequence === sequence && !changed && unit.sequence !== unitAmount) unit.sequence = sequence + 1
                return unit
            })
        }else {
            unitSequences = units.map(unit => {
                let changed = false
                if (unit.sequence === sequence - 1) {
                    unit.sequence = sequence
                    changed = true
                }
                if (unit.sequence === sequence && !changed && unit.sequence !== 1) unit.sequence = sequence - 1
                return unit
            })
        }
        setUnits(unitSequences)

        updateCourseUnits(unitSequences)
    }

    const onUnitChangeClick = (id) => {
        dispatch(setActUnit(getUnitById(id)))
        setUnitChangeLB(true)
    }

    const handleLBChangeUnitDeleteBtn = () => {
        if (window.confirm(getTrans(transState, 699))) {
            apiDel('courses/' + coursesState.actCourse.id + '/products/' + actProduct.id + '/units/' + actUnit.id, p)
                .finally(() => {
                    setUnitChangeLB(false)
                    p.getUnitsList(coursesState.actCourse.id, actProduct.id)
                })
        }
    }

    const handleAddTimes = (e) => {
        e.preventDefault()
        setOldProduct({...actProduct})
        setAddTimesLB(true)
        if (actProduct.startdate === null) setTimePage('nostart')
        setTimeSubmitStatus()
    }

    const handleAddPlace = (e) => {
        e.preventDefault()
        setAddPlaceLB(true)
    }

    const handleAddSpeaker = (e) => {
        e.preventDefault()
        setAddSpeakerLB(true)
    }

    const handleChangePage = (page) => {
        setTimePage(page)
    }

    const handleChangePagePlace = (page) => {
        if (page === 'noplace') setPlaceSubmit(true)
        setPlacePage(page)
    }

    const handleChangePagePlaceData = (page) => {
        if (page === 'select') setPlaceSubmit(true)
        setPlacePageData(page)
    }

    const handleSaveTimes = () => {
        setAddTimesLB(false)
        if (timePage !== 'start') {
            dispatch(updateActProduct({startdate: null}))
            dispatch(updateActProduct({enddate: null}))
        }
    }

    const handleSavePlace = () => {
        setAddPlaceLB(false)

        if (placePage === 'place') {
            if (placePageData === 'new') {
                apiPost('courses/products/places', p, actCoursePlace)
                    .then((r) => {
                        updateCourseProductPlace(r.data.id)
                    })
            }else {
                updateCourseProductPlace(selectedCoursePlaceId)
            }
        }else {
            updateCourseProductPlace(null)
        }
    }

    const handleSaveSpeaker = () => {
        setAddSpeakerLB(false)
        apiPatch('courses/' + coursesState.actCourse.id + '/products/' + coursesState.actProduct.id, p, {
            courseSpeaker: selectedCourseSpeaker
        })
            .finally(() => {
                p.onCourseProductItemClick(coursesState.actCourse.id, actProduct.id)
            })
    }

    const closeTimeLightbox = () => {
        setAddTimesLB(false)
        dispatch(updateActProduct({startdate: oldProduct.startdate}))
        dispatch(updateActProduct({enddate: oldProduct.enddate}))
        dispatch(updateActProduct({activeUntil: oldProduct.activeUntil}))
        dispatch(updateActProduct({timeInfo: oldProduct.timeInfo}))
    }

    const closePlaceLightbox = () => {
        setAddPlaceLB(false)
    }

    const closeSpeakerLightbox = () => {
        setAddSpeakerLB(false)
    }

    const handleLBDuplicateCourseProductBtn = (withModules = false) => {
        p.duplicateProduct(coursesState.actCourse.id, actProduct.id, withModules)
    }

    const handleCourseProductDuplicate = () => {
        setCourseProductDuplicateLB(true)
    }

    const handleCourseSpeakerNewBtn = () => {
        setAddCourseSpeakerLoading(true)
        apiPost('courses/products/speaker', p, coursesState.actCourseSpeaker)
            .then(() => {
                apiGet('courses/speaker', p)
                    .then(r => {
                        dispatch(setCourseSpeaker(r.data))
                        setStoredSpeaker(true)
                    })
            })
            .finally(() => {
                setAddCourseSpeakerLoading(false)
            })
    }

    /************** api events **************/
    const updateCourseProductPlace = (coursePlaceId) => {
        handleProductFormSubmit(null, () => apiPatch('courses/' + coursesState.actCourse.id + '/products/' + coursesState.actProduct.id, p, {
            coursePlaceId: coursePlaceId
        })
            .finally(() => {
                p.getCoursePlaceList(() => {
                    dispatch(setActCoursePlace(CoursePlacePost))
                    p.getCourseProductList(coursesState.actCourse.id)
                    p.onCourseProductItemClick(coursesState.actCourse.id, actProduct.id)
                    closeLightbox()
                    setPlacePageData('select')
                })
            }))
    }

    const updateCourseUnits = (unitSequences) => {
        setUnitLoading(true)
        setUnitSaved(false)
        const patchUnits = unitSequences.map((item) => {
            return {
                'id': item.id,
                'sequence': item.sequence
            }
        })

        apiPatch('course/' + coursesState.actCourse.id + '/product/' + actProduct.id + '/units/sequence', p, patchUnits, false)
            .finally(() => {
                setUnitLoading(false)
                setUnitSaved(true)
            })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** general **************/
    const statusTrans = {
        inactive: getTrans(transState, 693),
        activeNotPublished: getTrans(transState, 694),
        publishedNotBookable: getTrans(transState, 695)
    }

    return (
        <>
            <ContentTabs
                body={p.loadingCourseProduct
                    ? <FormLoad a={true}/>
                    : <div className='fl'>
                        <div className='is--full-12 is--6'>
                            {actProduct.courseProductStatus
                                && <>{
                                    Object.keys(actProduct.courseProductStatus).map(key => {
                                        console.log(actProduct.courseProductStatus[key])
                                        if (actProduct.courseProductStatus[key].status && actProduct.courseProductStatus[key].messages.length !== 0) {
                                            const content = actProduct.courseProductStatus[key].messages.map(message => <li>
                                                <div key={message.title}>
                                                    <p className='e-list__title'>
                                                        <span className='e-list__icon ico-info is--bg-pri6'/>
                                                        <span>{message.title}</span>
                                                    </p>
                                                    <p className='e-list__text'>{message.content}</p>
                                                </div>
                                            </li>)
                                            return <div>
                                                <h3 className={'f-s-de f-w-7'}>Status</h3>
                                                <p className={'f-w-5 f-s-de c-status-warn p-b-6'}>{statusTrans[key]}</p>
                                                <ul className='e-list__tiles is--message'>
                                                    {content}
                                                </ul>
                                            </div>
                                        }
                                        return ''
                                    })
                                }</>
                            }
                            <h3 className={'c-tab__content-title p-b-16 p-l-16'} tid='696'><Trans id={696}/></h3>
                            <ComplexCourseProductForm
                                actProduct={actProduct}
                                coursePlace={getCoursePlaceById(actProduct.coursePlaceId)}
                                handleAddPlace={handleAddPlace}
                                handleAddSpeaker={handleAddSpeaker}
                                handleAddTimes={handleAddTimes}
                                handleProductChange={handleProductChange}
                                handleProductFormSubmit={handleProductFormSubmit}
                                loadingProductDetail={loadingProductDetail}
                                productSubmit={productSubmit}
                                productValidated={productValidated}
                                setProductValidated={setProductValidated}
                                validationShow={validationShowProduct}
                            />
                            <div className={'p-t-16 is--brt m-t-16 p-l-16'}>
                                <p className={'e-def'}>
                                    <span tid='492'><Trans id={492}/>:</span>
                                    <span>{actProduct.productNumber}</span>
                                </p>
                                <p className={'e-def'}>
                                    <span tid='697'><Trans id={697}/>:</span>
                                    <span tid='495'>{buildDateStringWithTime(new Date(actProduct.creation))} <Trans id={495}/></span>
                                </p>
                            </div>
                        </div>
                        {p.loadingUnit
                            ? <div className='fl fl-jc-center is--full-12'>
                                <FormLoad a={true}/>
                            </div>
                            : <div className='p-l-16 is--full-12 is--6'>
                                <div>
                                    <div className='c-tab__head-top'>
                                        <div>
                                            <h3 className={'c-tab__content-title'} tid='698'><Trans id={698}/></h3>
                                        </div>
                                        <div>
                                            <nav className='c-tab__head-actions'>
                                                <ul>
                                                    <li>
                                                        <button className='e-btn__action ico-plus-circled' onClick={addProductModuleHandler}/>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className='c-itemlist__container p-tb-16'>
                                    <div>
                                        <ul className='c-itemlist'>
                                            {(typeof units !== 'undefined' && units !== null)
                                                && <>{units.sort((a, b) => {return a.sequence - b.sequence}).map((unit, i, a) =>
                                                    <ListItemUnit
                                                        amount={a.length}
                                                        handleChangePosBtn={handleChangePosBtn}
                                                        index={i}
                                                        key={unit.id}
                                                        onclick={onUnitChangeClick}
                                                        unit={unit}
                                                        seq={unit.sequence}
                                                    />
                                                )}</>
                                            }
                                        </ul>
                                    </div>
                                    <div>
                                        {unitLoading &&
                                            <FormLoad a={true}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                head={<div className='c-tab__head-top'>
                    <div className='w-100 fl fl-jc-sb'>
                        {!p.loadingCourseProduct &&
                            <>
                                <p className='c-tab__head-title f-w-9' tid={'498, 495'}>
                                    <Trans id={498}/>&nbsp;
                                    {actProduct.startdate !== null ?
                                        (<>{getDateString(new Date(actProduct.startdate))} <Trans id={495}/></>)
                                        : <span tid={'577'}>(<Trans id={577}/>)</span>
                                    }
                                    {(actProduct.coursePlace && actProduct.coursePlace.city) &&
                                        <>, {actProduct.coursePlace.city.substring(0, 30)}</>
                                    }
                                </p>
                                {actProduct.productNumberPrivate !== null &&
                                    <p>
                                        <span className='c-grey'> {actProduct.productNumber}</span><span className='c-grey'> / {actProduct.productNumberPrivate}</span>
                                    </p>
                                }
                            </>
                        }
                    </div>
                    <div>
                        <nav className='c-tab__head-actions'>
                            <ul>
                                <li>
                                    <button
                                        className='e-btn__action ico-retweet'
                                        onClick={handleCourseProductDuplicate}
                                        tid={'609'}
                                        title={getTrans(transState, 609)}
                                    />
                                </li>
                                <li>
                                    <button
                                        className='e-btn__action ico-eye-off'
                                        onClick={() => p.archiveCourseProductLBBtn(actProduct.id)}
                                        tid={'610'}
                                        title={getTrans(transState, 610)}
                                    />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>}
                onOpen={p.onTabOpen}
                open={p.openCourseProductDetail}
                tid={'698'}
                title={getTrans(transState, 698)}
                width={p.width}
            />
            <UnitAddLB
                actUnit={actUnit}
                addUnitSubmit={addUnitSubmit}
                addUnitValidated={addUnitValidated}
                closeLightbox={closeLightbox}
                handleLBAddUnitBtn={handleLBAddUnitBtn}
                handleNewUnitChange={handleNewUnitChange}
                loadingAddUnit={loadingAddUnit}
                setAddUnitValidated={setAddUnitValidated}
                unitAddLB={unitAddLB}
                unitAmount={unitAmount}
                validationShowUnitNew={validationShowUnitNew}
            />
            <UnitChangeLB
                actUnit={actUnit}
                unitSubmit={unitSubmit}
                unitValidated={unitValidated}
                closeLightbox={closeLightbox}
                handleLBChangeUnitBtn={handleLBChangeUnitBtn}
                handleLBChangeUnitDeleteBtn={handleLBChangeUnitDeleteBtn}
                handleUnitChange={handleUnitChange}
                loadingAddUnit={loadingAddUnit}
                setUnitValidated={setUnitValidated}
                unitChangeLB={unitChangeLB}
                validationShowUnitChange={validationShowUnitChange}
            />
            <CourseProductAddTimesLB
                actProduct={actProduct}
                addTimesLB={addTimesLB}
                closeTimeLightbox={closeTimeLightbox}
                handleChangePage={handleChangePage}
                handleProductChange={handleProductChange}
                handleSaveTimes={handleSaveTimes}
                productNoStartValidated={productNoStartValidated}
                productStartValidated={productStartValidated}
                setProductNoStartValidated={setProductNoStartValidated}
                setProductStartValidated={setProductStartValidated}
                timePage={timePage}
                timeSubmit={timeSubmit}
            />
            <DuplicateCourseProductLB
                closeLightbox={closeLightbox}
                courseProductDuplicateLB={courseProductDuplicateLB}
                handleLBDuplicateCourseProductBtn={handleLBDuplicateCourseProductBtn}
            />
            <CourseProductPlaceLB
                addPlaceLB={addPlaceLB}
                closeLightbox={closePlaceLightbox}
                handleChangePagePlace={handleChangePagePlace}
                handleChangePagePlaceData={handleChangePagePlaceData}
                handleInputChangePlace={handleInputChangePlace}
                handleSavePlace={handleSavePlace}
                place={actCoursePlace}
                placePage={placePage}
                placePageData={placePageData}
                placeSubmit={placeSubmit}
                handleCoursePlaceSelectChange={handleCoursePlaceSelectChange}
                selectedCoursePlaceId={selectedCoursePlaceId}
                setValidated={setPlaceValidated}
                validated={placeValidated}
            />
            <CourseProductSpeakerLB
                addCourseSpeakerLoading={addCourseSpeakerLoading}
                addSpeakerLB={addSpeakerLB}
                closeLightbox={closeSpeakerLightbox}
                handleCourseSpeakerNewBtn={handleCourseSpeakerNewBtn}
                handleInputChangeSpeaker={handleInputChangeSpeaker}
                handleSaveSpeaker={handleSaveSpeaker}
                speaker={actCourseSpeaker}
                speakerSubmit={speakerSubmit}
                handleCourseSpeakerSelectChange={handleCourseSpeakerSelectChange}
                selectedCourseSpeaker={selectedCourseSpeaker}
                setValidated={setSpeakerValidated}
                storedSpeaker={storedSpeaker}
                validated={speakerValidated}
            />
        </>
    )
}

export default withGlobalState(ComplexCourseProduct)
