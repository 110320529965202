import React from 'react';
import {withGlobalState} from "react-globally";
import {showSearchResult} from "../../../services/ServiceData";
import Trans from "../../elements/trans/Trans";

const ListItemCourseArchived = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const course = p.course

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleRearchiveBtn = (cid) => {
        p.rearchiveCourse(cid)
    }

    return (
        <li className={`is--${p.status} is--info`}>
            <div className='c-itemlist__content'>
                <div>
                    <p>{showSearchResult(p.search, course.courseNumber, 'f-w-7 c-pri-2-dark')} {showSearchResult(p.search, course.title, 'f-w-7 c-pri-2-dark')}</p>
                </div>
            </div>
            <div className='c-itemlist__actions c-optionlist__holder'>
                <button
                    className='e-btn__action ico-eye'
                    onClick={() => handleRearchiveBtn(course.id)}
                    tid={'576'}
                    title={<Trans id={576}/>}
                />
            </div>
        </li>
    )
}

export default withGlobalState(ListItemCourseArchived)
