import React from 'react'

const FormWrap = (p) => {
    return (
        <div className={`e-form__wrap ${p.addClasses}`}>
            {p.childs}
        </div>
    )
}

export default FormWrap
