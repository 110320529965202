import React from 'react';
import Page from "../Page";
import {withGlobalState} from "react-globally";
import Trans from "../../components/elements/trans/Trans";
import {handleLogout} from "../../App";

const PageMailApproving = (p) => {
    return (
        <Page
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <div className='fl fl-jc-center'>
                        <div className='w-1_3 rad-32 border border-w-1 border-c-font-lightgrey p-lr-38 p-tb-38'>
                            <div className='ico-mail p-b-16 after-f-s-24 a-c'/>
                            <h1 className='f-w-7 f-s-18 c-font-darkgrey a-c p-b-16' tid='652'><Trans id={652}/></h1>
                            <div className='c-font-darkgrey' tid='653'>
                                <Trans
                                    html={true}
                                    id={653}
                                    param={{mail: p.globalState.user.accountMail}}
                                />
                            </div>
                            <p className='p-t-16 c-font-darkgrey a-c' tid='654'><Trans id={654}/></p>
                            <p className='c-font-darkgrey a-c' tid='655'>
                                <Trans
                                    id={655}
                                    param={{mail: p.globalState.user.accountMail}}
                                />
                            </p>
                            <div className='fl fl-jc-center'>
                                <p
                                    className={'c-font-darkgrey hover-font-ul'}
                                    onClick={() => handleLogout(p)}
                                    tid='195'
                                >
                                    <Trans id={656}/>
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            title=''
        />
    )
}

export default withGlobalState(PageMailApproving)
