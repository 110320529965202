import React, {useEffect, useRef} from 'react';
import {withGlobalState} from "react-globally";
import Trans from "../../elements/trans/Trans";

const LB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const refLB = useRef(null)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleClickOutside = (event) => {
        if (refLB.current !== null && !refLB.current.contains(event.target)) if (p.onClose) p.onClose()
    }

    const handleClose = () => {
        if (p.onClose) p.onClose()
    }
    
    if (p.open) {
        return (
            <div className={`c-lightbox ${p.addClasses? p.addClasses : ''}`}>
                <div className='c-lightbox__inner' ref={refLB}>
                    <button
                        className='e-btn c-lightbox__close ico-cancel is--icon is--no-effects-trans'
                        onClick={handleClose}
                        tid='409'
                        title={<Trans id={409}/>}
                    />
                    <div className={'c-lightbox__holder'}>
                        <h3 className='p-b-16' tid={p.tid}>{p.title}</h3>
                        {p.content}
                    </div>
                    <div className='c-lightbox__actions'>
                        <ul>
                            {p.actions}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    return ('')
}

export default withGlobalState(LB)
