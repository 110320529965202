import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import FormInput from "../../../components/elements/form/FormInput";
import Trans from "../../../components/elements/trans/Trans";

const ComplexCoursePlaceForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const addCoursePlace = p.coursePlace

    /************** form **************/
    const [inputs] = useState({
        title: [[['maxLength', 128]]],
        street: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        houseNumber: [['required'], [['maxLength', 30]]],
        zip: [['required'], [['length', 5]]],
        city: [['required'], [['maxLength', 128]], [['minLength', 2]]]
    })

    return (
        <div>
            <form autoComplete='off'>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={756}/>}
                        name='title'
                        onChange={p.handleInputChange}
                        tid='756'
                        validationChecks={inputs.title}
                        validationShow={p.validationShow}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                        value={addCoursePlace.title}
                        visible={true}
                    />
                }
                />
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={264}/>}
                            name='street'
                            onChange={p.handleInputChange}
                            placeholder={<Trans id={264}/>}
                            required
                            tid='264'
                            validationChecks={inputs.street}
                            validationShow={p.validationShow}
                            validationAct={p.validated}
                            validationSet={p.setValidated}
                            value={addCoursePlace.street}
                            visible={true}
                        />
                        <FormInput
                            label={<Trans id={266}/>}
                            name='houseNumber'
                            onChange={p.handleInputChange}
                            placeholder={<Trans id={266}/>}
                            required
                            tid='266'
                            validationChecks={inputs.houseNumber}
                            value={addCoursePlace.houseNumber}
                            validationShow={p.validationShow}
                            validationAct={p.validated}
                            validationSet={p.setValidated}
                            visible={true}
                        />
                    </>
                }
                />
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={268}/>}
                            name='zip'
                            onChange={p.handleInputChange}
                            placeholder={<Trans id={268}/>}
                            required
                            tid='268'
                            validationChecks={inputs.zip}
                            validationShow={p.validationShow}
                            validationAct={p.validated}
                            validationSet={p.setValidated}
                            value={addCoursePlace.zip}
                            visible={true}
                        />
                        <FormInput
                            label={<Trans id={270}/>}
                            name='city'
                            onChange={p.handleInputChange}
                            placeholder={<Trans id={270}/>}
                            required
                            tid='270'
                            validationChecks={inputs.city}
                            validationShow={p.validationShow}
                            validationAct={p.validated}
                            validationSet={p.setValidated}
                            value={addCoursePlace.city}
                            visible={true}
                        />
                    </>
                }/>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={755}/>}
                        name='additional'
                        onChange={p.handleInputChange}
                        placeholder={<Trans id={755}/>}
                        tid='755'
                        value={addCoursePlace.additional}
                        visible={true}
                    />
                }/>
            </form>
            {p.children}
        </div>
    )
}

export default withGlobalState(ComplexCoursePlaceForm)
