import React from "react";
import {withGlobalState} from "react-globally";
import FormLoad from "../../../components/elements/form/FormLoad";
import FileUpload from "../../../components/elements/form/FileUpload";
import Trans from "../../../components/elements/trans/Trans";
import FormGrouping from "../../../components/elements/form/FormGrouping";

const ComplexProfileImagesForm = (p) => {
    return (
        <>
            <FormGrouping
                title={<span tid='242'><Trans id={242}/></span>}
            >
                <FileUpload
                    label={<Trans id={235}/>}
                    onChange={p.handleLogoChange}
                    required
                    submit={p.submitLogo}
                    tid='235'
                    upload={p.handleFormSubmitLogo}
                    value={p.profile.imageLogo}
                />
                <FormLoad a={p.loadingLogo}/>
                <FileUpload
                    label={<Trans id={236}/>}
                    submit={p.submitTitleImage}
                    tid='236'
                    onChange={p.handleTitleImageChange}
                    upload={p.handleFormSubmitTitleImage}
                    value={p.profile.imageTitle}
                />
                <FormLoad a={p.loadingTitleImage}/>
            </FormGrouping>
        </>
    )
}

export default withGlobalState(ComplexProfileImagesForm)
