import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import FormInput from "../../../components/elements/form/FormInput";
import Trans from "../../../components/elements/trans/Trans";

const ComplexCoursePlaceAddForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const place = p.place

    /************** form **************/
    const [inputs] = useState({
        title: [[['maxLength', 128]]],
        street: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        houseNumber: [['required'], [['maxLength', 30]]],
        zip: [['required'], [['length', 5]]],
        city: [['required'], [['maxLength', 128]], [['minLength', 2]]]
    })

    return (
        <form name='ProductFormPlace'>
            <FormRow childs={
                <>
                    <FormInput
                        label={<Trans id={264}/>}
                        name='street'
                        onChange={p.handleInputChangePlace}
                        placeholder={<Trans id={264}/>}
                        required
                        tid='264'
                        validationChecks={inputs.street}
                        validationShow={p.validationShow}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                        value={place.street}
                        visible={true}
                    />
                    <FormInput
                        label={<Trans id={266}/>}
                        name='houseNumber'
                        onChange={p.handleInputChangePlace}
                        placeholder={<Trans id={266}/>}
                        required
                        tid='266'
                        validationChecks={inputs.houseNumber}
                        value={place.houseNumber}
                        visible={true}
                    />
                </>
            }/>
            <FormRow childs={
                <FormInput
                    label={<Trans id={755}/>}
                    name='additional'
                    onChange={p.handleInputChangePlace}
                    placeholder={<Trans id={755}/>}
                    tid='755'
                    value={place.additional}
                    visible={true}
                />
            }/>
            <FormRow childs={
                <>
                    <FormInput
                        label={<Trans id={268}/>}
                        name='zip'
                        onChange={p.handleInputChangePlace}
                        placeholder={<Trans id={268}/>}
                        required
                        tid='268'
                        validationChecks={inputs.zip}
                        value={place.zip}
                        visible={true}
                    />
                    <FormInput
                        label={<Trans id={270}/>}
                        name='city'
                        onChange={p.handleInputChangePlace}
                        placeholder={<Trans id={270}/>}
                        required
                        tid='270'
                        validationChecks={inputs.city}
                        value={place.city}
                        visible={true}
                    />
                </>
            }/>
        </form>
    )
}

export default withGlobalState(ComplexCoursePlaceAddForm)
