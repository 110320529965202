import React from 'react'
import HeaderLogo from "../elements/img/HeaderLogo";
import HeaderMainNav from "../widgets/menu/HeaderMainNav";
import {NavLink} from "react-router-dom";
import {withGlobalState} from "react-globally";
import HeaderPublicNav from "../widgets/menu/HeaderPublicNav";
import Trans from "../elements/trans/Trans";
import {getTrans} from "../../services/ServiceTrans";
import {useSelector} from "react-redux";
import {handleLogout} from "../../App";

const Header = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const transState = useSelector((state) => state.trans)

    return (<>
        {p.nomenu
            ? <header className='shad-line h-60px pos-relative fl w-100 c-bg-w fl-a-items-center fl-jc-sb p-lr-16 zindex-100 w-100'>
                <div className='fl fl-a-items-center h-100'>
                    <HeaderLogo/>
                    <HeaderPublicNav/>
                </div>
                <div className='fl fl-a-items-center h-100'>
                    <nav className="h-100">
                        <ul className="fl h-100">
                            <li className='h-100'>
                                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                <a
                                    className='before-p-r-6 hover-c-pr-2 fl fl-a-items-center h-100 f-w-4 p-lr-16 transition-de-color ico-help-circled'
                                    href='https://www.fobimarkt.com/kontakt'
                                    rel='noreferrer'
                                    target='_blank'
                                    tid={'369'}
                                    title={<Trans id={369}/>}
                                />
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            : <header className='shad-line h-60px pos-relative fl w-100 c-bg-c fl-a-items-center fl-jc-sb p-lr-16 zindex-100'>
                <div className='fl fl-a-items-center h-100'>
                    <HeaderLogo/>
                    <HeaderMainNav/>
                </div>
                <div className='fl fl-a-items-center h-100'>
                    <nav className="h-100">
                        <ul className="fl h-100">
                            <li className='h-100'>
                                <NavLink
                                    activeClassName="complex-menu-ul"
                                    className='before-p-r-6 hover-c-pr-2 fl fl-a-items-center h-100 f-w-4 p-lr-16 transition-de-color ico-bell pos-relative'
                                    tid={'370'}
                                    title={getTrans(transState, 370)}
                                    to="/mitteilungen"
                                />
                            </li>
                            <li className='h-100'>
                                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                <a
                                    className='before-p-r-6 hover-c-pr-2 fl fl-a-items-center h-100 f-w-4 p-lr-16 transition-de-color ico-help-circled'
                                    href='https://www.fobimarkt.com/kontakt'
                                    rel='noreferrer'
                                    target='_blank'
                                    tid='369'
                                    title={getTrans(transState, 369)}
                                />
                            </li>
                            <li className='h-100'>
                                <a
                                    className='before-p-r-6 hover-c-pr-2 fl fl-a-items-center h-100 f-w-4 p-lr-16 transition-de-color'
                                    href='https://www.fobimarkt.com'
                                    rel='noreferrer'
                                    target='_blank'
                                    tid='531'
                                    title={getTrans(transState, 531)}
                                ><Trans id={531}/></a>
                            </li>
                        </ul>
                    </nav>
                    <div className='p-l-16'>
                        <button
                            className='c-bg-w rad-6 c-pr-2-dark p-tb-10 p-lr-16 hover-outline hover-outline-c-pr-2-dark hover-outline-w-2'
                            onClick={() => handleLogout(p)}
                            tid='308, 372'
                            title={getTrans(transState, 372)}
                        ><Trans id={308}/></button>
                    </div>
                </div>
            </header>
        }
    </>)
}

export default withGlobalState(Header)
