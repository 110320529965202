import React, {useEffect, useState} from 'react';
import Page from "../Page";
import Trans from "../../components/elements/trans/Trans";
import {withGlobalState} from "react-globally";
import {useDispatch, useSelector} from "react-redux";
import {apiGet} from "../../services/ServiceAPI";
import {setPricing} from "../../store/reducer/invoiceSlice";
import FormLoad from "../../components/elements/form/FormLoad";
import {getDoubleString} from "../../services/ServiceData";
import {buildDateStringNoTime} from "../../services/ServiceDate";
import Tile from "../../components/widgets/tiles/tile";
import {getTrans} from "../../services/ServiceTrans";

const PageInvoice = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [loadingPricing, setLoadingPricing] = useState(false)

    /************** global **************/
    const invoiceState = useSelector((state) => state.invoice)
    const transState = useSelector((state) => state.trans)
    const dispatch = useDispatch()

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (invoiceState.pricing.title === null) getPricing()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** api calls **************/
    const getPricing = () => {
        setLoadingPricing(true)
        apiGet('accounting/pricing', p)
            .then((r) => {
                dispatch(setPricing(r.data))
            })
            .finally(() => {
                setLoadingPricing(false)
            })
    }

    return (
        <Page
            contentElements={
                (
                    <>
                        {loadingPricing
                            ? <FormLoad a={true}/>
                            : <div className='c-c__cols'>
                                <div className='c-tiles__grid12 is--grid-a-s'>
                                    <Tile
                                        content={<>
                                            {invoiceState.pricing.title
                                                ? <>
                                                    <p tid='883'><Trans id={883}/>: {invoiceState.pricing.title}</p>
                                                    {invoiceState.pricing.annualFee &&
                                                        <p className="f-s-12">
                                                            <span tid='884'><Trans id={884}/></span>
                                                            <span className="f-w-5"> {getDoubleString(invoiceState.pricing.annualFee)} &euro;</span>
                                                        </p>
                                                    }
                                                    {invoiceState.pricing.productMargin &&
                                                        <p className="f-s-12">
                                                            <span tid='885'><Trans id={885}/></span>
                                                            <span className="f-w-5"> {getDoubleString(invoiceState.pricing.productMargin)} %</span>
                                                        </p>
                                                    }
                                                    {invoiceState.pricing.leadFixedPrice &&
                                                        <p className="f-s-12">
                                                            <span tid='886'><Trans id={886}/></span>
                                                            <span className="f-w-5"> {getDoubleString(invoiceState.pricing.leadFixedPrice)} &euro;</span>
                                                        </p>
                                                    }
                                                    {invoiceState.pricing.pricingAddedAt &&
                                                        <p className="f-s-12">
                                                            <span tid='887'><Trans id={887}/></span>
                                                            <span className="f-w-5"> {buildDateStringNoTime(new Date(invoiceState.pricing.pricingAddedAt))}</span>
                                                        </p>
                                                    }
                                                    {invoiceState.pricing.afterExpiration &&
                                                        <>
                                                            <p className="f-s-12">
                                                                <span>Nach Ablauf von {invoiceState.pricing.expiryDateMonths} Monaten:</span>
                                                                <span className="f-w-5"> {invoiceState.pricing.afterExpiration.title}</span>
                                                            </p>
                                                        </>
                                                    }
                                                </>
                                                : <p tid='881'><Trans id={881}/></p>
                                            }
                                        </>}
                                        tid='882'
                                        title={getTrans(transState, 882)}
                                        width={6}
                                    />
                                </div>
                            </div>
                        }
                    </>
                )
            }
            tid='649'
            title={<Trans id={649}/>}
        />
    )
}

export default withGlobalState(PageInvoice)