import React, {useEffect, useState} from 'react'
import Page from "./Page";
import {withGlobalState} from "react-globally";
import Trans from "../components/elements/trans/Trans";
import ContentTabs from "../components/widgets/structuring/ContentTabs";
import {useDispatch, useSelector} from "react-redux";
import {apiGet, apiPatch} from "../services/ServiceAPI";
import {setOrders} from "../store/reducer/ordersSlice";
import {getDateString} from "../services/ServiceDate";
import {getDoubleString} from "../services/ServiceData";
import { useTable, usePagination, useSortBy, useExpanded } from 'react-table'
import FormLoad from "../components/elements/form/FormLoad";
import ComplexTableBottom from "../components/elements/table/ComplexTableBottom";
import OrderDetailLB from "../complex/lightboxes/orders/OrderDetailLB";

const PageOrders = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** lightbox **************/
    const [oDLB, setODLB] = useState(false)

    /************** loading **************/
    const [lO, setLO] = useState(false)
    const [loadingOrderList, setLoadingOrderList] = useState(false)

    /************** global **************/
    const [actO, setActO] = useState(null)
    const ordersState = useSelector((state) => state.orders)
    const dispatch = useDispatch()

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (ordersState.orders.length === 0) getOrders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** lightbox **************/
    const openOrderLB = (row) => {
        setODLB(true)
        setActO(row)
    }

    const cL = () => {
        setODLB(false)
    }

    const setActOrderStatus = (id, s = true) => {
        setLO(true)
        apiPatch('orders/' + id + '/processed', p, {
            isProcessed: s
        })
            .finally(() => {
                setLO(false)
                getOrders()
            })
    }

    /************** api calls **************/
    const getOrders = () => {
        setLoadingOrderList(true)
        apiGet('orders', p)
            .then((r) => {
                dispatch(setOrders(r.data))
            })
            .finally(() => {
                setLoadingOrderList(false)
            })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const columns = React.useMemo(
        () => [
            {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }) => (
                    <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <span className={'ico-up'}/> : <span className={'ico-down'}/>}</span>
                )
            },
            {
                Header: <Trans id={509}/>,
                accessor: 'createdAt',
            },
            {
                Header: <Trans id={510}/>,
                accessor: 'bruttoAmount',
            },
            {
                Header: <Trans id={511}/>,
                accessor: 'tax',
            },
            {
                Header: <Trans id={512}/>,
                accessor: 'status',
            },
            {
                Header: <Trans id={513}/>,
                accessor: 'isProcessedByRetailerContent',
            }
        ],
        []
    )

    const data = React.useMemo(
        () => ordersState.orders.map((r) => {
            return {
                addressJson: r.addressJson,
                createdAt: getDateString(new Date(r.createdAt)),
                bruttoAmount: getDoubleString(r.bruttoAmount) + ' ' + r.currency,
                id: r.id,
                orderItems: r.orderItems,
                tax: r.tax,
                status: r.status,
                isProcessedByRetailer: r.isProcessedByRetailer,
                isProcessedByRetailerContent: (r.isProcessedByRetailer)
                    ? <span className={'c-status-ok'} tid='516'><Trans id={516}/></span>
                    : <span className={'c-status-er'} tid='517'><Trans id={517}/></span>
            }
        }),
        [ordersState.orders]
    )

    const renderRowSubComponent = React.useCallback(()  => {return ('')}, [])

    const tableInstance = useTable(
        {
            columns,
            data,
            renderRowSubComponent
        },
        useSortBy,
        useExpanded,
        usePagination
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        visibleColumns
    } = tableInstance

    return (
        <Page
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <div className='c-tab__outer is--fullwidth'>
                        <ContentTabs
                            body={<>
                                {loadingOrderList
                                    ? <FormLoad a={true}/>
                                    : <div>
                                        <table className={'e-table'} {...getTableProps()}>
                                            <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            <span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                            {page.map((row) => {
                                                prepareRow(row)
                                                return (
                                                    <React.Fragment {...row.getRowProps()}>
                                                        <tr {...row.getRowProps()} {...row.getToggleRowExpandedProps()} onClick={() => openOrderLB({ row })}>
                                                            {
                                                                row.cells.map(cell => <td {...cell.getCellProps()}>
                                                                    {cell.render('Cell')}
                                                                </td>)
                                                            }
                                                        </tr>
                                                        {row.isExpanded &&
                                                        <tr>
                                                            <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                                                        </tr>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                        <ComplexTableBottom
                                            canNextPage={canNextPage}
                                            canPreviousPage={canPreviousPage}
                                            gotoPage={gotoPage}
                                            nextPage={nextPage}
                                            pageCount={pageCount}
                                            pageIndex={pageIndex}
                                            pageOptions={pageOptions}
                                            pageSize={pageSize}
                                            previousPage={previousPage}
                                            setPageSize={setPageSize}
                                        />
                                    </div>
                                }
                            </>}
                            head={<>
                                <div className='c-tab__head-top'>
                                    <div>
                                        <p className='c-tab__head-title' tid='521'><Trans id={521}/></p>
                                    </div>
                                </div>
                                <div className='c-tab__head-bottom'>
                                </div>
                            </>}
                            open={true}
                            width={100}
                        />
                        <OrderDetailLB
                            actO={actO}
                            cL={cL}
                            lO={lO}
                            oDLB={oDLB}
                            setActOrderStatus={setActOrderStatus}
                        />
                    </div>
                )
            }
            tid='229'
            title={<Trans id={229}/>}
        />
    )
}

export default withGlobalState(PageOrders)