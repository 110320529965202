import React from "react";
import {withGlobalState} from "react-globally";
import {showSearchResult} from "../../../services/ServiceData";

const ListItemCourseSpeaker = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const speaker = p.speaker
    const q = p.search

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** button events **************/
    const handleListItemClick = (id) => {
        p.onclick(id)
    }

    return (
        <li
            className={`is--noborder ${p.selected}`}
            onClick={e => handleListItemClick(speaker.id, e)}
        >
            <div className='c-itemlist__content'>
                {speaker.title &&
                    <div>
                        <p>{showSearchResult(q, speaker.title, 'f-w-7 c-pri-2-dark')}</p>
                    </div>
                }
                <div>
                    <p>
                        <span>{showSearchResult(q, speaker.lastName, 'f-w-7 c-pri-2-dark')}, {showSearchResult(q, speaker.firstName, 'f-w-7 c-pri-2-dark')} </span>
                    </p>
                </div>
            </div>
        </li>
    )
}

export default withGlobalState(ListItemCourseSpeaker)
