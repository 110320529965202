import React from 'react';
import {withGlobalState} from "react-globally";
import Infobox from "../../../components/elements/message/Infobox";
import LB from "../../../components/widgets/message/LB";
import {useSelector} from "react-redux";
import Trans from "../../../components/elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";

const ArchiveCourseLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.handleLBArchiveCourseBtn}
                            tid='865'
                        ><Trans id={865}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={p.closeLightbox}
                            tid='864'
                        ><Trans id={864}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={<div>
                <Infobox
                    addClasses={'is--bg-pri6 is--no-border is--icon-left c-w'}
                    content={<Trans id={866}/>}
                    icon={'attention'}
                    tid='866, 867'
                    title={getTrans(transState, 867)}
                />
            </div>}
            onClose={p.closeLightbox}
            open={p.courseArchiveLB}
            tid='868'
            title={getTrans(transState, 868)}
        />
    )
}

export default withGlobalState(ArchiveCourseLB)
