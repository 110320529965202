import React, {useEffect, useState} from "react";
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import ComplexCoursePlaceForm from "../../forms/coursePlace/ComplexCoursePlaceForm";
import {CoursePlacePost} from "../../../obj/CoursePlace/CoursePlacePost.obj";
import {getInputValue} from "../../../components/functions/FuncForm";
import {hasKeys} from "../../../components/functions/FuncObjects";
import FormLoad from "../../../components/elements/form/FormLoad";
import Trans from "../../../components/elements/trans/Trans";

const AddCoursePlaceLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form **************/
    const [validated, setValidated] = useState({})
    const [addCoursePlaceSubmit, setAddCoursePlaceSubmit] = useState(false)
    const [validationShow, setValidationShow] = useState(false)

    /************** objects **************/
    const [newCoursePlace, setNewCoursePlace] = useState(CoursePlacePost)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    useEffect(() => {
        setAddCoursePlaceSubmit(!hasKeys(validated))
    }, [validated])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** input events **************/
    /* change */
    const handleInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        setNewCoursePlace({
            ...newCoursePlace,
            [input.name]: input.value
        })
    }

    const handleLBAddCoursePlaceBtn = () => {
        setValidationShow(true)

        if (addCoursePlaceSubmit) {
            p.handleLBAddCoursePlaceBtn(newCoursePlace)
            setValidationShow(false)
        }
    }

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn'
                            disabled={!addCoursePlaceSubmit}
                            onClick={handleLBAddCoursePlaceBtn}
                            tid='798'
                        ><Trans id={798}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeLightbox}
                            tid='799'
                        ><Trans id={799}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <div>
                    <ComplexCoursePlaceForm
                        coursePlace={newCoursePlace}
                        handleInputChange={handleInputChange}
                        validated={validated}
                        setValidated={setValidated}
                        validationShow={validationShow}
                    />
                    <FormLoad a={p.addCoursePlaceLoading}/>
                </div>
            }
            onClose={p.closeLightbox}
            open={p.addCoursePlaceLB}
            tid='800'
            title={<Trans id={800}/>}
        />
    )
}

export default withGlobalState(AddCoursePlaceLB)
