import React from 'react'
import {NavLink} from "react-router-dom";
import {withGlobalState} from "react-globally";
import Trans from "../../elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

function onPaths(p) {
    return (m, l) => {
        return p.includes(l.pathname);
    };
}

const HeaderMainNav = () => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)
    const generalState = useSelector((s) => s.general)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const actCl = 'pos-relative complex-menu-ul c-pr-3 hover-c-pr-3'
    const cl = 'before-p-r-6 hover-c-pr-2 fl fl-a-items-center h-100 f-w-4 p-lr-16 transition-de-color'

    return (
        <div className='fl fl-a-items-center h-100'>
            <nav className="h-100">
                <ul className="fl h-100">
                    <li className='h-100'>
                        <NavLink
                            activeClassName={actCl}
                            className={cl}
                            exact
                            isActive={onPaths(['/', "/login"])}
                            tid='225'
                            title={getTrans(transState, 225)}
                            to='/'
                        ><Trans id={225}/></NavLink>
                    </li>
                    {generalState.onlyNotActivatedStatus &&
                        <>
                            <li className='h-100'>
                                <NavLink
                                    activeClassName={actCl}
                                    className={cl}
                                    tid='226'
                                    title={getTrans(transState, 226)}
                                    to="/angebote"
                                ><Trans id={226}/></NavLink>
                            </li>
                            <li className='h-100'>
                                <NavLink
                                    activeClassName={actCl}
                                    className={cl}
                                    tid='227'
                                    title={getTrans(transState, 227)}
                                    to="/bestellungen"
                                ><Trans id={227}/></NavLink>
                            </li>
                            <li className='h-100'>
                                <NavLink
                                    activeClassName={actCl}
                                    className={cl}
                                    tid='647, 648'
                                    title={getTrans(transState, 648)}
                                    to="/anfragen"
                                ><Trans id={647}/></NavLink>
                            </li>
                        </>
                    }
                    <li className='h-100'>
                        <NavLink
                            activeClassName={actCl}
                            className={cl}
                            tid='228'
                            title={getTrans(transState, 228)}
                            to="/profil"
                        ><Trans id={228}/></NavLink>
                    </li>
                    <li className='h-100'>
                        <NavLink
                            activeClassName={actCl}
                            className={cl}
                            tid='371, 646'
                            title={getTrans(transState, 371)}
                            to="/stammdaten"
                        ><Trans id={646}/></NavLink>
                    </li>
                    <li className='h-100'>
                        <NavLink
                            activeClassName={actCl}
                            className={cl}
                            tid='644, 645'
                            title={getTrans(transState, 644)}
                            to="/abonnement"
                        ><Trans id={645}/></NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default withGlobalState(HeaderMainNav)
