import React from 'react';
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import Infobox from "../../../components/elements/message/Infobox";
import FormLoad from "../../../components/elements/form/FormLoad";
import {useSelector} from "react-redux";
import {getTrans} from "../../../services/ServiceTrans";
import Trans from "../../../components/elements/trans/Trans";

const DeleteCourseSpeakerLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleCourseSpeakerDelete = () => {
        p.handleCourseSpeakerDelete(p.speaker.id)
    }

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={handleCourseSpeakerDelete}
                            tid={'619'}
                        ><Trans id={619}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={p.closeLightbox}
                            tid={'620'}
                        ><Trans id={620}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <>
                    <div>
                        <Infobox
                            addClasses={'is--bg-pri6 is--no-border is--icon-left c-w'}
                            content={
                                <div tid={'622'}>
                                    <Trans id={622}/>
                                </div>
                            }
                            icon={'attention'}
                            tid={'621'}
                            title={getTrans(transState, 621)}
                        />
                    </div>
                    <FormLoad a={p.loadingDeleteSpeaker}/>
                </>
            }
            onClose={p.closeLightbox}
            open={p.deleteSpeakerLB}
            tid={'618'}
            title={getTrans(transState, 618)}
        />
    )
}

export default withGlobalState(DeleteCourseSpeakerLB)
