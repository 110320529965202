import React, {useState, useEffect, useCallback, useRef} from 'react';
import {withGlobalState} from "react-globally";
import FormWrap from "./FormWrap";
import {useSelector} from "react-redux";
import {onEventActions, setValiOfInput, validation} from "../../functions/FuncValidation";
import {getTrans} from "../../../services/ServiceTrans";
import CKEditor from "ckeditor4-react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import FormLabel from "./FormLabel";
import FormValidationBar from "./FormValidationBar";
import {
    buildDateStringNoTimeEN, buildDateTimeString,
    buildTimeString,
    mergeDateStringAndDate,
    mergeTimeStringAndDate
} from "../../../services/ServiceDate";
import {getInputValue} from "../../functions/FuncForm";

/**
 * FormInput component
 *
 * This module returns any type of input fields and manages all states
 *
 * @param {Object} p                             Properties.
 * @param {string} [p.addClassesWrap]            add classes to wrap div
 * @param {string} [p.examples]                  exaple text
 * @param {string} [p.htmlType=text]             HTML type of input (text, password, mail etc.)
 * @param {string} [p.label]                     Label text
 * @param {string} [p.name]                      Input name
 * @param {function} [p.onBlur]                  onBlur function
 * @param {function} [p.onChange]                onChange function
 * @param {function} [p.onEditorChange]          onEditorChange function
 * @param {array} [p.options]                    select input options
 * @param {string} [p.placeholder]               placeholder text
 * @param {boolean} [p.required=false]           required
 * @param {boolean} [p.selected]                 select item of select input type
 * @param {number} [p.step]                      number input step
 * @param {string} [p.tid]                       ids of used translations
 * @param {string} [p.validationChecks]          list of validations
 * @param {string} [p.validationShow]            show validations
 * @param {string} [p.validationAct]             current validations
 * @param {function} [p.validationSet]           set validations function
 * @param {boolean} [p.validationOnInit=false]   activate validation on init
 * @param {boolean} [p.validationOnChange=false] activate validation on change
 * @param {boolean} [p.valOnlyChange=false]      activate validation only on change
 * @param {*} [p.value]                          input value
 */
const FormInput = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** loading **************/
    const [loaded, setLoaded] = useState(false)

    /************** form **************/
    // eslint-disable-next-line
    const [focused, setFocused] = useState(false)
    // eslint-disable-next-line
    const [notFirst, setNotFirst] = useState(true)
    const [showValidations, setShowValidations] = useState(false)
    const [validationShow, setValidationShow] = useState(false)
    const [validationOnChange, setValidationOnChange] = useState(false)
    const [validationOnInit, setValidationOnInit] = useState(false)
    const [changed, setChanged] = useState(false)

    /************** validation **************/
    const [errorMessage, setErrorMessage] = useState('')
    // eslint-disable-next-line
    const [errorLine, setErrorLine] = useState('')
    // eslint-disable-next-line
    const [firstVali, setFirstVali] = useState(true)

    /************** states **************/
    const inputRef = useRef(null);
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** general **************/
    const doValidation = (e, param = {}) => {
        setFirstVali(false)
        const input = getInputValue(e, param)
        // validation on events falls validation defined
        if (p.validationChecks && p.validationChecks[0] !== undefined) {
            setValidation(validation(p.validationChecks, input.value, p))
        }
    }

    const setValidation = useCallback((status) => {
        setValiOfInput(status, p.validationAct, p.validationSet, p.name, setErrorMessage, setErrorLine)
        //setValidationOfInput(p, status, focused, firstVali, notFirst, onlyline, setErrror, setErrorLine)
    }, [p])

    /************** input events **************/
    const handleEditorChange = (e, param = {}) => {
        p.onEditorChange(e, param)
        handleEvent(e, param)
    }

    const handleOnInput = (e, param = {}) => {
        setChanged(true)
        if (p.onChange) p.onChange(e)
        handleEvent(e, param)
    }

    const handleOnBlur = (e, param = {}) => {
        if (p.onBlur) p.onBlur(e)
        handleEvent(e, param)
        setShowValidations(false)
    }

    const handleOnChange = (e, param = {}) => {
        if (p.onChange) p.onChange(e)
        handleEvent(e, param)
    }

    const handleOnFocusIn = (e, param = {}) => {
        setFocused(true)
        handleEvent(e, param)
        setShowValidations(true)
    }

    const handleEvent = (e, param = {}) => {
        doValidation(e, param)
        if (p.onEvent) p.onEvent(e)
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        onEventActions(p, setNotFirst, loaded, setLoaded, setValidation)
    }, [p, loaded, p.valOnlyChange, p.validation, p.value, setValidation]);

    useEffect(() => {
        setValidationShow(p.validationShow)
    }, [p.validationShow]);

    useEffect(() => {
        setValidationOnInit(p.validationOnInit)
    }, [p.validationOnInit]);

    useEffect(() => {
        setValidationOnChange(p.validationOnChange)
    }, [p.validationOnChange]);

    useEffect(() => {
        if (p.type === 'select') {
            doValidation(false, {
                name: p.name,
                value: p.selected
            })
        }else {
            if (inputRef.current !== null) {
                doValidation(false, {
                    name: p.name,
                    value: inputRef.current.value
                })
            }
        }
        // eslint-disable-next-line
    }, []);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- TEMPLATE VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    let placeholder = ''
    if (typeof p.placeholder !== undefined) placeholder = p.placeholder
    if (p.placeholder && typeof p.placeholder === 'object' && 'props' in p.placeholder && 'id' in p.placeholder.props) {
        placeholder = getTrans(transState, p.placeholder.props.id)
    }

    let input = ''
    let inputClasses = `input p-lr-16 p-tb-10 rad-6 c-bg-w shad-sm w-100 focus-outline focus-outline-c-pr-2-dark focus-outline-w-2 focus-outline-offset-1 ${errorLine} ${p.addClasses} ${(p.visible)&& 'is--visible'}`
    if (p.big) inputClasses = 'e-form__input is--big'
    if (p.little) inputClasses = 'e-form__input'

    switch(p.type) {
        case 'checkbox': {
            input = <>
                <label className="e-checkbox" data-kind="normal" htmlFor={p.name}>
                    <input
                        checked={p.value}
                        id={p.name}
                        name={p.name}
                        onBlur={(e) => handleOnBlur(e)}
                        onChange={(e) => handleOnChange(e)}
                        onFocus={(e) => handleOnFocusIn(e)}
                        onInput={(e) => handleOnInput(e)}
                        onKeyUp={(e) => handleOnInput(e)}
                        ref={inputRef}
                        type="checkbox"
                    />
                    <span>{p.label}</span>
                    <span className="e-checkbox__text"/>
                </label>
            </>
            break;
        }
        case 'date': {
            input = <input
                className={inputClasses}
                name={p.name}
                onBlur={(e) => handleOnBlur(e)}
                onChange={(e) => handleOnChange(e)}
                onFocus={(e) => handleOnFocusIn(e)}
                onInput={(e) => handleOnInput(e)}
                placeholder={placeholder}
                ref={inputRef}
                type={'date'}
                data-validation={JSON.stringify(p.validation)}
                value={p.value}
            />
            break;
        }
        case 'datetime': {
            const dateValue = (p.value !== null && p.value !== '')? new Date(p.value) : null

            const getDateTimeEvent = (dateValue) => {
                return {
                    target: {
                        name: p.name,
                        value: buildDateTimeString(dateValue),
                        getAttribute: function(){return 'datetime'}
                    }
                }
            }

            const handleTimeEvent = (e) => handleOnChange(getDateTimeEvent(mergeTimeStringAndDate(dateValue, e.target.value)))
            const handleDateEvent = (e) => handleOnChange(getDateTimeEvent(mergeDateStringAndDate(dateValue, e.target.value)))
            const handleDateOnBlur = (e) => handleOnBlur(getDateTimeEvent(mergeDateStringAndDate(dateValue, e.target.value)))
            const handleDateOnFocusIn = (e) => handleOnFocusIn(getDateTimeEvent(mergeDateStringAndDate(dateValue, e.target.value)))
            const handleTimeOnBlur = (e) => handleOnBlur(getDateTimeEvent(mergeTimeStringAndDate(dateValue, e.target.value)))
            const handleTimeOnFocusIn = (e) => handleOnFocusIn(getDateTimeEvent(mergeTimeStringAndDate(dateValue, e.target.value)))

            input = <table>
                <tr>
                    <td>
                        <input
                            className={inputClasses}
                            name={p.name}
                            onBlur={(e) => handleDateOnBlur(e)}
                            onChange={(e) => handleDateEvent(e)}
                            onFocus={(e) => handleDateOnFocusIn(e)}
                            placeholder={placeholder}
                            type={'date'}
                            data-validation={JSON.stringify(p.validation)}
                            value={buildDateStringNoTimeEN(dateValue)}
                        />
                    </td>
                    <td>
                        <input
                            className={inputClasses}
                            name={p.name}
                            onBlur={(e) => handleTimeOnBlur(e)}
                            onChange={(e) => handleTimeEvent(e)}
                            onFocus={(e) => handleTimeOnFocusIn(e)}
                            placeholder={placeholder}
                            type={'time'}
                            data-validation={JSON.stringify(p.validation)}
                            value={buildTimeString(dateValue)}
                        />
                    </td>
                </tr>
            </table>
            break;
        }
        case 'multiselect': {
            input = <Select
                closeMenuOnSelect={false}
                isMulti
                name={p.name}
                onBlur={(e) => handleOnBlur(e)}
                onChange={(e) => p.onChange(e, {
                    name: p.name,
                    value: e.value
                })}
                options={p.options}
                placeholder={placeholder}
                value={p.value}
            />
            break;
        }
        case 'multiselect-by-objects': {
            const options = p.options.map(option => {
                return {
                    label: option.label,
                    value: option.value.id
                }
            })

            const values = p.value.map(val => {
                return {
                    label: val.label,
                    value: val.value.id
                }
            })

            const handleOnMultiSelectByIdChange = (e) => {
                p.onChange(e, {
                    name: p.name,
                    value: e.map(item => {
                        let result = {}
                        p.options.forEach(option => {
                            if (item.value === option.value.id) result = option.value
                        })
                        return result
                    })
                })
            }

            input = <Select
                closeMenuOnSelect={false}
                isMulti
                name={p.name}
                onBlur={(e) => handleOnBlur(e)}
                onChange={(e) => handleOnMultiSelectByIdChange(e)}
                options={options}
                placeholder={placeholder}
                value={values}
            />
            break;
        }
        case 'multiselect-create': {
            input = <CreatableSelect
                closeMenuOnSelect={false}
                isMulti
                name={p.name}
                onChange={(selectedOption) => p.onChange(selectedOption)}
                options={p.options}
                placeholder={placeholder}
                value={p.value}
            />
            break;
        }
        case 'number': {
            input = <input
                className={inputClasses}
                max={p.max}
                min={(p.min) ? p.min : '0'}
                name={p.name}
                onBlur={(e) => handleOnBlur(e)}
                onChange={(e) => handleOnChange(e)}
                onFocus={(e) => handleOnFocusIn(e)}
                onInput={(e) => handleOnInput(e)}
                placeholder={placeholder}
                ref={inputRef}
                step={(p.step) ? p.step : '1'}
                type='number'
                data-validation={JSON.stringify(p.validation)}
                value={p.value}
            />
            break;
        }
        case 'richarea': {
            input = <CKEditor
                config={{
                    format_tags: 'p;h2;h3;h4;h5;h6',
                    height: '30rem',
                    removePlugins: 'easyimage,cloudservices,exportPdf,exportpdf',
                    scayt_autoStartup: false,
                    scayt_sLang: 'de_DE',
                    stylesSet: [
                        { name: 'Fett', element: 'big' },
                        { name: 'Schmal', element: 'small' },
                        { name: 'Durchgestrichen', element: 'del' },
                        { name: 'Zitat inline', element: 'q' },
                    ],
                    toolbar: [
                        { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                        { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
                        '/',
                        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat' ] },
                        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                        { name: 'links', items: [  ] },
                        { name: 'insert', items: [ 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar' ] },
                        { name: 'styles', items: [ 'Styles', 'Format' ] }
                    ],
                    uiColor: '#FFFFFF'
                }}
                data={p.value}
                onBlur={(e) => handleOnBlur(e, p.name)}
                onChange={(e) => handleEditorChange(e, p.name)}
            />
            break;
        }
        case 'select': {
            const options = p.options;

            let actOption = null
            if (p.selected !== null) {
                actOption = options[0]
                options.forEach(function(option){
                    if (option.value === p.selected) actOption = option;
                });
            }

            const handleOnSelectChange = (e) => {
                if (e !== null) {
                    let val = e.value
                    if (p.dataType) {
                        // eslint-disable-next-line default-case
                        switch (p.dataType) {
                            case 'int': {
                                val = parseInt(val)
                            }
                        }
                    }
                    handleEvent(null, {
                        name: p.name,
                        value: val
                    })
                    p.onChange(e, {name: p.name, value: val})
                }
            }

            input = <Select
                defaultValue={actOption}
                name={p.name}
                onBlur={p.onBlur}
                onChange={handleOnSelectChange}
                options={options}
                placeholder={placeholder}
                ref={inputRef}
                validation={JSON.stringify(p.validation)}
                value={actOption}
            />
            break;
        }
        case 'textarea': {
            input = <textarea
                className={inputClasses + ' is--area'}
                id={p.name}
                name={p.name}
                onBlur={(e) => handleOnBlur(e)}
                onChange={(e) => handleOnChange(e)}
                onFocus={(e) => handleOnFocusIn(e)}
                onInput={(e) => handleOnInput(e)}
                placeholder={placeholder}
                ref={inputRef}
                data-validation={JSON.stringify(p.validation)}
                value={(p.value === null)? '' : p.value}
            />
            break;
        }
        case 'switch': {
            input = <>
                <input
                    checked={p.value}
                    className="e-form__switch-checkbox"
                    id={p.name}
                    name={p.name}
                    onBlur={(e) => handleOnBlur(e)}
                    onChange={(e) => handleOnChange(e)}
                    onFocus={(e) => handleOnFocusIn(e)}
                    onInput={(e) => handleOnInput(e)}
                    ref={inputRef}
                    type="checkbox"
                />
                <label className="e-form__switch-label" htmlFor={p.name}>
                    <span className={`e-form__switch-button`} />
                </label>
            </>
            break;
        }
        default: {
            input = <input
                className={inputClasses}
                id={p.name}
                name={p.name}
                onBlur={(e) => handleOnBlur(e)}
                onChange={(e) => handleOnChange(e)}
                onFocus={(e) => handleOnFocusIn(e)}
                onInput={(e) => handleOnInput(e)}
                placeholder={placeholder}
                ref={inputRef}
                type={(p.htmlType) ? p.htmlType : 'text'}
                data-validation={JSON.stringify(p.validation)}
                value={(p.value === null)? '' : p.value}
            />
        }
    }

    return (
        <FormWrap
            addClasses={p.addClassesWrap}
            childs={
                <>
                    {p.label &&
                        <div className={'e-form__label-bar'}>
                            {p.type !== 'checkbox' &&
                                <FormLabel
                                    error={<div className={`e-form__error-line ${errorLine} ${(p.big) ? 'is--big' : ''}`}/>}
                                    label={p.label}
                                    name={p.name}
                                    required={p.required}
                                />
                            }
                            <FormValidationBar
                                label={p.label}
                                showInfo={p.showInfo}
                                showValidations={showValidations}
                                text={p.text}
                                val={(p.value === null)? '' : p.value}
                                validation={p.validation}
                            />
                        </div>
                    }
                    {p.examples &&
                        <div className={'e-form__example'}>{p.examples}</div>
                    }
                    {input}
                    {(validationShow || validationOnInit || (validationOnChange && changed)) && errorMessage}
                </>
            }
        />
    )
}

export default withGlobalState(FormInput)
