import { createSlice } from '@reduxjs/toolkit';
import {PricingObj} from "../../obj/Invoice/Pricing.obj";

export const slice = createSlice({
    name: 'invoice',
    initialState: {
        pricing: PricingObj
    },
    reducers: {
        setPricing: (s, p) => {
            s.pricing = p.payload
        }
    }
});

export const {
    setPricing
} = slice.actions;

export default slice.reducer;
