import React from "react";
import {useSelector} from "react-redux";
import {getTrans} from "../../../services/ServiceTrans";

const ComplexTableBottom = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const transState = useSelector((state) => state.trans)

    return (
        <div className='fl fl-a-items-center fl-jc-center p-t-6'>
            <div className='p-r-6'>
                <select
                    onChange={e => p.setPageSize(Number(e.target.value))}
                    value={p.pageSize}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option
                            key={pageSize}
                            value={pageSize}
                        >
                            Zeige {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            <div className='fl fl-a-items-center'>
                <div>Seite <strong>{p.pageIndex + 1} von {p.pageOptions.length}</strong></div>
                <div className='p-lr-6'>| Gehe zur Seite:{' '}
                    <input
                        defaultValue={p.pageIndex + 1}
                        onChange={e => {
                            p.gotoPage(e.target.value ? Number(e.target.value) - 1 : 0)
                        }}
                        style={{ width: '100px' }}
                        type="number"
                    />
                </div>
            </div>
            <ul className="e-actions__menu">
                <li>
                    <button
                        className='e-btn__action'
                        disabled={!p.canPreviousPage}
                        onClick={() => p.gotoPage(0)}
                        tid={'594'}
                        title={getTrans(transState, 594)}
                    >{'<<'}</button>
                </li>
                <li>
                    <button
                        className='e-btn__action'
                        disabled={!p.canPreviousPage}
                        onClick={() => p.previousPage()}
                        tid={'595'}
                        title={getTrans(transState, 595)}
                    >{'<'}
                    </button>
                </li>
                <li>
                    <button
                        className='e-btn__action'
                        disabled={!p.canNextPage}
                        onClick={() => p.nextPage()}
                        tid={'596'}
                        title={getTrans(transState, 596)}
                    >{'>'}
                    </button>
                </li>
                <li>
                    <button
                        className='e-btn__action'
                        disabled={!p.canNextPage}
                        onClick={() => p.gotoPage(p.pageCount - 1)}
                        tid={'597'}
                        title={getTrans(transState, 597)}
                    >{'>>'}
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default ComplexTableBottom