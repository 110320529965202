import React, {useEffect, useState} from 'react';
import Page from "../Page";
import {withGlobalState} from "react-globally";
import {useDispatch, useSelector} from "react-redux";
import {
    setActCourse,
    setActProduct,
    setCourses,
    setProducts,
    setProductUnits
} from "../../store/reducer/courseSlice";
import {apiDel, apiGet, apiPatch, apiPost} from "../../services/ServiceAPI";
import ComplexCourseList from "../../complex/courses/ComplexCourseList";
import ComplexCourseDetail from "../../complex/courses/ComplexCourseDetail";
import ComplexCourseProducts from "../../complex/courseProduct/ComplexCourseProducts";
import ComplexCourseProduct from "../../complex/courseProduct/ComplexCourseProduct";
import {CourseProductObj} from "../../obj/Product/CourseProduct.obj";
import ArchivedCourseLB from "../../complex/lightboxes/courses/ArchivedCourseLB";
import {CourseObj} from "../../obj/Course/Course.obj";
import Trans from "../../components/elements/trans/Trans";
import {setArchivedProducts} from "../../store/reducer/archivedSlice";
import CourseProductDeleteLB from "../../complex/lightboxes/courseProduct/CourseProductDeleteLB";

const PageCourses = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const dispatch = useDispatch()

    /************** loading **************/
    const [loadingCourseList, setLoadingCourseList] = useState(false)
    const [loadingCourse, setLoadingCourse] = useState(false)
    const [loadingCourseProductList, setLoadingCourseProductList] = useState(false)
    const [loadingCourseProduct, setLoadingCourseProduct] = useState(false)
    const [loadingProductDelete, setLoadingProductDelete] = useState(false)
    const [loadingUnit, setLoadingUnit] = useState(false)
    const [loadingArchivedProducts, setLoadingArchivedProducts] = useState(false)

    /************** states **************/
    const [openCourseList, setOpenCourseList] = useState(true)
    const [openCourseDetail, setOpenCourseDetail] = useState(false)
    const [openCourseProducts, setOpenCourseProducts] = useState(false)
    const [openCourseProductDetail, setOpenCourseProductDetail] = useState(false)
    const [productArchiveId, setProductArchiveId] = useState(null)

    /************** lightbox **************/
    const [productDeleteLB, setProductDeleteLB] = useState(false)
    const [openAddCourseLB, setOpenAddCourseLB] = useState(false)

    /************** objects **************/
    const [tabsWidths] = useState({
        courseList: 25,
        courseDetail: 50,
        productList: 25,
        productDetail: 70
    })

    /************** lightbox **************/
    const [courseArchivedLB, setCourseArchivedLB] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (coursesState.courses.length === 0) getCourseList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (coursesState.courses.length === 0) getCourseList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return function cleanup() {
            dispatch(setActCourse(CourseObj))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** general **************/
    const doCloseAll = () => {
        setOpenCourseList(true)
        setOpenCourseDetail(false)
        setOpenCourseProducts(false)
        setOpenCourseProductDetail(false)
    }

    const doOpenCourseProductDetail = () => {
        setOpenCourseList(false)
        setOpenCourseDetail(false)
        setOpenCourseProducts(true)
        setOpenCourseProductDetail(true)
    }

    const doOpenCourseDetail = () => {
        setOpenCourseList(true)
        setOpenCourseDetail(true)
        setOpenCourseProducts(true)
        setOpenCourseProductDetail(false)
        dispatch(setActProduct(CourseProductObj))
    }

    /************** api calls **************/
    const onCourseItemClick = (id) => {
        doOpenCourseDetail()
        setLoadingCourseProductList(true)
        setLoadingCourse(true)
        apiGet('courses/' + id, p)
            .then(r => {
                dispatch(setActCourse(r.data))
                getCourseProductList(id)
            })
            .finally(() => {
                setLoadingCourse(false)
            })
    }

    const onCourseProductItemClick = (cid, pid) => {
        setLoadingCourseProduct(true)
        doOpenCourseProductDetail()
        apiGet('courses/' + cid + '/products/' + pid, p)
            .then(r => {
                dispatch(setActProduct(r.data))
            })
            .catch(() => {
                doOpenCourseDetail()
            })
            .finally(() => {
                setLoadingCourseProduct(false)
            })
        getUnitsList(cid, pid)
    }

    const getUnitsList = (cid, pid) => {
        setLoadingUnit(true)
        apiGet('courses/' + cid + '/products/' + pid + '/units', p)
            .then(r => {
                dispatch(setProductUnits(r.data))
            })
            .finally(() => {
                setLoadingUnit(false)
            })
    }

    const getCourseProductList = (id) => {
        if (typeof id !== 'undefined') {
            setLoadingCourseProductList(true)
            apiGet('courses/' + id + '/products', p)
                .then(r => {
                    dispatch(setProducts(r.data))
                    setLoadingCourseProductList(false)
                })
                .catch(() => {
                    setLoadingCourseList(false)
                })
        }
    }

    const getCourseList = () => {
        setLoadingCourseList(true)
        apiGet('courses', p)
            .then(r => {
                dispatch(setCourses(r.data))
            })
            .finally(() => {
                setLoadingCourseList(false)
            })
    }

    const archiveCourse = (cid) => {
        apiPatch('courses/' + cid, p, {
            archived: true
        })
            .then(() => {
                getCourseList()
            })
            .finally(() => {
                doCloseAll()
            })
    }

    const inactiveCourse = (cid, status = false) => {
        apiPatch('courses/' + cid, p, {
            readyToPublished: status
        })
            .then(() => {
                getCourseList()
            })
            .finally(() => {
                doCloseAll()
            })
    }

    const archiveProduct = (cid, pid) => {
        apiPatch('courses/' + cid + '/products/' + pid, p, {
            archived: true
        })
            .then(() => {
                getCourseProductList(cid)
            })
            .finally(() => {
                doOpenCourseDetail()
            })
    }

    const rearchiveCourse = (cid) => {
        apiPatch('courses/' + cid, p, {
            archived: false,
            readyToPublished: false
        })
            .then(() => {
                getCourseList()
            })
            .finally(() => {
                doCloseAll()
                closeLightbox()
            })
    }

    const rearchiveCourseProduct = (cid, pid) => {
        apiPatch('courses/' + cid + '/products/' + pid, p, {
            archived: false,
            readyToPublished: false
        })
            .then(() => {
                getCourseList()
                getArchivedProducts()
            })
            .finally(() => {
                doCloseAll()
                closeLightbox()
            })
    }

    const deleteProduct = (cid, pid) => {
        setLoadingProductDelete(true)
        apiDel('courses/' + cid + '/products/' + pid, p)
            .then(() => {
                getCourseProductList(cid)
            })
            .finally(() => {
                setLoadingProductDelete(false)
                doOpenCourseDetail()
            })
    }

    const duplicateProduct = (cid, pid, withModules) => {
        const withModulesParam = withModules? '?copyUnits=true' : ''
        apiPost('courses/' + cid + '/products/' + pid + withModulesParam, p, {})
            .then(() => {
                getCourseProductList(cid)
                onCourseProductItemClick(cid, pid)
            })
    }

    const inactiveCourseProduct = (cid, pid, status) => {
        apiPatch('courses/' + cid + '/products/' + pid, p, {
            readyToPublished: status
        })
            .then(() => {
                getCourseProductList(cid)
            })
            .finally(() => {
                doOpenCourseDetail()
            })
    }

    const getArchivedProducts = () => {
        setLoadingArchivedProducts(true)

        apiGet('courses/products/archived', p)
            .then((r) => {
                dispatch(setArchivedProducts(r.data))
            })
            .catch(() => {
                dispatch(setArchivedProducts([]))
            })
            .finally(() => {
                setLoadingArchivedProducts(false)
            })
    }

    /************** button events **************/
    const addCourseBtnClick = () => {
        setOpenAddCourseLB(true)
    }

    const archivedCourseBtnClick = () => {
        setCourseArchivedLB(true)
    }

    const archiveCourseProductLBBtn = (pid) => {
        setProductArchiveId(pid)
        setProductDeleteLB(true)
    }

    const closeLightbox = () => {
        setCourseArchivedLB(false)
        setProductDeleteLB(false)
    }

    const handleLBArchiveProductBtn = () => {
        archiveProduct(coursesState.actCourse.id, productArchiveId)
        setProductDeleteLB(false)
    }

    return (
        <>
            <Page
                actions={[
                    {
                        icon: 'plus-circled',
                        onClick: addCourseBtnClick,
                        tid: '524',
                        title: <Trans id={524}/>
                    },
                    {
                        icon: 'eye-off',
                        onClick: archivedCourseBtnClick,
                        tid: '525',
                        title: <Trans id={525}/>
                    }
                ]}
                addClasses={'is--fullwidth'}
                tid='244'
                title={<Trans id={244}/>}
                contentElements={
                    (
                        <>
                            <div className='c-tab__outer is--fullwidth'>
                                <ComplexCourseList
                                    loadingCourseList={loadingCourseList}
                                    archiveCourse={archiveCourse}
                                    getCourseList={getCourseList}
                                    onItemClick={onCourseItemClick}
                                    onTabOpen={doOpenCourseDetail}
                                    openAddCourseLB={openAddCourseLB}
                                    openCourseList={openCourseList}
                                    setOpenAddCourseLB={setOpenAddCourseLB}
                                    width={tabsWidths.courseList}
                                    inactiveCourse={inactiveCourse}
                                />
                                <ComplexCourseDetail
                                    archiveCourse={archiveCourse}
                                    getCourseList={getCourseList}
                                    loadingCourse={loadingCourse}
                                    onTabOpen={doOpenCourseDetail}
                                    openCourseDetail={openCourseDetail}
                                    width={tabsWidths.courseDetail}
                                    onCourseItemClick={onCourseItemClick}
                                />
                                <ComplexCourseProducts
                                    archiveCourseProductLBBtn={archiveCourseProductLBBtn}
                                    getCourseProductList={getCourseProductList}
                                    loadingCourseProductList={loadingCourseProductList}
                                    onItemClick={onCourseProductItemClick}
                                    openCourseProducts={openCourseProducts}
                                    width={tabsWidths.productList}
                                    duplicateProduct={duplicateProduct}
                                    inactiveCourseProduct={inactiveCourseProduct}
                                />
                                <ComplexCourseProduct
                                    archiveCourseProductLBBtn={archiveCourseProductLBBtn}
                                    deleteProduct={deleteProduct}
                                    getUnitsList={getUnitsList}
                                    loadingCourseProduct={loadingCourseProduct}
                                    loadingProductDelete={loadingProductDelete}
                                    loadingUnit={loadingUnit}
                                    openCourseProductDetail={openCourseProductDetail}
                                    width={tabsWidths.productDetail}
                                    onCourseProductItemClick={onCourseProductItemClick}
                                    getCourseProductList={getCourseProductList}
                                    duplicateProduct={duplicateProduct}
                                    getCoursePlaceList={p.getCoursePlaceList}
                                />
                            </div>
                            <ArchivedCourseLB
                                courseArchivedLB={courseArchivedLB}
                                closeLightbox={closeLightbox}
                                courses={coursesState.courses}
                                getArchivedProducts={getArchivedProducts}
                                loadingArchivedProducts={loadingArchivedProducts}
                                rearchiveCourse={rearchiveCourse}
                                rearchiveCourseProduct={rearchiveCourseProduct}
                            />
                            <CourseProductDeleteLB
                                closeLightbox={closeLightbox}
                                handleLBArchiveProductBtn={handleLBArchiveProductBtn}
                                productDeleteLB={productDeleteLB}
                            />
                        </>
                    )
                }
            />
        </>
    )
}

export default withGlobalState(PageCourses)
