import Trans from "../components/elements/trans/Trans";

/**
 *
 * @param p
 * @param alt
 * @returns {string|*}
 */
export function getUserShort(p, alt = '') {
    const firstname = p.globalState.user.accountFirstName
    const lastname = p.globalState.user.accountLastName
    if (typeof firstname !== undefined && typeof lastname !== undefined && firstname !== '' && lastname !== '' && firstname !== null && lastname !== null) return firstname.charAt(0) + lastname.charAt(0)
    if (alt !== '') return <span tid='218'>{alt}</span>
    return <span tid='218'><Trans id={218}/></span>
}

/**
 *
 * @param p
 * @returns {string|*}
 */
export function getUserSalutation(p) {
    return getUserSalutationFromStringWithoutFirstname(p.globalState.user.accountFirstName, p.globalState.user.accountLastName, p.globalState.user.accountSalutation)
}

/**
 *
 * @param firstname
 * @param lastname
 * @param salutation
 * @returns {JSX.Element|string}
 */
export function getUserSalutationFromStringWithoutFirstname(firstname, lastname, salutation) {
    if (typeof firstname !== undefined && typeof lastname !== undefined && firstname !== null && lastname !== null && lastname !== '') {
        // eslint-disable-next-line default-case
        switch (salutation) {
            case '-': {
                if (firstname !== '') return firstname + ' ' + lastname
                return ''
            }
            case 'f': {
                return <span tid={219}><Trans id={219}/>{' ' + lastname}</span>
            }
            case 'm': {
                return <span tid={220}><Trans id={220}/>{' ' + lastname}</span>
            }
        }
    }
    return ''
}

/**
 *
 * @param firstname
 * @param lastname
 * @param salutation
 * @param profession
 * @param html
 * @returns {JSX.Element|string}
 */
export function getUserSalutationFromString(firstname, lastname, salutation, profession = '', html = false) {
    const f = firstname
    const l = lastname
    let p = profession
    if (typeof f !== undefined && typeof l !== undefined && f !== null && l !== null && l !== '') {
        p = (p !== '' && p !== null)? p + ' ' : ''
        // eslint-disable-next-line default-case
        switch (salutation) {
            case '-': {
                if (f !== '') return p + f + ' ' + l
                return ''
            }
            case 'f': {
                return html? <span tid={219}><Trans id={219}/>{' ' + p + f + ' ' + l}</span> : p + f + ' ' + l
            }
            case 'm': {
                return html? <span tid={220}><Trans id={220}/>{' ' + p + f + ' ' + l}</span> : p + f + ' ' + l
            }
        }
    }
    return ''
}

/**
 *
 * @param p
 * @returns {string|*}
 */
export function getUserName(p) {
    if (p.globalState.user.accountFirstName !== '' && p.globalState.user.accountLastName !== '') {
        return p.globalState.user.accountFirstName.charAt(0) + p.globalState.user.accountLastName.charAt(0)
    }
    return ''
}

/**
 *
 * @param c
 * @returns {string}
 */
export function getDoubleString(c) {
    if (typeof c !== 'undefined' && c !== null) {
        const s = c.toString().split('.')
        if ((s.length - 1) <= 1) {
            if (!isNaN(c) && c.toString().indexOf('.') !== -1) return s[0] + ',' + s[1]
            return c + ',00'
        }
    }
    return ''
}

/**
 *
 * @param q
 * @param t
 * @param classes
 * @returns {JSX.Element|*}
 */
export function showSearchResult(q, t, classes = 'is--result') {
    if (q !== null && typeof q !== 'undefined') {
        if (q !== '') {
            const found = t.toLowerCase().indexOf(q.toLowerCase())
            if (found !== -1) return <>
                {t.substr(0, found)}<span className={classes}>{t.substr(found, q.length)}</span>{t.substr(found + q.length)}
            </>
        }
    }
    return t
}

/**
 *
 * @param searchValue
 * @param attributes
 * @returns {boolean}
 */
export function getSearchInString(searchValue, attributes) {
    if (typeof attributes === 'object' && searchValue && searchValue !== '') {
        let s = false
        attributes.forEach((i) => {
            if (i && i.toString().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) s = true
        })
        return s
    }
    return true
}
