import axios from "axios";

const axiosTRansInstance = axios.create({
    baseURL: process.env.REACT_APP_TRANSLATE_URL
})

/**
 *
 * @returns {Promise<unknown>}
 */
export async function transGetAll() {
    return await new Promise((res, rej) => {
        axiosTRansInstance.get('de/trans_rb.json')
            .then(r => {
                res(r);
            })
            .catch(r => {
                rej(r);
            })
    })
}

/**
 *
 * @param transState
 * @param id
 * @returns {string|*}
 */
export function getTrans(transState, id) {
    return (id in transState.trans)? transState.trans[id] : ''
}
