import { createSlice } from '@reduxjs/toolkit';
import {CourseObj} from "../../obj/Course/Course.obj";
import {CourseProductObj} from "../../obj/Product/CourseProduct.obj";
import {CoursePlacePost} from "../../obj/CoursePlace/CoursePlacePost.obj";
import {CourseSpeakerPostObj} from "../../obj/Speaker/CourseSpeakerPost.obj";
import {CourseServicePost} from "../../obj/CourseService/CourseServicePost.obj";

export const slice = createSlice({
    name: 'courses',
    initialState: {
        actCourse: CourseObj,
        actCoursePlace: CoursePlacePost,
        actCourseService: CourseServicePost,
        actCourseSpeaker: CourseSpeakerPostObj,
        actProduct: CourseProductObj,
        actUnit: {},
        categories: [],
        courses: [],
        courseKinds: [],
        courseKindsOptions: [],
        courseTypeOptions: [],
        courseTypes: [],
        occupations: [],
        occupationsOptions: [],
        products: [],
        productUnits: [],
        targets: [],
        targetsOptions: [],
        courseSpeaker: [],
        courseSpeakerOptions: [],
        coursePlaces: [],
        coursePlacesOptions: [],
        courseServices: [],
        courseServicesOptions: []
    },
    reducers: {
        setCourses: (s, p) => {
            s.courses = p.payload
        },
        setActCourse: (s, p) => {
            s.actCourse = p.payload
        },
        setActCoursePlace: (s, p) => {
            s.actCoursePlace = CoursePlacePost
            s.actCoursePlace = p.payload
        },
        setActCourseService: (s, p) => {
            s.actCourseService = CourseServicePost
            s.actCourseService = p.payload
        },
        setActCourseSpeaker: (s, p) => {
            s.actCourseSpeaker = p.payload
        },
        setProducts: (s, p) => {
            s.products = p.payload
        },
        setActProduct: (s, p) => {
            s.actProduct = p.payload
            if (typeof p.payload.courseType !== 'undefined' && typeof p.payload.courseType.id !== 'undefined') s.actProduct.courseTypeId = p.payload.courseType.id
            if (typeof p.payload.courseKind !== 'undefined' && p.payload.courseKind !== null) s.actProduct.courseKindId = p.payload.courseKind.id
        },
        setCourseTypes: (s, p) => {
            s.courseTypes = p.payload
            s.courseTypeOptions = p.payload.map(t => {return {
                value: t.id,
                label: t.title
            }})
        },
        setCourseKinds: (s, p) => {
            s.courseKinds = p.payload
            s.courseKindsOptions = p.payload.map(t => {
                return {
                    value: t.id,
                    label: t.title
                }
            })
        },
        setCategories: (s, p) => {
            s.categories = p.payload
        },
        setProductUnits: (s, p) => {
            s.productUnits = p.payload.sort((a, b) => {return a.sequence - b.sequence})
        },
        setActUnit: (s, p) => {
            s.actUnit = p.payload
        },
        setOccupations: (s, p) => {
            s.occupations = p.payload

            s.occupationsOptions = s.occupations.map(o => {return {
                value: o,
                label: o.title
            }})
        },
        setTargets: (s, p) => {
            s.targets = p.payload

            s.targetsOptions = s.targets.map(t => {return {
                value: t,
                label: t.title
            }})
        },
        setCourseSpeaker: (s, p) => {
            s.courseSpeaker = p.payload

            s.courseSpeakerOptions = s.courseSpeaker.map(cs => {return {
                value: cs,
                label: cs.firstName + ' ' + cs.lastName
            }})
        },
        setCoursePlaces: (s, p) => {
            s.coursePlaces = p.payload

            s.coursePlacesOptions = s.coursePlaces.map(p => {return {
                value: p.id,
                label: p.street + ' ' + p.houseNumber + ' ' + p.zip + ' ' + p.city
            }})
        },
        setCourseServices: (s, p) => {
            s.courseServices = p.payload

            s.courseServicesOptions = s.courseServices.map(s => {return {
                value: s,
                label: s.title
            }})
        },
        updateActCourse: (s, p) => {
            const key = Object.keys(p.payload)
            return {
                ...s,
                actCourse: {
                    ...s.actCourse,
                    [key[0]]: p.payload[key[0]]
                }
            }
        },
        updateActCoursePlace: (s, p) => {
            const key = Object.keys(p.payload)
            return {
                ...s,
                actCoursePlace: {
                    ...s.actCoursePlace,
                    [key[0]]: p.payload[key[0]]
                }
            }
        },
        updateActCourseService: (s, p) => {
            const key = Object.keys(p.payload)
            return {
                ...s,
                actCourseService: {
                    ...s.actCourseService,
                    [key[0]]: p.payload[key[0]]
                }
            }
        },
        updateActCourseSpeaker: (s, p) => {
            const key = Object.keys(p.payload)
            return {
                ...s,
                actCourseSpeaker: {
                    ...s.actCourseSpeaker,
                    [key[0]]: p.payload[key[0]]
                }
            }
        },
        updateActProduct: (s, p) => {
            const k = Object.keys(p.payload)
            let ak = k[0]
            if (k[0] === 'readyToPublishedProduct') ak = 'readyToPublished'
            return {
                ...s,
                actProduct: {
                    ...s.actProduct,
                    [ak]: p.payload[k[0]]
                }
            }
        },
        updateActProductUnit: (s, p) => {
            const k = Object.keys(p.payload)
            return {
                ...s,
                actUnit: {
                    ...s.actUnit,
                    [k[0]]: p.payload[k[0]]
                }
            }
        }
    }
})

export const {
    setActCourse,
    setActCoursePlace,
    setActCourseService,
    setActCourseSpeaker,
    setActProduct,
    setActUnit,
    setCategories,
    setCourses,
    setCourseKinds,
    setCoursePlaces,
    setCourseSpeaker,
    setCourseTypes,
    setOccupations,
    setProducts,
    setProductUnits,
    setTargets,
    setCourseServices,
    updateActCourse,
    updateActCoursePlace,
    updateActCourseSpeaker,
    updateActProduct,
    updateActProductUnit,
    updateActCourseService
} = slice.actions

export default slice.reducer
