import React from 'react';
import {withGlobalState} from "react-globally";
import Infobox from "../../../components/elements/message/Infobox";
import LB from "../../../components/widgets/message/LB";
import Trans from "../../../components/elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

const ActiveCourseLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--light-ok'
                            onClick={p.closeLightbox}
                            tid='859'
                        ><Trans id={859}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={p.handleLBActiveCourseBtn}
                            tid='860'
                        ><Trans id={860}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={<div>
                <Infobox
                    addClasses={'is--bg-pri6 is--no-border is--icon-left c-w'}
                    content={<Trans id={861}/>}
                    icon={'attention'}
                    tid='861, 862'
                    title={getTrans(transState, 862)}
                />
            </div>}
            onClose={p.closeLightbox}
            open={p.courseActiveLB}
            tid='863'
            title={getTrans(transState, 863)}
        />
    )
}

export default withGlobalState(ActiveCourseLB)
