import React, {useEffect, useState} from "react";
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import {getInputValue} from "../../../components/functions/FuncForm";
import {hasKeys} from "../../../components/functions/FuncObjects";
import FormLoad from "../../../components/elements/form/FormLoad";
import {CourseSpeakerPostObj} from "../../../obj/Speaker/CourseSpeakerPost.obj";
import ComplexCourseSpeakerForm from "../../forms/courseSpeaker/ComplexCourseSpeakerForm";
import {useSelector} from "react-redux";
import {getTrans} from "../../../services/ServiceTrans";
import Trans from "../../../components/elements/trans/Trans";

const AddCourseSpeakerLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    /************** form **************/
    const [validated, setValidated] = useState({})
    const [addCourseSpeakerSubmit, setAddCourseSpeakerSubmit] = useState(false)

    /************** objects **************/
    const [newCourseSpeaker, setNewCourseSpeaker] = useState(CourseSpeakerPostObj)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setAddCourseSpeakerSubmit(!hasKeys(validated))
    }, [validated])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    /* change */
    const handleInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        setNewCourseSpeaker({
            ...newCourseSpeaker,
            [input.name]: input.value
        })
    }

    const handleLBAddCourseSpeakerBtn = () => {
        p.handleLBAddCourseSpeakerBtn(newCourseSpeaker, addCourseSpeakerSubmit)
    }

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn'
                            disabled={!addCourseSpeakerSubmit}
                            onClick={handleLBAddCourseSpeakerBtn}
                            tid={'633'}
                        ><Trans id={633}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeLightbox}
                            tid={'634'}
                        ><Trans id={634}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <div>
                    <ComplexCourseSpeakerForm
                        courseSpeaker={newCourseSpeaker}
                        handleInputChange={handleInputChange}
                        validated={validated}
                        setValidated={setValidated}
                        validationShowNew={p.validationShowNew}
                    />
                    <FormLoad a={p.addCourseSpeakerLoading}/>
                </div>
            }
            onClose={p.closeLightbox}
            open={p.addCourseSpeakerLB}
            tid={'632'}
            title={getTrans(transState, 632)}
        />
    )
}

export default withGlobalState(AddCourseSpeakerLB)
