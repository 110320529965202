import React, {useState} from 'react'
import {getValidationBar} from "../../functions/FuncForm";
import LB from "../../widgets/message/LB";
import Trans from "../trans/Trans";
import {useSelector} from "react-redux";

const FormValidationBar = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const [moreInfosLB, setMoreInfosLB] = useState(false)
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleMoreInfosClick = () => {
        setMoreInfosLB(true)
    }

    const closeLightbox = () => {
        setMoreInfosLB(false)
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const vali = getValidationBar(p, transState)

    const validationsTiles = vali.validations.map((validationItem, i) => {
        if (typeof validationItem.title !== 'undefined' && validationItem.title !== '') {
            const iconBefore = (typeof validationItem.iconBefore !== 'undefined')? 'ico-' + validationItem.iconBefore : ''
            const iconAfter = (typeof validationItem.iconAfter !== 'undefined')? 'ico-after-' + validationItem.iconAfter : ''
            let title = validationItem.title

            // eslint-disable-next-line default-case
            switch (validationItem.type) {
                case 'maxLength': {
                    if (typeof p.val === 'string') title = p.val.length  + '/' + title
                    break;
                }
            }

            return <li key={i} className={`${iconBefore} ${iconAfter}`}>
                <span>{title}</span>
            </li>
        }
        return ''
    })

    return (
        <>
            {(p.showInfo && vali.title !== '')
                && <div className={'c-validation'}>
                    <div className={'c-validation__content'}>
                        <ul className={'c-validation__menu'}>{(vali.validations.length !== 0 && p.showValidations)
                            && validationsTiles
                        }{(vali.text && vali.text !== '') &&
                            <li
                                className={'c-validation__more'}
                                onClick={handleMoreInfosClick}
                                tid={'599'}
                            ><Trans id={599}/></li>
                        }</ul>
                    </div>
                </div>
            }
            <LB
                actions={
                    <>
                        <li>
                            <button
                                className='e-btn'
                                onClick={closeLightbox}
                                tid={'598'}
                            ><Trans id={598}/></button>
                        </li>
                    </>
                }
                addClasses='is--big'
                content={
                    <>
                        <ul className={'c-validation__menu'}>{validationsTiles}</ul>
                        {vali.text? <div className={'p-t-16'} dangerouslySetInnerHTML={{__html:vali.text}}/> : ''}
                    </>
                }
                onClose={closeLightbox}
                open={moreInfosLB}
                tid={'600'}
                title={<><Trans id={600}/>: {vali.title}</>}
            />
        </>
    )
}

export default FormValidationBar
