import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import {getInputValue} from "../../components/functions/FuncForm";
import {useSelector} from "react-redux";
import {apiPost} from "../../services/ServiceAPI";
import {hasKeys} from "../../components/functions/FuncObjects";
import FormLoad from "../../components/elements/form/FormLoad";
import ListItemCourseProduct from "../../components/widgets/list/ListItemCourseProduct";
import {PostProductObj} from "../../obj/Product/PostProduct.obj";
import ListGroupCourse from "../../components/widgets/list/ListGroupCourse";
import CourseProductAddLB from "../lightboxes/courseProduct/CourseProductAddLB";
import Trans from "../../components/elements/trans/Trans";
import FormInput from "../../components/elements/form/FormInput";
import {getSearchInString} from "../../services/ServiceData";
import {validation} from "../../components/functions/FuncValidation";
import {getTrans} from "../../services/ServiceTrans";

const ComplexCourseProducts = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const transState = useSelector((state) => state.trans)

    /************** loading **************/
    const [loadingAddProduct, setLoadingAddProduct] = useState(false)

    /************** form **************/
    /* states */
    const [addProductSubmit, setAddProductSubmit] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [inputs] = useState({
        contingent: [['required']],
        productNumberPrivate: [[['maxLength', 32]]],
        supportText: [[['maxLength', 600]]],
        totPrice: [['required']],
        typeOfSale: [['required']]
    })

    /* validation */
    const [addProductValidated, setAddProductValidated] = useState({})
    const [validationShow, setValidationShow] = useState(false)

    /************** lightbox **************/
    const [productAddLB, setProductAddLB] = useState(false)

    /************** objects **************/
    const [newProduct, setNewProduct] = useState(PostProductObj)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        resetValidation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (productAddLB === true) resetValidation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productAddLB])

    useEffect(() => {
        setAddProductSubmit(!hasKeys(addProductValidated))
    }, [addProductValidated])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** general **************/
    const resetValidation = () => {
        let vali = {}
        for (const [key, value] of Object.entries(inputs)) {
            const status = validation(value, newProduct[key], p)
            if (!status.status) vali[key] = status
        }
        setAddProductValidated(vali)
    }

    const getListItemProduct = (filter, status, nofilter = '') => {
        return coursesState.products.filter(product => {
            let status = product.courseProductStatus[filter].status
            if (nofilter !== '') status = status && !product.courseProductStatus[filter].status
            return status
        }).map((product) => {
            return <>
                {
                    getSearchInString(searchValue, [
                        product.productNumber,
                        product.productNumberPrivate,
                        product.courseType.title
                    ]) &&
                    <ListItemCourseProduct
                        courseId={coursesState.actCourse.id}
                        key={product.id}
                        onclick={p.onItemClick}
                        product={product}
                        selected={(coursesState.actProduct.id === product.id)? 'is--selected' : ''}
                        search={searchValue}
                        status={status}
                        onArchive={p.archiveCourseProductLBBtn}
                        onDuplicate={onProductDuplicate}
                        inactiveCourseProduct={p.inactiveCourseProduct}
                    />
                }
            </>
        });
    }

    /************** input events **************/
    /* change */
    const handleNewProductChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        setNewProduct({
            ...newProduct,
            [input.name]: input.value
        })
    }

    const onCourseProductSearch = (e) => {
        setSearchValue(e.target.value)
    }

    /* submit */
    /************** button events **************/
    const addProductHandler = () => {
        setProductAddLB(true)
    }

    const closeLightbox = () => {
        setProductAddLB(false)
    }

    const handleLBAddProductBtn = () => {
        setValidationShow(true)

        if (addProductSubmit) {
            setLoadingAddProduct(true)
            apiPost('courses/' + coursesState.actCourse.id + '/products', p, newProduct)
                .then(() => {
                    p.getCourseProductList(coursesState.actCourse.id)
                })
                .finally(() => {
                    setLoadingAddProduct(false)
                    setProductAddLB(false)
                    setValidationShow(false)
                })
        }
    }

    const onProductDuplicate = (pid) => {
        p.duplicateProduct(coursesState.actCourse.id, pid)
    }

    return (
        <>
            <ContentTabs
                body={<>
                    {!p.loadingCourseProductList
                        ? <>
                            <ListGroupCourse
                                colorClass={'c-status-ok'}
                                status={getTrans(transState, 700)}
                                info={getTrans(transState, 704)}
                                listItems={getListItemProduct('bookable', 'active')}
                                tid='700, 704'
                            />
                            <ListGroupCourse
                                colorClass={'c-status-warn'}
                                status={getTrans(transState, 701)}
                                info={getTrans(transState, 705)}
                                listItems={getListItemProduct('publishedNotBookable', 'not-published')}
                                tid='701, 705'
                            />
                            <ListGroupCourse
                                colorClass={'c-status-warn'}
                                status={getTrans(transState, 702)}
                                info={getTrans(transState, 706)}
                                listItems={getListItemProduct('activeNotPublished', 'not-published')}
                                tid='702, 706'
                            />
                            <ListGroupCourse
                                colorClass={'c-grey'}
                                status={getTrans(transState, 703)}
                                info={getTrans(transState, 707)}
                                listItems={getListItemProduct('inactive', 'inactive')}
                                tid='703, 707'
                            />
                        </>
                        : <FormLoad a={true}/>
                    }
                </>}
                head={<>
                    <div className='c-tab__head-top'>
                        <div>
                            <p
                                className='c-tab__head-title'
                                tid='410'
                            ><Trans id={410}/> <span className={'e-text__tag is--filled'}>{coursesState.products.length}</span></p>
                        </div>
                        <div>
                            <nav className='c-tab__head-actions'>
                                <ul>
                                    <li>
                                        <button
                                            className='e-btn__action ico-plus-circled'
                                            onClick={addProductHandler}
                                        />
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className='c-tab__head-bottom'>
                        <form autoComplete="off">
                            <FormInput
                                addClasses='is--visible-border'
                                addClassesWrap={'p-none'}
                                name='searchList'
                                onChange={onCourseProductSearch}
                                placeholder={getTrans(transState, 708)}
                                tid='708'
                                type='text'
                                value={searchValue}
                            />
                        </form>
                    </div>
                </>}
                onOpen={p.onTabOpen}
                open={p.openCourseProducts}
                title={getTrans(transState, 709)}
                width={p.width}
            />
            <CourseProductAddLB
                addProductSubmit={addProductSubmit}
                addProductValidated={addProductValidated}
                closeLightbox={closeLightbox}
                handleLBAddProductBtn={handleLBAddProductBtn}
                handleNewProductChange={handleNewProductChange}
                inputs={inputs}
                loadingAddProduct={loadingAddProduct}
                newProduct={newProduct}
                productAddLB={productAddLB}
                setAddProductValidated={setAddProductValidated}
                validationShow={validationShow}
            />
        </>
    )
}

export default withGlobalState(ComplexCourseProducts)
