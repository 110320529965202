import { createSlice } from '@reduxjs/toolkit';
import {OrderObj} from "../../obj/Order/Order.obj";

export const slice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        leads: [],
        actOrder: OrderObj
    },
    reducers: {
        setOrders: (state, payload) => {
            state.orders = payload.payload
        },
        setLeads: (state, payload) => {
            state.leads = payload.payload
        },
        setActOrder: (state, payload) => {
            state.actOrder = payload.payload
        }
    }
});

export const {
    setOrders,
    setLeads,
    setActOrder
} = slice.actions;

export default slice.reducer;
