import React from 'react';
import {withGlobalState} from "react-globally";
import Infobox from "../../../components/elements/message/Infobox";
import LB from "../../../components/widgets/message/LB";
import ComplexAddCourseForm from "../../forms/course/ComplexAddCourseForm";
import Trans from "../../../components/elements/trans/Trans";
import FormLoad from "../../../components/elements/form/FormLoad";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

const CourseAddLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <FormLoad
                        a={p.loading}
                        addClasses={'p-b-16'}
                        checked={p.globalState.auth.logedin}
                    />
                    {!p.loading &&
                        <>
                            <li>
                                <button
                                    className='e-btn'
                                    onClick={p.handleLBAddCourseBtn}
                                    tid='407'
                                ><Trans id={407}/></button>
                            </li>
                            <li>
                                <button
                                    className='e-btn is--orange'
                                    onClick={p.handleLightboxClose}
                                    tid='408'
                                ><Trans id={408}/></button>
                            </li>
                        </>
                    }
                </>
            }
            addClasses='is--big'
            onClose={p.closeLightbox}
            open={p.open}
            tid='406'
            title={<Trans id={406}/>}
            content={
                <div>
                    <div className='p-b-16'>
                        <Infobox
                            addClasses={'is--no-border is--icon-left is--small'}
                            content={getTrans(transState, 869)}
                            html={true}
                            icon={'info'}
                            tid='533, 869'
                            title={<Trans id={533}/>}
                        />
                    </div>
                    <ComplexAddCourseForm
                        handleNewCourseChange={p.handleNewCourseChange}
                        loadingAddCourse={p.loadingAddCourse}
                        newCourse={p.newCourse}
                        setValidated={p.setValidated}
                        validated={p.validated}
                        validationShow={p.validationShow}
                    />
                </div>
            }
        />
    )
}

export default withGlobalState(CourseAddLB)
