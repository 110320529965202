import React from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import FormLoad from "../../../components/elements/form/FormLoad";
import Trans from "../../../components/elements/trans/Trans";
import FormInput from "../../../components/elements/form/FormInput";
import FormGrouping from "../../../components/elements/form/FormGrouping";

const ComplexProfileDescForm = (p) => {
    const handleInputChange = p.handleInputChange
    const profile = p.profile

    return (
        <form onSubmit={event => p.handleFormSubmitDesc(event)}>
            <FormGrouping
                title={<span tid='243'><Trans id={243}/></span>}
            >
                <FormRow childs={
                    <FormInput
                        data={profile.shortDesc}
                        label={<Trans id={232}/>}
                        name='shortDesc'
                        onEditorChange={handleInputChange}
                        required
                        tid='232'
                        type={'richarea'}
                        value={profile.shortDesc}
                        validationChecks={[['required'], [['maxLength', 350]]]}
                        validationShow={true}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                    />
                }/>
                <FormRow childs={
                    <FormInput
                        data={profile.longDesc}
                        label={<Trans id={233}/>}
                        name='longDesc'
                        onEditorChange={handleInputChange}
                        required
                        tid='233'
                        type={'richarea'}
                        value={profile.longDesc}
                        validationChecks={[['required']]}
                        validationShow={true}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                    />
                }/>
                <FormLoad a={p.loading}/>
                <FormRow childs={
                    <button
                        className='e-btn'
                        disabled={!p.submitDesc}
                        tid='234'
                        type='submit'
                    ><Trans id={234}/></button>
                }/>
            </FormGrouping>
        </form>
    )
}

export default withGlobalState(ComplexProfileDescForm)
