import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import {useDispatch, useSelector} from "react-redux";
import {getInputValue} from "../../components/functions/FuncForm";
import {updateActCourseService} from "../../store/reducer/courseSlice";
import {hasKeys} from "../../components/functions/FuncObjects";
import FormLoad from "../../components/elements/form/FormLoad";
import FormRow from "../../components/elements/form/FormRow";
import ButtonMenu from "../../components/elements/menu/ButtonMenu";
import ComplexCourseServiceForm from "../forms/courseService/ComplexCourseServiceForm";
import {getTrans} from "../../services/ServiceTrans";
import Trans from "../../components/elements/trans/Trans";

const ComplexCourseServiceDetail = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const transState = useSelector((state) => state.trans)
    const dispatch = useDispatch()
    const service = coursesState.actCourseService

    /************** form **************/
    const [validated, setValidated] = useState({})
    const [courseServiceSubmit, setCourseServiceSubmit] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    useEffect(() => {
        setCourseServiceSubmit(!hasKeys(validated))
    }, [validated])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    /* change */
    const handleInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        dispatch(updateActCourseService({[input.name]: input.value}))
    }

    /* submit */
    const handleCourseServiceSaveBtn = () => {
        p.updateCourseService(service, courseServiceSubmit)
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const serviceListHead = <div className='c-tab__head-top'>
        <div>
            <p className='c-tab__head-title f-s-16 f-w-7'>{service.title}</p>
        </div>
        <div>
            <nav className='c-tab__head-actions'>
                <ul>
                    <li>
                        <button
                            className='e-btn__action ico-trash'
                            onClick={p.handleCourseServiceDeleteLB}
                            tid='724'
                            title={getTrans(transState, 724)}
                        />
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    const serviceListContent = <>
        <ComplexCourseServiceForm
            courseService={service}
            handleInputChange={handleInputChange}
            validated={validated}
            setValidated={setValidated}
            validationShow={p.validationShowDetail}
        />
        <FormLoad a={p.loadingChangeService}/>
        <FormRow childs={
            <ButtonMenu items={[
                <button
                    className='e-btn'
                    disabled={!courseServiceSubmit}
                    onClick={handleCourseServiceSaveBtn}
                    tid='725'
                    type='submit'
                ><Trans id={725}/></button>
            ]}/>
        }/>
    </>

    return (
        <ContentTabs
            body={serviceListContent}
            head={serviceListHead}
            open={p.openDetail}
            tid='726'
            title={getTrans(transState, 726)}
            width={50}
        />
    )
}

export default withGlobalState(ComplexCourseServiceDetail)
