import React from 'react'

const ButtonMenu = (p) => {
    if (p.items) {
        const items = p.items.map((item, i) => <li key={i}>{item}</li>)
        return (<div>
            <nav>
                <ul className='e-btn__menu'>
                    {items}
                </ul>
            </nav>
        </div>)
    }

    return ''
}

export default ButtonMenu
