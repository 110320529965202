import React from "react";
import {withGlobalState} from "react-globally";
import Trans from "../../../components/elements/trans/Trans";
import FormRow from "../../../components/elements/form/FormRow";
import FormInput from "../../../components/elements/form/FormInput";
import {NavLink} from "react-router-dom";
import FormError from "../../../components/elements/form/FormError";
import FormLoad from "../../../components/elements/form/FormLoad";
import {Btn} from "../../../components/functions/FuncBtn";
import Infobox from "../../../components/elements/message/Infobox";

const ComplexLoginForm = (p) => {
    const login = p.login

    return (
        <form className='e-form' onSubmit={event => p.handleFormSubmitLogin(event, p)}>
            <h3 className='f-s-24 f-w-7' tid='212'><Trans id={191}/></h3>
            <h4 className='f-s-18 f-w-5 p-tb-16' tid='211'><Trans id={192}/></h4>
            {p.action &&
                <>
                    <Infobox
                        addClasses={'is--bg-body is--no-border is--icon-left'}
                        content={<Trans id={651}/>}
                        icon={'info'}
                    />
                </>
            }
            <FormRow
                adClasses={'p-t-16'}
                childs={
                    <FormInput
                        label={<Trans id={199}/>}
                        name='username'
                        onChange={p.handleInputChange}
                        placeholder={<Trans id={198}/>}
                        required
                        tid='198, 199'
                        htmlType='email'
                        validationChecks={p.inputs.username}
                        validationShow={p.validationShow}
                        validationAct={p.validationAct}
                        validationSet={p.validationSet}
                        value={login.username}
                    />
                }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={201}/>}
                    name='accountPassword'
                    onChange={p.handleInputChange}
                    placeholder={<Trans id={200}/>}
                    required
                    tid='200, 201'
                    htmlType='password'
                    validationChecks={p.inputs.accountPassword}
                    validationShow={p.validationShow}
                    validationAct={p.validationAct}
                    validationSet={p.validationSet}
                    value={login.accountPassword}
                />
            }/>
            <FormRow childs={
                <>
                    <div className='w-100'>
                        <FormLoad
                            a={p.loading}
                            addClasses={'p-b-16'}
                            checked={p.globalState.auth.logedin}
                        />
                        {!p.loading &&
                            <button
                                className={Btn('bg-pri-3', 'w-100')}
                                tid='193'
                                title={<Trans id={193}/>}
                                type='submit'
                            >
                                <Trans id={193}/>
                            </button>
                        }
                    </div>
                </>
            }/>
            <p className='a-c p-b-16 p-t-16'>
                <NavLink
                    className='c-pri-2 hover-font-ul'
                    tid='194'
                    title={<Trans id={194}/>}
                    to="/forgot-password"
                >
                    <Trans id={194}/>
                </NavLink>
            </p>
            {p.message !== '' &&
                <FormError error={<span tid='197'>{p.message}</span>}/>
            }
            <div className='border border-w-t-1 border-c-lightgrey p-t-14'>
                <p className='a-c p-b-10' tid='196'>
                    <Trans id={196}/>
                </p>
                <div className='fl fl-jc-center'>
                    <NavLink
                        className={Btn('link-pr-3')}
                        tid='195'
                        title={<Trans id={195}/>}
                        to="/registration"
                    >
                        <Trans id={195}/>
                    </NavLink>
                </div>
            </div>
        </form>
    )
}

export default withGlobalState(ComplexLoginForm)
