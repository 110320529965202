import React from 'react';
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import Trans from "../../../components/elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

const DuplicateCourseProductLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={() => p.closeLightbox()}
                            tid='855'
                        ><Trans id={855}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={() => {
                                p.handleLBDuplicateCourseProductBtn()
                                p.closeLightbox()
                            }}
                            tid='856'
                        ><Trans id={856}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={() => {
                                p.handleLBDuplicateCourseProductBtn(true)
                                p.closeLightbox()
                            }}
                            tid='857'
                        ><Trans id={857}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <div>

                </div>
            }
            onClose={p.closeLightbox}
            open={p.courseProductDuplicateLB}
            tid='858'
            title={getTrans(transState, 858)}
        />
    )
}

export default withGlobalState(DuplicateCourseProductLB)
