import React, {useEffect, useState} from 'react';
import Page from "../Page";
import {withGlobalState} from "react-globally";
import ComplexCourseSpeakerList from "../../complex/courseSpeaker/ComplexCourseSpeakerList";
import {useDispatch, useSelector} from "react-redux";
import {apiDel, apiGet, apiPost, apiPut} from "../../services/ServiceAPI";
import {setActCourseSpeaker, setCourseSpeaker} from "../../store/reducer/courseSlice";
import ComplexCourseSpeakerDetail from "../../complex/courseSpeaker/ComplexCourseSpeakerDetail";
import DeleteCourseSpeakerLB from "../../complex/lightboxes/courseSpeaker/DeleteCourseSpeakerLB";
import AddCourseSpeakerLB from "../../complex/lightboxes/courseSpeaker/AddCourseSpeakerLB";
import Trans from "../../components/elements/trans/Trans";

const PageCourseSpeaker = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** loading **************/
    const [courseSpeakerLoading, setCourseSpeakerLoading] = useState(false)
    const [loadingChangeSpeaker, setLoadingChangeSpeaker] = useState(false)
    const [loadingDeleteSpeaker, setLoadingDeleteSpeaker] = useState(false)
    const [addCourseSpeakerLoading, setAddCourseSpeakerLoading] = useState(false)

    /************** lightbox **************/
    const [addCourseSpeakerLB, setAddCourseSpeakerLB] = useState(false)
    const [deleteSpeakerLB, setDeleteSpeakerLB] = useState(false)

    /************** global **************/
    const [loadSpeaker, setLoadSpeaker] = useState(false)
    const coursesState = useSelector((state) => state.courses)
    const dispatch = useDispatch()

    /************** states **************/
    const [openDetail, setOpenDetail] = useState(false)

    /************** validation **************/
    const [validationShowNew, setValidationShowNew] = useState(false)
    const [validationShowDetail, setValidationShowDetail] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (coursesState.courseSpeaker.length === 0 && !loadSpeaker) getCourseSpeakerList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** button events **************/
    const addCourseSpeakerHandler = () => {
        setAddCourseSpeakerLB(true)
    }

    const closeLightbox = () => {
        setAddCourseSpeakerLB(false)
        setDeleteSpeakerLB(false)
    }

    const handleCourseSpeakerDeleteLB = () => {
        setDeleteSpeakerLB(true)
    }

    const onItemClick = (id) => {
        getCourseSpeakerDetail(id)
    }

    const handleCourseSpeakerDelete = (id) => {
        setLoadingDeleteSpeaker(true)

        apiDel('courses/products/speaker/' + id, p)
            .finally(() => {
                setLoadingDeleteSpeaker(false)
                setDeleteSpeakerLB(false)
                setOpenDetail(false)
                getCourseSpeakerList()
            })
    }

    /************** input events **************/
    const handleLBAddCourseSpeakerBtn = (newCS, submit) => {
        setValidationShowNew(true)
        if (submit) {
            setAddCourseSpeakerLoading(true)
            apiPost('courses/products/speaker', p, newCS)
                .finally(() => {
                    getCourseSpeakerList()
                    setAddCourseSpeakerLB(false)
                    setAddCourseSpeakerLoading(false)
                    setValidationShowNew(false)
                })
        }
    }

    /************** api events **************/
    const getCourseSpeakerList = () => {
        setCourseSpeakerLoading(true)
        apiGet('courses/products/speaker', p)
            .then(r => {
                dispatch(setCourseSpeaker(r.data))
                setLoadSpeaker(true)
                setCourseSpeakerLoading(false)
            })
    }

    const getCourseSpeakerDetail = (id) => {
        dispatch(setActCourseSpeaker(coursesState.courseSpeaker.find((s) => {
            return s.id === id
        })))
        setOpenDetail(true)
    }

    const updateCourseSpeaker = (s, submit) => {
        setValidationShowDetail(true)
        if (submit) {
            setLoadingChangeSpeaker(true)
            apiPut('courses/products/speaker/' + s.id, p, s)
                .finally(() => {
                    setLoadingChangeSpeaker(false)
                    getCourseSpeakerList()
                    setValidationShowDetail(false)
                })
        }
    }

    return (
        <>
            <Page
                actions={[
                    {
                        adClasses: (coursesState.courseSpeaker.length === 0)? 'c-bg-pr-2-dark': '',
                        icon: 'plus-circled',
                        onClick: addCourseSpeakerHandler,
                        tid: '528',
                        title: <Trans id={528}/>
                    }
                ]}
                addClasses={'is--fullwidth'}
                contentElements={
                    (
                        <>
                            <DeleteCourseSpeakerLB
                                closeLightbox={closeLightbox}
                                deleteSpeakerLB={deleteSpeakerLB}
                                handleCourseSpeakerDelete={handleCourseSpeakerDelete}
                                loadingDeleteSpeaker={loadingDeleteSpeaker}
                                speaker={coursesState.actCourseSpeaker}
                            />
                            <AddCourseSpeakerLB
                                addCourseSpeakerLB={addCourseSpeakerLB}
                                addCourseSpeakerLoading={addCourseSpeakerLoading}
                                closeLightbox={closeLightbox}
                                handleLBAddCourseSpeakerBtn={handleLBAddCourseSpeakerBtn}
                                validationShowNew={validationShowNew}
                            />
                            <div className='c-tab__outer is--fullwidth'>
                                <ComplexCourseSpeakerList
                                    addCourseSpeakerHandler={addCourseSpeakerHandler}
                                    courseSpeakerLoading={courseSpeakerLoading}
                                    onItemClick={onItemClick}
                                />
                                {coursesState.actCourseSpeaker.firstName !== null &&
                                    <ComplexCourseSpeakerDetail
                                        handleCourseSpeakerDeleteLB={handleCourseSpeakerDeleteLB}
                                        loadingChangeSpeaker={loadingChangeSpeaker}
                                        openDetail={openDetail}
                                        updateCourseSpeaker={updateCourseSpeaker}
                                        validationShowDetail={validationShowDetail}
                                    />
                                }
                            </div>
                        </>
                    )
                }
                tid='529'
                title={<Trans id={529}/>}
            />
        </>
    )
}

export default withGlobalState(PageCourseSpeaker)
