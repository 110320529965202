import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'features',
    initialState: {
        features: []
    },
    reducers: {
        setFeatures: (state, payload) => {
            state.features = payload.payload
        }
    }
});

export const {
    setFeatures
} = slice.actions;

export default slice.reducer;
