import React from "react";
import Trans from "../../../components/elements/trans/Trans";
import FormRow from "../../../components/elements/form/FormRow";
import FormInput from "../../../components/elements/form/FormInput";
import FormLoad from "../../../components/elements/form/FormLoad";
import {NavLink} from "react-router-dom";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";
import {Btn} from "../../../components/functions/FuncBtn";

const ComplexRegistrationForm = (p) => {
    const transState = useSelector((state) => state.trans)
    return (
        <form onSubmit={event => p.handleFormSubmitRegistration(event, p)}>
            <h3 className='f-s-24 f-w-7' tid='212'><Trans id={212}/></h3>
            <h4 className='f-s-18 f-w-5 p-tb-16' tid='211'><Trans id={211}/></h4>
            <FormRow
                adClasses={'p-t-16'}
                childs={
                    <FormInput
                        label={<Trans id={199}/>}
                        name='accountMail'
                        onChange={p.handleRegistrationInputChange}
                        placeholder={getTrans(transState, 198)}
                        required
                        tid='198, 199'
                        htmlType='email'
                        value={p.registration.accountMail}
                        validationChecks={p.inputs.accountMail}
                        validationShow={p.validationShow}
                        validationAct={p.validatedRegistration}
                        validationSet={p.setValidatedRegistration}
                    />
                }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={201}/>}
                    name='accountPassword'
                    onChange={p.handleRegistrationInputChange}
                    placeholder={getTrans(transState, 200)}
                    required
                    tid='200, 201'
                    htmlType='password'
                    value={p.registration.accountPassword}
                    validationChecks={p.inputs.accountPassword}
                    validationShow={p.validationShow}
                    validationAct={p.validatedRegistration}
                    validationSet={p.setValidatedRegistration}
                />
            }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={214}/>}
                    name='accountPasswordRepeat'
                    onChange={p.handleRegistrationInputChange}
                    placeholder={getTrans(transState, 213)}
                    required
                    tid='213, 214'
                    htmlType='password'
                    value={p.registration.accountPasswordRepeat}
                    validationChecks={p.inputs.accountPasswordRepeat.concat([[['passwordEqual', p.registration.accountPassword]]])}
                    validationShow={p.validationShow}
                    validationAct={p.validatedRegistration}
                    validationSet={p.setValidatedRegistration}
                />
            }
            />
            {/*
             <FormRow childs={
                <FormInput
                    label={<span>Mit der Registrierung stimmen Sie den <a href="https://www.fobimarkt.com/agb-anbieter" rel="noreferrer"
                                                                         target="_blank"><b>AGB</b></a> zu.
                        Bitte beachten Sie die <a href="https://www.fobimarkt.com/datenschutz" rel="noreferrer"
                                                  target="_blank"><b>Datenschutzbestimmungen.</b></a></span>}
                    name='agb'
                    onChange={p.handleRegistrationInputChange}
                    type={'checkbox'}
                    value={p.registration.agb}
                    validationChecks={p.inputs.agb}
                    validationShow={p.validationShow}
                    validationAct={p.validatedRegistration}
                    validationSet={p.setValidatedRegistration}
                />
            }
            />
            */}
            <FormRow childs={
                <div tid='797'><Trans id={797} html={true}/></div>
            }
            />
            <FormRow
                adClasses={'p-t-16'}
                childs={
                <>
                    <div className='w-100 fl fl-jc-center'>
                        <FormLoad
                            a={p.loadingRegistration}
                            addClasses={'p-b-16'}
                        />
                        {!p.loadingRegistration&&
                            <button
                                className={Btn('bg-pri-3')}
                                tid='209'
                                title={<Trans id={209}/>}
                                type='submit'
                            >
                                <Trans id={209}/>
                            </button>
                        }
                    </div>
                </>
            }/>
            <FormRow
                adClasses={'p-t-16'}
                childs={
                    <div className='fl fl-jc-center w-100'>
                        <NavLink
                            className={Btn('link-pr-3')}
                            tid='215'
                            to="/login"
                        ><Trans id={215}/></NavLink>
                    </div>
                }
            />
        </form>
    )
}

export default ComplexRegistrationForm
