import update from "react-addons-update";
import {hasKeys} from "../components/functions/FuncObjects";

/**
 *
 * @param file
 * @returns {Promise<unknown>}
 */
export function toBase64(file) {
    return new Promise((res, rej) => {
        const r = new FileReader();
        r.readAsDataURL(file);
        r.onload = () => res(r.result);
        r.onerror = e => rej(e);
    })
}

class ServiceForm {
    /**
     *
     * @param p
     * @param event
     * @param params
     */
    setGlobalUser(p, event, params = {}) {
        let v = ''
        let n = ''

        if (!(!hasKeys(params) && params.constructor === Object)) {
            v = params.value
            n = params.name
        }

        if (typeof params === 'string') {
            v = event.editor.getData()
            n = params
        }

        if (typeof event.target !== "undefined") {
            v = event.target.value
            n = event.target.name
        }

        p.setGlobalState(update(p.globalState, {
            user: {
                [n]: {
                    $set: v
                }
            }
        }))
    }
}

export default ServiceForm