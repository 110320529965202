import React from 'react';
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import Infobox from "../../../components/elements/message/Infobox";
import FormLoad from "../../../components/elements/form/FormLoad";
import Trans from "../../../components/elements/trans/Trans";

const DeleteCoursePlaceLB = (p) => {
    const handleCoursePlaceDelete = () => {
        p.handleCoursePlaceDelete(p.place.id)
    }

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={handleCoursePlaceDelete}
                            tid='801'
                        ><Trans id={801}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={p.closeLightbox}
                            tid='802'
                        ><Trans id={802}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <>
                    <div>
                        <Infobox
                            addClasses={'is--bg-pri6 is--no-border is--icon-left c-w'}
                            content={<Trans id={803}/>}
                            icon={'attention'}
                            tid='803, 804'
                            title={<Trans id={804}/>}
                        />
                    </div>
                    <FormLoad a={p.loadingDeletePlace}/>
                </>
            }
            onClose={p.closeLightbox}
            open={p.deletePlaceLB}
            tid='805'
            title={<Trans id={805}/>}
        />
    )
}

export default withGlobalState(DeleteCoursePlaceLB)
