import React from 'react'
import {Link} from "react-router-dom";
import Logo from "./Logo";

const HeaderLogo = () => {
    return (
        <Link to="/">
            <Logo/>
        </Link>
    )
}

export default HeaderLogo
