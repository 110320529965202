
/**
 *
 * @param obj
 * @returns {boolean}
 */
export function hasKeys(obj) {
    if (obj !== null && obj !== undefined) return Object.keys(obj).length !== 0
    return false
}
