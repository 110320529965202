import update from "react-addons-update";

/**
 *
 * @param o
 * @returns {FormData}
 */
export function getFormData(o) {
    const f = new FormData()
    Object.keys(o).forEach(k => {
        (typeof o[k].date !== 'undefined')? f.append(k, o[k].date) : f.append(k, o[k])
    });
    return f
}

/**
 *
 * @param s
 * @param o
 * @param e
 * @returns {*}
 */
export function setStateDataFromForm(s, o, e) {
    return update(s, {
        [o]: {
            [e.target.name]: {$set: (e.target.type === 'checkbox')? e.target.checked : e.target.value}
        }
    })
}

/**
 *
 * @param date
 * @returns {string|*}
 */
export function getDateString(date) {
    if (date === null || typeof date === 'undefined') return date
    return (date.getHours() === 0 && date.getMinutes() === 0) ? buildDateStringNoTime(date) : buildDateStringWithTime(date)
}

/**
 *
 * @param date
 * @returns {string}
 */
export function buildDateStringNoTime(date) {
    return (date === null)
        ? date
        : getTwoDigits(date.getDate()) + '.' + getTwoDigits(parseInt(date.getMonth(), 10) + 1) + '.' + getYearShort(date.getFullYear())
}

/**
 *
 * @param date
 * @returns {string}
 */
export function buildDateStringNoTimeEN(date) {
    if (date === null) return date
    return getDigits(date.getFullYear(), 4) + '-' + getTwoDigits((parseInt(date.getMonth(), 10) + 1)) + '-' + getTwoDigits(date.getDate())
}

/**
 *
 * @param date
 * @returns {string}
 */
export function buildDateStringWithTime(date) {
    if (date === null) return date
    return buildDateStringNoTime(date) + ' ' + buildTimeString(date)
}

/**
 *
 * @param date
 * @returns {string}
 */
export function buildTimeString(date) {
    if (date === null) return date
    return getTwoDigits(date.getHours()) + ':' + getTwoDigits(date.getMinutes())
}

/**
 *
 * @param date
 * @returns {string}
 */
export function buildDateTimeString(date) {
    if (date === null || typeof date === 'string') return date
    return buildDateStringNoTimeEN(date) + 'T' + buildTimeString(date)
}

/**
 *
 * @param date
 * @param time
 * @returns {*}
 */
export function mergeTimeStringAndDate(date, time) {
    if (date === null) return date
    const ta = time.split(':')
    date.setHours(ta[0])
    date.setMinutes(ta[1])
    return date
}

/**
 *
 * @param date
 * @param dateString
 * @returns {*}
 */
export function mergeDateStringAndDate(date, dateString) {
    if (date === null) date = new Date()
    const nD = new Date(dateString)
    if (nD.toString() !== 'Invalid Date') {
        date.setFullYear(nD.getFullYear())
        date.setMonth(nD.getMonth())
        date.setDate(nD.getDate())
        if (date.getFullYear() < 1000) return dateString
    }else {
        return dateString
    }
    return date
}

/**
 *
 * @param startDate
 * @param endDate
 * @returns {string}
 */
export function getDateStringTwo(startDate, endDate) {
    return (ifDatesNotEqual(startDate, endDate))
        ? buildDateStringNoTime(startDate) + ' bis ' + buildDateStringNoTime(endDate)
        : buildDateStringWithTime(startDate) + 'Uhr bis ' + buildTimeString(endDate) + 'Uhr'
}

/**
 *
 * @param startDate
 * @param endDate
 * @returns {string}
 */
export function getDateStringTwoWithTimes(startDate, endDate) {
    return (ifDatesNotEqual(startDate, endDate))
        ? buildDateStringWithTime(startDate) + 'Uhr bis ' + buildDateStringWithTime(endDate) + 'Uhr'
        : buildDateStringWithTime(startDate) + 'Uhr bis ' + buildTimeString(endDate) + 'Uhr'
}

/**
 *
 * @param startDate
 * @param endDate
 * @returns {boolean}
 */
export function ifDatesNotEqual(startDate, endDate) {
    return Math.ceil(((endDate.getTime() - startDate.getTime()) / 86400000)) > 1
}

/**
 *
 * @param fullYear
 * @returns {string}
 */
export function getYearShort(fullYear) {
    return fullYear.toString().substring(2, 4)
}

/**
 *
 * @param d
 * @returns {boolean}
 */
export function isValidDate(d) {
    return d instanceof Date && !isNaN(d)
}

/**
 *
 * @param number
 * @returns {string|*}
 */
export function getTwoDigits(number) {
    return (number.toString().length < 2) ? '0' + number : number
}

/**
 *
 * @param number
 * @param digits
 * @returns {string|*}
 */
export function getDigits(number, digits) {
    let r = number.toString()
    const l = r.length
    for (let i = l; i < digits; i++) {
        r = '0' + r
    }
    return r
}
