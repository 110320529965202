import React, {useState} from 'react';
import Page from "../Page";
import {useDispatch, useSelector} from "react-redux";
import {apiDel, apiPost, apiPut} from "../../services/ServiceAPI";
import {setActCourseService} from "../../store/reducer/courseSlice";
import ComplexCourseServiceList from "../../complex/courseService/ComplexCourseServiceList";
import ComplexCourseServiceDetail from "../../complex/courseService/ComplexCourseServiceDetail";
import DeleteCourseServiceLB from "../../complex/lightboxes/courseService/DeleteCourseServiceLB";
import AddCourseServiceLB from "../../complex/lightboxes/courseService/AddCourseServiceLB";
import {withGlobalState} from "react-globally";
import Trans from "../../components/elements/trans/Trans";

const PageCourseService = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [addCourseServiceLoading, setAddCourseServiceLoading] = useState(false)
    const [loadingChangeService, setLoadingChangeService] = useState(false)
    const [loadingDeleteService, setLoadingDeleteService] = useState(false)

    /************** lightbox **************/
    const [addCourseServiceLB, setAddCourseServiceLB] = useState(false)
    const [deleteServiceLB, setDeleteServiceLB] = useState(false)

    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const dispatch = useDispatch()

    /************** states **************/
    const [openDetail, setOpenDetail] = useState(false)

    /************** form **************/
    const [validationShowDetail, setValidationShowDetail] = useState(false)
    const [validationShowNew, setValidationShowNew] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** button events **************/
    const addCourseServiceHandler = () => {
        setAddCourseServiceLB(true)
    }

    const closeLightbox = () => {
        setAddCourseServiceLB(false)
        setDeleteServiceLB(false)
    }

    const onItemClick = (id) => {
        getCourseServiceDetail(id)
    }

    const handleCourseServiceDeleteLB = () => {
        setDeleteServiceLB(true)
    }

    const handleCourseServiceDelete = (id) => {
        setLoadingDeleteService(true)

        apiDel('courses/products/places/' + id, p)
            .finally(() => {
                setLoadingDeleteService(false)
                setDeleteServiceLB(false)
                setOpenDetail(false)
                p.getCourseServiceList()
            })
    }

    /************** input events **************/
    const handleLBAddCourseServiceBtn = (newCourseService, submit) => {
        setValidationShowNew(true)

        if (submit) {
            setAddCourseServiceLoading(true)
            apiPost('courses/services', p, newCourseService)
                .finally(() => {
                    p.getCourseServiceList()
                    setAddCourseServiceLB(false)
                    setAddCourseServiceLoading(false)
                    setValidationShowNew(false)
                })
        }
    }

    /************** api events **************/
    const getCourseServiceDetail = (id) => {
        dispatch(setActCourseService(coursesState.courseServices.find((service) => {
            return service.id === id
        })))
        setOpenDetail(true)
    }

    const updateCourseService = (s, courseServiceSubmit) => {
        setValidationShowDetail(true)

        if (courseServiceSubmit) {
            setLoadingChangeService(true)
            apiPut('courses/services/' + s.id, p, s)
                .finally(() => {
                    setLoadingChangeService(false)
                    p.getCourseServiceList()
                    setValidationShowDetail(false)
                })
        }
    }

    return (
        <Page
            actions={[
                {
                    adClasses: (coursesState.courseServices.length === 0)? 'c-bg-pr-2-dark': '',
                    icon: 'plus-circled',
                    onClick: addCourseServiceHandler,
                    tid: '526',
                    title: <Trans id={526}/>
                }
            ]}
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <>
                        <DeleteCourseServiceLB
                            closeLightbox={closeLightbox}
                            deleteServiceLB={deleteServiceLB}
                            handleCourseServiceDelete={handleCourseServiceDelete}
                            loadingDeleteService={loadingDeleteService}
                            service={coursesState.actCourseService}
                        />
                        <AddCourseServiceLB
                            addCourseServiceLB={addCourseServiceLB}
                            addCourseServiceLoading={addCourseServiceLoading}
                            closeLightbox={closeLightbox}
                            handleLBAddCourseServiceBtn={handleLBAddCourseServiceBtn}
                            validationShowNew={validationShowNew}
                        />
                        <div className='c-tab__outer is--fullwidth'>
                            <ComplexCourseServiceList
                                addCourseServiceHandler={addCourseServiceHandler}
                                courseServiceLoading={p.courseServiceLoading}
                                onItemClick={onItemClick}
                            />
                            <ComplexCourseServiceDetail
                                handleCourseServiceDeleteLB={handleCourseServiceDeleteLB}
                                loadingChangeService={loadingChangeService}
                                openDetail={openDetail}
                                updateCourseService={updateCourseService}
                                validationShowDetail={validationShowDetail}
                            />
                        </div>
                    </>
                )
            }
            tid='527'
            title={<Trans id={527}/>}
        />
    )
}

export default withGlobalState(PageCourseService)