import React from 'react'
import {NavLink} from "react-router-dom";
import {withGlobalState} from "react-globally";

const Breadcrumb = (p) => {
    return (
        <>
            {typeof p.breadcrumb !== 'undefined' &&
                <div className='c-breadcrumb'>
                    <ul className='fl'>
                        {p.breadcrumb.map(function(item, i, r) {
                            if (item.route !== '') {
                                return <li className={'p-r-6' + (i + 1 !== r.length && ' ico-after-right-open after-p-l-6')} key={i}>
                                    <NavLink
                                        activeClassName="is--active"
                                        to={item.route}
                                    >{item.title}</NavLink>
                                </li>;
                            }
                            return <li key={i}>
                                <p className='vis-inline'>{item.title}</p>
                            </li>;
                        })}
                    </ul>
                </div>
            }
        </>
    )
}

export default withGlobalState(Breadcrumb)
