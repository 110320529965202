import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'help',
    initialState: {
        helps: []
    },
    reducers: {
        setHelps: (state, payload) => {
            state.helps = payload.payload
        }
    }
});

export const {
    setHelps
} = slice.actions;

export default slice.reducer;
