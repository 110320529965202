import {hasKeys} from "./FuncObjects";
import {ValidationObj} from "../../obj/Validation/Validation.obj";
import {getTrans} from "../../services/ServiceTrans";

/**
 *
 * @param event
 * @param params
 * @returns {{name: *, value: *}}
 */
export function getInputValue(event, params = {}) {
    let name = ''
    let value = ''

    if (hasKeys(params) && event !== null && event.editor !== undefined) {
        name = params
        value = event.editor.getData()
    }else if (hasKeys(params) && params.constructor === Object) {
        name = params.name
        value = params.value
    }else {
        if (typeof event.target !== 'undefined') {
            const type = event.target.getAttribute('type')
            name = event.target.name
            value = (type === 'checkbox')? event.target.checked : event.target.value

            if (type === 'number') value = parseFloat(value)
        }
    }

    return {
        name: name,
        value: value
    }
}

/**
 *
 * @param p
 * @param transState
 * @returns {{validations: *[], text: string, title: string, open: boolean}}
 */
export function getValidationBar(p, transState) {
    let validationObj = ValidationObj
    validationObj = {
        ...validationObj,
        open: true,
        text: (p.text)? p.text : '',
        title: (p.label)? p.label : ''
    }

    let validations = []
    if (p.validation) {
        validations = p.validation[0].map(item => {
            let ret = {
                iconAfter: '',
                iconBefore: '',
                title: '',
                type: (item[0])? item[0] : ''
            }

            if (typeof item === "string") {
                // eslint-disable-next-line default-case
                switch (item) {
                    case 'required': {
                        ret.title = getTrans(transState, 739)
                        break;
                    }
                    case 'email': {
                        ret.title = getTrans(transState, 740)
                        break;
                    }
                    case 'url': {
                        ret.title = getTrans(transState, 741)
                        break;
                    }
                    case 'tel': {
                        ret.title = getTrans(transState, 742)
                        break;
                    }
                }
            } else if (Array.isArray(item) && item.length === 2) {
                // eslint-disable-next-line default-case
                switch (item[0]) {
                    case 'maxLength': {
                        ret.title = item[1]
                        ret.iconBefore = 'left-big'
                        break;
                    }
                    case 'minLength': {
                        ret.title = item[1]
                        ret.iconAfter = 'right-big'
                        break;
                    }
                }
            }
            return ret
        })
    }

    validationObj = {
        ...validationObj,
        validations: validations
    }

    return validationObj
}
