import React, {useEffect, useState} from 'react';
import Page from "./Page";
import {withGlobalState} from "react-globally";
import {apiPut} from "../services/ServiceAPI";
import Infobox from "../components/elements/message/Infobox";
import {hasKeys} from "../components/functions/FuncObjects";
import {buildDateStringNoTime} from "../services/ServiceDate";
import ContentTabs from "../components/widgets/structuring/ContentTabs";
import ComplexBaseDataForm from "../complex/forms/basedata/ComplexBaseDataForm";
import Trans from "../components/elements/trans/Trans";
import {Prompt} from "react-router-dom";
import {getTrans} from "../services/ServiceTrans";
import {useSelector} from "react-redux";

const PageBasedata = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [loading, setLoading] = useState(false);

    /************** global **************/
    const u = p.globalState.user
    const serviceForm = p.globalState.services.form
    const transState = useSelector((state) => state.trans)

    /************** form **************/
    const [validated, setValidated] = useState({})
    const [submit, setSubmit] = useState(false);
    const [changed, setChanged] = useState(false)
    const [saved, setSaved] = useState(true);
    const [validationShow, setValidationShow] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setSubmit(!hasKeys(validated))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setSubmit(!hasKeys(validated))
    }, [changed, validated])

    useEffect(() => {
        window.onbeforeunload = (saved)? () => true : undefined
    }, [saved])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form events **************/
    const handleInputChange = (event, params = {}) => {
        serviceForm.setGlobalUser(p, event, params)
        setChanged(true)
        setSaved(false)
    }

    /************** api events **************/
    const handleFormSubmitMain = (event) => {
        event.preventDefault()
        setSubmit(false)
        setChanged(false)
        setValidationShow(true)

        if (submit) {
            setLoading(true)
            let user = JSON.parse(JSON.stringify(u))
            delete user.timestamp;

            apiPut('profile/userdata', p, user)
                .then(() => {
                    setSaved(true)
                    setLoading(false)
                    p.getUserUpdate(p)
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <>
            <Page
                addClasses={'is--fullwidth'}
                tid='291'
                title={<Trans id={291}/>}
                contentElements={
                    (
                        <div className='c-tab__outer is--fullwidth'>
                            <Prompt
                                tid='878'
                                message={() => getTrans(transState, 878)}
                                when={!saved}
                            />
                            <ContentTabs
                                addClasses={'is--br-none'}
                                body={<ComplexBaseDataForm
                                    handleFormSubmitMain={handleFormSubmitMain}
                                    handleInputChange={handleInputChange}
                                    loading={loading}
                                    setValidated={setValidated}
                                    submit={submit}
                                    validated={validated}
                                    validationShow={validationShow}
                                />}
                                open={true}
                                width={60}
                            />
                            <ContentTabs
                                addClasses={'is--br-none'}
                                body={
                                    <Infobox
                                        addClasses={'c-bg-body is--no-border is--icon-left'}
                                        content={
                                            <>
                                                <span className='p-b-14 vis-block'><Trans id={288}/></span>
                                                <ul className='e-list'>
                                                    <li>
                                                        <b tid='289'>{<Trans id={289}/>}</b> {p.globalState.user.retailerNumber}
                                                    </li>
                                                    <li>
                                                        <b tid='290'>{<Trans id={290}/>}</b> {buildDateStringNoTime(new Date(p.globalState.user.timestamp))}
                                                    </li>
                                                </ul>
                                            </>
                                        }
                                        icon={'info'}
                                        titleTid='288'
                                    />
                                }
                                head={''}
                                open={true}
                                width={40}
                            />
                        </div>
                    )
                }
            />
        </>
    )
}

export default withGlobalState(PageBasedata)