import React, {useEffect, useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import {useSelector} from "react-redux";
import FormInput from "../../../components/elements/form/FormInput";
import Infobox from "../../../components/elements/message/Infobox";
import {getTrans} from "../../../services/ServiceTrans";
import Trans from "../../../components/elements/trans/Trans";

const ComplexCourseProductAddForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const generalState = useSelector((state) => state.general)
    const courseTypeOptions = coursesState.courseTypeOptions
    const transState = useSelector((state) => state.trans)
    const stF = useSelector((s) => s.features)

    /************** form **************/
    const [typesOfSale, setTypesOfSale] = useState([])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setTypesOfSale(stF.features.leads.map((i) => {
            if (i !== undefined) return {
                value: i.identifier,
                label: i.title
            }
            return []
        }))
    }, [stF.features.leads])

    return (
        <form autoComplete='off'>
            <FormRow childs={
                <>
                    <FormInput
                        label={<Trans id={445}/>}
                        max={999}
                        name='contingent'
                        onChange={p.handleNewProductChange}
                        required
                        tid='445'
                        type={'number'}
                        validationChecks={p.inputs.contingent}
                        validationShow={p.validationShow}
                        validationAct={p.addProductValidated}
                        validationSet={p.setAddProductValidated}
                        value={p.newProduct.contingent}
                    />
                </>
            }
            />
            <FormRow childs={
                <>
                    <FormInput
                        label={<Trans id={757}/>}
                        name='totPrice'
                        onChange={p.handleNewProductChange}
                        required
                        step={'0.01'}
                        tid='757'
                        type={'number'}
                        validationChecks={p.inputs.totPrice}
                        validationShow={p.validationShow}
                        validationAct={p.addProductValidated}
                        validationSet={p.setAddProductValidated}
                        value={p.newProduct.totPrice}
                    />
                    <FormInput
                        label={<Trans id={511}/>}
                        name='tax'
                        onChange={p.handleNewProductChange}
                        options={generalState.tax}
                        required
                        tid='511'
                        type={'select'}
                        selected={p.newProduct.tax}
                    />
                </>
            }
            />
            <FormRow childs={
                <>
                    <FormInput
                        label={<Trans id={758}/>}
                        name='eligible'
                        onChange={p.handleNewProductChange}
                        tid='758'
                        type={'switch'}
                        value={p.newProduct.eligible}
                    />
                    <FormInput
                        label={<Trans id={759}/>}
                        name='points'
                        onChange={p.handleNewProductChange}
                        required
                        tid='759'
                        type={'number'}
                        value={p.newProduct.points}
                    />
                </>
            }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={760}/>}
                    name='supportText'
                    onChange={p.handleNewProductChange}
                    tid='760'
                    type={'textarea'}
                    validationChecks={p.inputs.supportText}
                    validationShow={p.validationShow}
                    validationAct={p.addProductValidated}
                    validationSet={p.setAddProductValidated}
                    value={p.newProduct.supportText}
                />
            }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={761}/>}
                    name='productNumberPrivate'
                    onChange={p.handleNewProductChange}
                    tid='761'
                    validationChecks={p.inputs.supportText}
                    validationShow={p.validationShow}
                    validationAct={p.addProductValidated}
                    validationSet={p.setAddProductValidated}
                    value={p.newProduct.productNumberPrivate}
                />
            }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={762}/>}
                    name='courseTypeId'
                    onChange={p.handleNewProductChange}
                    options={courseTypeOptions}
                    required
                    tid='762'
                    type={'select'}
                    selected={p.newProduct.courseTypeId}
                />
            }
            />
            <FormRow childs={
                <FormInput
                    label={'Veranstaltungsart'}
                    options={coursesState.courseKindsOptions}
                    name='courseKindId'
                    onChange={p.handleNewProductChange}
                    required
                    type={'select'}
                    selected={p.newProduct.courseKindId}
                />
            }
            />
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'} tid='763'><Trans id={763}/></h4>
                <div className='p-b-16'>
                    <Infobox
                        addClasses={'is--no-border is--icon-left is--small'}
                        content={getTrans(transState, 532)}
                        html={true}
                        icon={'info'}
                        title={<Trans id={533}/>}
                    />
                </div>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={764}/>}
                        options={typesOfSale}
                        name='typeOfSale'
                        onChange={p.handleNewProductChange}
                        required
                        tid='764'
                        type={'select'}
                        selected={p.newProduct.typeOfSale}
                        validationChecks={p.inputs.typeOfSale}
                        validationShow={p.validationShow}
                        validationAct={p.addProductValidated}
                        validationSet={p.setAddProductValidated}
                    />
                }
                />
            </div>
        </form>
    )
}

export default withGlobalState(ComplexCourseProductAddForm)
