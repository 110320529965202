import React, {useState} from 'react';
import Page from "../Page";
import {withGlobalState} from "react-globally";
import {useDispatch, useSelector} from "react-redux";
import {apiDel, apiPost, apiPut} from "../../services/ServiceAPI";
import {setActCoursePlace} from "../../store/reducer/courseSlice";
import ComplexCoursePlaceList from "../../complex/coursePlaces/ComplexCoursePlaceList";
import AddCoursePlaceLB from "../../complex/lightboxes/coursePlace/AddCoursePlaceLB";
import ComplexCoursePlaceDetail from "../../complex/coursePlaces/ComplexCoursePlaceDetail";
import DeleteCoursePlaceLB from "../../complex/lightboxes/coursePlace/DeleteCoursePlaceLB";
import Trans from "../../components/elements/trans/Trans";

const PageCoursePlace = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [addCoursePlaceLoading, setAddCoursePlaceLoading] = useState(false)
    const [loadingChangePlace, setLoadingChangePlace] = useState(false)
    const [loadingDeletePlace, setLoadingDeletePlace] = useState(false)

    /************** lightbox **************/
    const [addCoursePlaceLB, setAddCoursePlaceLB] = useState(false)
    const [deletePlaceLB, setDeletePlaceLB] = useState(false)

    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const dispatch = useDispatch()

    /************** states **************/
    const [openDetail, setOpenDetail] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** button events **************/
    const addCoursePlaceHandler = () => {
        setAddCoursePlaceLB(true)
    }

    const closeLightbox = () => {
        setAddCoursePlaceLB(false)
        setDeletePlaceLB(false)
    }

    const onItemClick = (id) => {
        getCoursePlaceDetail(id)
    }

    const handleCoursePlaceDeleteLB = () => {
        setDeletePlaceLB(true)
    }

    const handleCoursePlaceDelete = (id) => {
        setLoadingDeletePlace(true)

        apiDel('courses/products/places/' + id, p)
            .finally(() => {
                setLoadingDeletePlace(false)
                setDeletePlaceLB(false)
                setOpenDetail(false)
                p.getCoursePlaceList()
            })
    }

    /************** input events **************/
    const handleLBAddCoursePlaceBtn = (newCoursePlace) => {
        setAddCoursePlaceLoading(true)
        apiPost('courses/products/places', p, newCoursePlace)
            .finally(() => {
                p.getCoursePlaceList()
                setAddCoursePlaceLB(false)
                setAddCoursePlaceLoading(false)
            })
    }

    /************** api events **************/
    const getCoursePlaceDetail = (id) => {
        dispatch(setActCoursePlace(coursesState.coursePlaces.find((place) => {
            return place.id === id
        })))
        setOpenDetail(true)
    }

    const updateCoursePlace = (place) => {
        setLoadingChangePlace(true)
        apiPut('courses/products/places/' + place.id, p, place)
            .finally(() => {
                setLoadingChangePlace(false)
                p.getCoursePlaceList()
            })
    }

    return (
        <Page
            actions={[
                {
                    adClasses: (coursesState.coursePlaces.length === 0)? 'c-bg-pr-2-dark': '',
                    icon: 'plus-circled',
                    onClick: addCoursePlaceHandler,
                    title: <Trans id={522}/>
                }
            ]}
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <>
                        <DeleteCoursePlaceLB
                            closeLightbox={closeLightbox}
                            deletePlaceLB={deletePlaceLB}
                            handleCoursePlaceDelete={handleCoursePlaceDelete}
                            loadingDeletePlace={loadingDeletePlace}
                            place={coursesState.actCoursePlace}
                        />
                        <AddCoursePlaceLB
                            addCoursePlaceLB={addCoursePlaceLB}
                            addCoursePlaceLoading={addCoursePlaceLoading}
                            closeLightbox={closeLightbox}
                            handleLBAddCoursePlaceBtn={handleLBAddCoursePlaceBtn}
                        />
                        <div className='c-tab__outer is--fullwidth'>
                            <ComplexCoursePlaceList
                                addCoursePlaceHandler={addCoursePlaceHandler}
                                coursePlaceLoading={p.coursePlaceLoading}
                                onItemClick={onItemClick}
                            />
                            {coursesState.actCoursePlace.city !== null &&
                                <ComplexCoursePlaceDetail
                                    handleCoursePlaceDeleteLB={handleCoursePlaceDeleteLB}
                                    loadingChangePlace={loadingChangePlace}
                                    openDetail={openDetail}
                                    updateCoursePlace={updateCoursePlace}
                                />
                            }
                        </div>
                    </>
                )
            }
            tid='523'
            title={<Trans id={523}/>}
        />
    )
}

export default withGlobalState(PageCoursePlace)
