import React from 'react';
import Logo from "../components/elements/img/Logo";

class ErrorBoundaryGlobal extends React.Component {
    constructor(p) {
        super(p);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        /*apiPost('test', this.p, {
            name: error.name,
            message: error.message,
            componentStack: info.componentStack
        })*/
    }

    render() {
        if (this.state.hasError) return <>
            <div className='fl-jc-center fl p-t-38'>
                <div>
                    <div className='p-b-16 a-c'>
                        <h1 className='a-c c-pri-2 f-s-18' tid='307'>Sorry.{/*TRANS:HC*/}</h1>
                    </div>
                    <div className='p-b-16 a-c'>
                        <p className='a-c' tid='307'>{/*TRANS:HC*/}Es ist ein Fehler aufgetereten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieten Sie uns unter <a href={'mailto:info@fobimarkt.com'}>info@fobimarkt.com</a>.</p>
                    </div>
                    <div className='p-b-16 a-c'>
                        <p className={'c-text'}>{/*TRANS:HC*/}Zurück zum <a href={'/'}>{/*TRANS:HC*/}Dashboard</a></p>
                    </div>
                    <div className='fl fl-jc-center'>
                        <Logo nopadding/>
                    </div>
                </div>
            </div>
        </>;
        return this.props.children;
    }
}

export default ErrorBoundaryGlobal
