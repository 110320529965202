import React from 'react';
import {withGlobalState} from "react-globally";
import FormLoad from "../../../components/elements/form/FormLoad";
import LB from "../../../components/widgets/message/LB";
import Trans from "../../../components/elements/trans/Trans";
import {useSelector} from "react-redux";
import {getTrans} from "../../../services/ServiceTrans";
import ComplexCourseUnitForm from "../../forms/courseUnit/ComplexCourseUnitForm";

const UnitAddLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** global **************/
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn'
                            disabled={!p.addUnitSubmit}
                            onClick={p.handleLBAddUnitBtn}
                            tid={'612'}
                        ><Trans id={612}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeLightbox}
                            tid={'613'}
                        ><Trans id={613}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <div>
                    <ComplexCourseUnitForm
                        actUnit={p.actUnit}
                        unitValidated={p.addUnitValidated}
                        handleUnitChange={p.handleNewUnitChange}
                        setUnitValidated={p.setAddUnitValidated}
                        validationShowUnitNew={p.validationShowUnitNew}
                    />
                    <FormLoad a={p.loadingAddUnit}/>
                </div>
            }
            onClose={p.closeLightbox}
            open={p.unitAddLB}
            tid={'611'}
            title={getTrans(transState, 611)}
        />
    )
}

export default withGlobalState(UnitAddLB)
