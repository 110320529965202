import React, {useEffect, useRef, useState} from 'react'
import {withGlobalState} from "react-globally";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

const ContentTabs = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const [bodyHeigt, setBodyHeigt] = useState('0px')
    const transState = useSelector((state) => state.trans)

    /************** refs **************/
    const refHeader = useRef(null)

    let head = ''
    let showClass = ''
    let title = getTrans(transState, 738)
    let width = ''

    if (p.width) width = p.width + '%'

    if (!p.open) {
        showClass = 'is--small'
        width = ''
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    useEffect(() => {
        if (refHeader.current) setBodyHeigt((refHeader.current.offsetHeight + 28) + 'px')
    }, [p.title, p.open])

    if (p.title) title = p.title

    if (p.head && (typeof p.open === 'undefined' || p.open)) {
        head = <div className='c-tab__head' ref={refHeader}>
            {p.head}
        </div>;
    }else {
        if (p.onOpen) {
            showClass += ' is--notempty'
            head = <p onClick={p.onOpen}>{title}</p>
        }
    }

    return (
        <div className={`c-tab__holder ${p.addClasses}`} style={{
            width: width
        }}>
            <div className={`c-tab ${showClass}`}>
                {head}
                <div className={`c-tab__content is--${p.innerMargin}`} style={{
                    display: (p.open)? 'block': 'none',
                    paddingTop: bodyHeigt
                }}>
                    {p.body}
                </div>
            </div>
        </div>
    )
}

export default withGlobalState(ContentTabs)
