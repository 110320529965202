import React, {useState} from 'react'
import SidebarLinks from "../widgets/menu/SidebarLinks";
import {NavLink, Route} from "react-router-dom";
import {withGlobalState} from "react-globally";
import Trans from "../elements/trans/Trans";
import {useSelector} from "react-redux";
import {getTrans} from "../../services/ServiceTrans";

const Sidebar = () => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)
    const [openSidebar, setOpenSidebar] = useState(true)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** button events **************/
    const handleSidebarSizeBtn = () => {
        setOpenSidebar(!openSidebar)
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const sidebarClasses = openSidebar? '' : 'is--closed'
    const activeClass = ' c-bg-lightgrey-dark-5 before-c-pr-3'
    const linkClass = 'fl font-ws-nowrap fl-a-items-center p-10 f-w-3 rad-3 hover-c-bg-lightgrey-dark-5 ' + (openSidebar && ' before-m-r-10')
    const linkTextClass = 'c-font' + (!openSidebar? ' vis-none' : '')

    return (
        <div className={`complex-sidebar pos-relative h-100 c-bg-lightgrey position-relative minw-15rem p-tb-16 p-lr-10 empty-vis-none ${!openSidebar && 'minw-inherit'}`}>
            <button
                className={`complex-sidebar-btn zindex-100 c-bg-w ico-left-open transition-de-bg hover-shad-sm ${sidebarClasses}`}
                onClick={handleSidebarSizeBtn}
                tid={'587, 588'}
                title={openSidebar? getTrans(transState, 587) : getTrans(transState, 588)}
            />
            <Route path="/profil">
                <SidebarLinks children={(
                    <>
                        <li>
                            <NavLink
                                activeClassName={activeClass}
                                className={linkClass + ' ico-user'}
                                exact
                                to="/profil"
                            >
                                <span className={linkTextClass} id='309'><Trans id={309}/></span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={activeClass}
                                className={linkClass + ' ico-picture'}
                                to="/profil/galerie"
                            >
                                <span className={linkTextClass} id='310'><Trans id={310}/></span>
                            </NavLink>
                        </li>
                    </>
                )}/>
            </Route>
            <Route path="/stammdaten">
                <SidebarLinks children={(
                    <>
                        <li>
                            <NavLink
                                activeClassName={activeClass}
                                className={linkClass + ' ico-user'}
                                exact
                                to="/stammdaten"
                            >
                                <span className={linkTextClass} id='291'><Trans id={291}/></span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={activeClass}
                                className={linkClass + ' ico-key'}
                                to="/stammdaten/zugangsdaten"
                            >
                                <span className={linkTextClass} id='374'><Trans id={374}/></span>
                            </NavLink>
                        </li>
                    </>
                )}/>
            </Route>
            <Route path="/angebote">
                <SidebarLinks children={(
                    <>
                        <li>
                            <NavLink
                                activeClassName={activeClass}
                                className={linkClass + ' ico-graduation-cap'}
                                exact
                                to="/angebote"
                            >
                                <span className={linkTextClass} id='589'><Trans id={589}/></span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={activeClass}
                                className={linkClass + ' ico-mic'}
                                to="/angebote/speaker"
                            >
                                <span className={linkTextClass} id='529'><Trans id={529}/></span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={activeClass}
                                className={linkClass + ' ico-location'}
                                to="/angebote/orte"
                            >
                                <span className={linkTextClass} id='590'><Trans id={590}/></span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={activeClass}
                                className={linkClass + ' ico-plus'}
                                to="/angebote/zusatzleistungen"
                            >
                                <span className={linkTextClass} id='527'><Trans id={527}/></span>
                            </NavLink>
                        </li>
                    </>
                )}/>
            </Route>
        </div>
    )
}

export default withGlobalState(Sidebar)
