import React from 'react';
import {withGlobalState} from "react-globally";
import FormLoad from "../../../components/elements/form/FormLoad";
import LB from "../../../components/widgets/message/LB";
import ComplexCourseProductAddForm from "../../forms/courseProduct/ComplexCourseProductAddForm";
import Trans from "../../../components/elements/trans/Trans";

const CourseProductAddLB = (p) => {
    return (
        <LB
            addClasses='is--big'
            onClose={p.closeLightbox}
            open={p.productAddLB}
            tid='411'
            title={<Trans id={411}/>}
            content={
                <div>
                    <ComplexCourseProductAddForm
                        addProductValidated={p.addProductValidated}
                        handleNewProductChange={p.handleNewProductChange}
                        inputs={p.inputs}
                        newProduct={p.newProduct}
                        setAddProductValidated={p.setAddProductValidated}
                        validationShow={p.validationShow}
                    />
                    <FormLoad a={p.loadingAddProduct}/>
                </div>
            } actions={
            <>
                <FormLoad
                    a={p.loading}
                    addClasses={'p-b-16'}
                />
                {!p.loading&&
                    <>
                        <li>
                            <button
                                disabled={!p.addProductSubmit}
                                className='e-btn'
                                onClick={p.handleLBAddProductBtn}
                                tid='413'
                            ><Trans id={413}/></button>
                        </li>
                        <li>
                            <button
                                className='e-btn is--orange'
                                onClick={p.closeLightbox}
                                tid='412'
                            ><Trans id={412}/></button>
                        </li>
                    </>
                }
            </>
        }/>
    )
}

export default withGlobalState(CourseProductAddLB)
