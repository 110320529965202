import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'archived',
    initialState: {
        archivedProducts: []
    },
    reducers: {
        setArchivedProducts: (s, p) => {
            s.archivedProducts = p.payload
        }
    }
})

export const {
    setArchivedProducts
} = slice.actions

export default slice.reducer
