import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import FormInput from "../../../components/elements/form/FormInput";
import Trans from "../../../components/elements/trans/Trans";

const ComplexCourseUnitForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const actUnit = p.actUnit
    const valShow = p.validationShowUnitNew || p.validationShowUnitChange

    /************** form **************/
    const [inputs] = useState({
        title: [['required'], [['maxLength', 128]]],
        startDate: [['greaterNow'], [['smallerThanDate', actUnit.endDate]]],
        endDate: [['greaterNow'], [['greaterThanDate', actUnit.startDate]]]
    })

    return (
        <form autoComplete='off'>
            <FormRow childs={
                <FormInput
                    label={<Trans id={793}/>}
                    name='title'
                    onChange={p.handleUnitChange}
                    required
                    tid='793'
                    validationChecks={inputs.title}
                    validationShow={valShow}
                    validationAct={p.unitValidated}
                    validationSet={p.setUnitValidated}
                    value={actUnit.title}
                    visible={true}
                />
            }
            />
            <FormRow childs={
                <>
                    <FormInput
                        label={<Trans id={794}/>}
                        name='startDate'
                        onChange={p.handleUnitChange}
                        tid='794'
                        type={'datetime'}
                        validationChecks={inputs.startDate}
                        validationShow={valShow}
                        validationAct={p.unitValidated}
                        validationSet={p.setUnitValidated}
                        value={actUnit.startDate}
                        visible={true}
                    />
                    <FormInput
                        label={<Trans id={795}/>}
                        name='endDate'
                        onChange={p.handleUnitChange}
                        tid='795'
                        type={'datetime'}
                        validationChecks={inputs.endDate}
                        validationShow={valShow}
                        validationAct={p.unitValidated}
                        validationSet={p.setUnitValidated}
                        value={actUnit.endDate}
                        visible={true}
                    />
                </>
            }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={796}/>}
                    name='shortDesc'
                    onEditorChange={p.handleUnitChange}
                    tid='796'
                    type={'richarea'}
                    value={actUnit.shortDesc}
                    visible={true}
                />
            }
            />
        </form>
    )
}

export default withGlobalState(ComplexCourseUnitForm)
