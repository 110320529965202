import React from 'react'

const FormRow = (p) => {
    return (
        <div className={`e-form__row ${p.adClasses ? p.adClasses : ''}`}>
            {p.childs}
        </div>
    )
}

export default FormRow
