import React, {useEffect, useState} from 'react';
import Page from "../Page";
import {apiGet, apiPost} from "../../services/ServiceAPI";
import {withGlobalState} from "react-globally";
import {hasKeys} from "../../components/functions/FuncObjects";
import Trans from "../../components/elements/trans/Trans";
import ComplexLoginForm from "../../complex/forms/login/ComplexLoginForm";
import {setFeatures} from "../../store/reducer/featuresSlice";
import {useDispatch} from "react-redux";
import {getInputValue} from "../../components/functions/FuncForm";
import {validation} from "../../components/functions/FuncValidation";
import {useLocation} from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PageLogin = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** general **************/
    const _p = p
    const params = useQuery();
    const action = params.get('action')

    /************** loading **************/
    const [loading, setLoading] = useState(false);

    /************** form **************/
    const [validatedLogin, setValidatedLogin] = useState({});
    const [submitLogin, setSubmitLogin] = useState(false)
    const [login, setLogin] = useState({
        username: '',
        accountPassword: ''
    })
    const [message, setMessage] = useState('');
    const [inputs] = useState({
        accountPassword: [['required']],
        username: [['required'], ['email'], [['maxLength', 128]]]
    })
    const [validationShow, setValidationShow] = useState(false)

    /************** global **************/
    const dispatch = useDispatch()

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        let vali = {}
        for (const [key, value] of Object.entries(inputs)) {
            const status = validation(value, login[key], p)
            if (!status.status) vali[key] = status
        }
        setValidatedLogin(vali)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setSubmitLogin(!hasKeys(validatedLogin))
    }, [validatedLogin])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form events **************/
    const handleInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        setLogin({
            ...login,
            [input.name]: input.value
        })
        setSubmitLogin(!hasKeys(validatedLogin))
    }

    /************** api events **************/
    const handleFormSubmitLogin = async (event, p) => {
        event.preventDefault()
        event.stopPropagation()
        setValidationShow(true)

        if (submitLogin) {
            setLoading(true)
            const username = event.target.username.value

            apiPost('account/login', p, {
                username: event.target.username.value,
                password: event.target.accountPassword.value
            }, 'onlyerr').then(() => {
                if (!p.globalState.user.filled) {
                    apiGet('profile/userdata', p).then(r => {
                        p.setGlobalState({
                            user: r.data
                        })
                        setMessage('')
                        p.setGlobalState({
                            auth: {
                                logedin: true
                            }
                        })
                        p.setGlobalState({
                            username: username
                        })

                        if (r.data.allowedFeatures !== undefined) dispatch(setFeatures(r.data.allowedFeatures))

                        setLoading(false)
                        _p.getUserUpdate()
                    })
                }else {
                    p.setGlobalState({
                        auth: {
                            logedin: true
                        }
                    })
                    p.setGlobalState({
                        username: username
                    })

                    setLoading(false)
                }
            }).catch(er => {
                if (typeof er.response !== 'undefined' && er.response.status === 401) setMessage(<Trans id={197}/>)
                setLoading(false)
            })
        }
    }

    return (
        <Page
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <div className='c-c__cols'>
                        <div
                            className='p-70 c-bg-body'
                            style={{width: "50%"}}
                        >
                            <h1 className='p-b-24' tid='650'><Trans id={650}/></h1>
                            <div className='text' tid='202'><Trans id={202} html={true}/></div>
                        </div>
                        <div className='c-box is--cc p-t-70' style={{width: "50%"}}>
                            <div style={{width: "50%"}}>
                                <ComplexLoginForm
                                    action={action}
                                    handleFormSubmitLogin={handleFormSubmitLogin}
                                    handleInputChange={handleInputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    login={login}
                                    message={message}
                                    submitLogin={submitLogin}
                                    validationShow={validationShow}
                                    validationAct={validatedLogin}
                                    validationSet={setValidatedLogin}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
            title=''
        />
    )
}

export default withGlobalState(PageLogin)
