import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import FormLoad from "../../../components/elements/form/FormLoad";
import {useSelector} from "react-redux";
import Trans from "../../../components/elements/trans/Trans";
import FormInput from "../../../components/elements/form/FormInput";

const ComplexCourseDetailForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const actCourse = coursesState.actCourse
    const occupationsOptions = (typeof actCourse.courseOccupations !== 'undefined')? actCourse.courseOccupations.map(o => {return {
        value: o,
        label: o.title
    }}) : []
    const targetsOptions = (typeof actCourse.courseTargets !== 'undefined')? actCourse.courseTargets.map(t => {return {
        value: t,
        label: t.title
    }}) : []
    const servicesOptions = (typeof actCourse.services !== 'undefined')? actCourse.services.map(s => {return {
        value: s,
        label: s.title
    }}) : []

    /************** form **************/
    const [inputs] = useState({
        title: [['required'], [['maxLength', 64]], [['minLength', 10]]],
        titleLong: [[['maxLength', 250]], [['minLength', 10]]],
        descShort: [[['maxLength', 350]]]
    })

    return (
        <form onSubmit={e => p.handleCourseFormSubmit(e)}>
            <h3 className={'c-tab__content-title p-b-16 p-l-16'} tid='746'><Trans id={746}/></h3>
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'} tid='747'><Trans id={747}/></h4>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={405}/>}
                        name='readyToPublished'
                        onChange={p.handleInputChange}
                        tid='405'
                        type={'switch'}
                        value={actCourse.readyToPublished}
                    />
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={397}/>}
                        name='title'
                        onChange={p.handleInputChange}
                        placeholder={<Trans id={404}/>}
                        required
                        text={<Trans id={750}/>}
                        tid='397, 404, 750'
                        showInfo={true}
                        validationChecks={inputs.title}
                        value={actCourse.title}
                        validationShow={p.validationShow}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                    />
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={398}/>}
                        name='titleLong'
                        onChange={p.handleInputChange}
                        placeholder={<Trans id={403}/>}
                        showInfo={true}
                        text={<Trans id={751}/>}
                        tid='398, 403, 751'
                        validationChecks={inputs.titleLong}
                        value={actCourse.titleLong}
                        validationShow={p.validationShow}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                    />
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={399}/>}
                        name='descShort'
                        onChange={p.handleInputChange}
                        placeholder={<Trans id={402}/>}
                        required
                        showInfo={true}
                        tid='399, 402'
                        type={'textarea'}
                        validationChecks={inputs.descShort}
                        value={actCourse.descShort}
                        validationShow={p.validationShow}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                    />
                }
                />
                <FormRow childs={
                    <FormInput
                        data={actCourse.descLong}
                        label={<Trans id={400}/>}
                        name='descLong'
                        onEditorChange={p.handleInputChange}
                        required
                        tid='400'
                        type={'richarea'}
                        value={actCourse.descLong}
                    />
                }
                />
            </div>
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'} tid='748'><Trans id={748}/></h4>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={237}/>}
                        name='courseOccupations'
                        onChange={p.handleInputChange}
                        options={coursesState.occupationsOptions}
                        placeholder={<Trans id={237}/>}
                        tid='237'
                        type={'multiselect-by-objects'}
                        value={occupationsOptions}
                    />
                }/>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={753}/>}
                        name='courseTargets'
                        onChange={p.handleInputChange}
                        options={coursesState.targetsOptions}
                        placeholder={<Trans id={753}/>}
                        tid='753'
                        type={'multiselect-by-objects'}
                        value={targetsOptions}
                    />
                }/>
            </div>
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'} tid='749'><Trans id={749}/></h4>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={754}/>}
                        name='services'
                        onChange={p.handleInputChange}
                        options={coursesState.courseServicesOptions}
                        placeholder={<Trans id={754}/>}
                        showInfo={true}
                        text={<Trans id={752}/>}
                        tid='752, 754'
                        type={'multiselect-by-objects'}
                        value={servicesOptions}
                    />
                }/>
            </div>
            <FormRow childs={
                <>
                    <div className='w-100'>
                        <FormLoad
                            a={p.loadingDetail}
                            addClasses={'p-b-16'}
                        />
                        {!p.loadingDetail &&
                            <button
                                className={`c-bg-pr-3 c-w rad-6 w-100 p-lr-16 p-tb-10 a-c outline outline-w-2 outline-c-pr-3 hover-c-bg-pr-3-darker hover-outline-c-pr-3-darker ${p.loading? 'is--loading' : ''}`}
                                tid='401'
                                title={<Trans id={401}/>}
                                type='submit'
                            >
                                <Trans id={401}/>
                            </button>
                        }
                    </div>
                </>
            }/>
        </form>
    )
}

export default withGlobalState(ComplexCourseDetailForm)
