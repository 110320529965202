export const PatchProductObj = {
    activeUntil: null,
    archived: false,
    azav: '',
    contingent: 0,
    courseCategories: [],
    coursePlaceId: null,
    courseKindId: 1,
    courseTypeId: 1,
    eligible: false,
    enddate: null,
    extendedCost: null,
    imageFilename: null,
    imageTitle: null,
    points: 0,
    productNumberPrivate: '',
    readyToPublished: false,
    startdate: null,
    supportText: '',
    tax: 0,
    timeInfo: '',
    totPrice: 0,
    typeOfSale: 0
}