import React from "react"
import {withGlobalState} from "react-globally";

const tile = (p) => {
    return (
        <div className={`is--grid-${p.width&& p.width} border border-w-1 border-c-pr-4 shad-xsm ${p.addClasses&& p.addClasses}`}>
            {p.title &&
                <p className={`${p.addClassesTitle? p.addClassesTitle : 'f-s-16 f-w-7 p-b-16 child-c-font-darkgrey'}`}>{p.title}</p>
            }
            {p.content &&
                <div className={`${p.addClassesContent? p.addClassesContent : 'child-c-font-darkgrey'}`}>{p.content}</div>
            }
        </div>
    )
}

export default withGlobalState(tile)
