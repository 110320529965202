import React from "react"

const FormGrouping = (p) => {
    return (
        <div className={'e-form__grouping m-b-6'}>
            <p className={'f-s-16 f-w-5 child-c-font-darkgrey p-b-16'}>{p.title}</p>
            {p.children}
        </div>
    )
}

export default FormGrouping
