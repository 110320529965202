import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'general',
    initialState: {
        tax: [
            {value: 0, label: '0%'},
            {value: 19, label: '19%'}
        ],
        publicStatus: true, // false, if retailer profile is not published by iterate status response
        onlyNotActivatedStatus: false // false, if retailer profile is not published only because of no activation by fobimarkt
    },
    reducers: {
        setTax: (s, p) => {
            s.tax = p.payload
        },
        setPublicStatus: (s, p) => {
            s.publicStatus = p.payload
        },
        setOnlyNotActivatedStatus: (s, p) => {
            s.onlyNotActivatedStatus = p.payload
        }
    }
})

export const {
    setTax,
    setPublicStatus,
    setOnlyNotActivatedStatus
} = slice.actions

export default slice.reducer
