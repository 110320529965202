import React from 'react'
import {withGlobalState} from "react-globally";
import Trans from "../../elements/trans/Trans";

const HeaderPublicNav = () => {
    return (
        <div className='h-100'>
            <nav className="h-100">
                <ul className="fl h-100">
                    <li className='h-100'>
                        <a
                            className='before-p-r-6 hover-c-pr-2 fl fl-a-items-center h-100 f-w-4 p-lr-16 transition-de-color'
                            href='https://www.fobimarkt.com'
                            rel='noreferrer'
                            target='_blank'
                            tid='389'
                            title={<Trans id={389}/>}
                        ><Trans id={389}/></a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default withGlobalState(HeaderPublicNav)
