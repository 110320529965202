import React from "react";
import {withGlobalState} from "react-globally";
import {showSearchResult} from "../../../services/ServiceData";

const ListItemCourseService = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const service = p.service
    const q = p.search

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** button events **************/
    const handleListItemClick = (id) => {
        p.onclick(id)
    }

    return (
        <li
            className={`is--noborder ${p.selected}`}
            onClick={e => handleListItemClick(service.id, e)}
        >
            <div className='c-itemlist__content'>
                {service.title &&
                    <div>
                        <p>{showSearchResult(q, service.title, 'f-w-7 c-pri-2-dark')}</p>
                    </div>
                }
            </div>
        </li>
    )
}

export default withGlobalState(ListItemCourseService)
