import React from 'react';
import {withGlobalState} from "react-globally";
import FormLoad from "../../../components/elements/form/FormLoad";
import LB from "../../../components/widgets/message/LB";
import ComplexCourseUnitForm from "../../forms/courseUnit/ComplexCourseUnitForm";
import {useSelector} from "react-redux";
import {getTrans} from "../../../services/ServiceTrans";
import Trans from "../../../components/elements/trans/Trans";

const UnitChangeLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn'
                            disabled={!p.unitSubmit}
                            onClick={p.handleLBChangeUnitBtn}
                            tid={'615'}
                        ><Trans id={615}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeLightbox}
                            tid={'616'}
                        ><Trans id={616}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.handleLBChangeUnitDeleteBtn}
                            tid={'617'}
                        ><Trans id={617}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <div>
                    <ComplexCourseUnitForm
                        actUnit={p.actUnit}
                        unitValidated={p.unitValidated}
                        handleUnitChange={p.handleUnitChange}
                        setUnitValidated={p.setUnitValidated}
                        validationShowUnitChange={p.validationShowUnitChange}
                    />
                    <FormLoad a={p.loadingAddUnit}/>
                </div>
            }
            onClose={p.closeLightbox}
            open={p.unitChangeLB}
            tid={'614'}
            title={getTrans(transState, 614)}
        />
    )
}

export default withGlobalState(UnitChangeLB)
