import React from 'react'
import {withGlobalState} from "react-globally";
import ActionItem from "../elements/menu/ActionItem";
import ActionMenu from "../elements/menu/ActionMenu";

const PageHead = (p) => {
    return (
        <>
            {(p.title !== null && p.title !== '')
                ? <div className='c-c__head'>
                    <div>{p.title ? <h1 className='f-s-h4'>{p.title}</h1> : ''}</div>
                    {p.actions
                        ? <div>
                            <ActionMenu items={p.actions.map((item, i) => {
                                return <ActionItem key={i} item={item}/>
                            })}/>
                        </div>
                        : ''
                    }
                </div>
                : <></>
            }
        </>
    )
}

export default withGlobalState(PageHead)
