import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import {useDispatch, useSelector} from "react-redux";
import {getInputValue} from "../../components/functions/FuncForm";
import {updateActCourseSpeaker} from "../../store/reducer/courseSlice";
import {hasKeys} from "../../components/functions/FuncObjects";
import FormLoad from "../../components/elements/form/FormLoad";
import FormRow from "../../components/elements/form/FormRow";
import ButtonMenu from "../../components/elements/menu/ButtonMenu";
import ComplexCourseSpeakerForm from "../forms/courseSpeaker/ComplexCourseSpeakerForm";
import {getTrans} from "../../services/ServiceTrans";
import Trans from "../../components/elements/trans/Trans";

const ComplexCourseSpeakerDetail = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form **************/
    const [validated, setValidated] = useState({})
    const [courseSpeakerSubmit, setCourseSpeakerSubmit] = useState(false)

    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const transState = useSelector((state) => state.trans)
    const dispatch = useDispatch()
    const speaker = coursesState.actCourseSpeaker

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    useEffect(() => {
        setCourseSpeakerSubmit(!hasKeys(validated))
    }, [validated])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    /* change */
    const handleInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        dispatch(updateActCourseSpeaker({[input.name]: input.value}))
    }

    /* submit */
    const handleCourseSpeakerSaveBtn = () => {
        p.updateCourseSpeaker(speaker, courseSpeakerSubmit)
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const speakerListHead = <div className='c-tab__head-top'>
        <div>
            <p className='c-tab__head-title f-s-16 f-w-7'>{speaker.title
                ? speaker.title
                : <span>{speaker.firstName} {speaker.lastName}</span>
            }</p>
            {speaker.title &&
                <p className='c-tab__head-title f-s-de f-w-5'><span className='f-w-4 c-lightgrey'>{speaker.firstName} {speaker.lastName}</span></p>
            }
        </div>
        <div>
            <nav className='c-tab__head-actions'>
                <ul>
                    <li>
                        <button
                            className='e-btn__action ico-trash'
                            onClick={p.handleCourseSpeakerDeleteLB}
                            tid='731'
                            title={getTrans(transState, 731)}
                        />
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    const speakerListContent = <>
        <ComplexCourseSpeakerForm
            courseSpeaker={speaker}
            handleInputChange={handleInputChange}
            validated={validated}
            setValidated={setValidated}
            validationShowDetail={p.validationShowDetail}
        />
        <FormLoad a={p.loadingChangeSpeaker}/>
        <FormRow childs={
            <ButtonMenu items={[
                <button
                    className='e-btn'
                    disabled={!courseSpeakerSubmit}
                    onClick={handleCourseSpeakerSaveBtn}
                    tid='732'
                    type='submit'
                ><Trans id={732}/></button>
            ]}/>
        }/>
    </>

    return (
        <ContentTabs
            body={speakerListContent}
            head={speakerListHead}
            open={p.openDetail}
            tid='733'
            title={getTrans(transState, 733)}
            width={50}
        />
    )
}

export default withGlobalState(ComplexCourseSpeakerDetail)
