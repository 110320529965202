export const PostProductObj = {
    activeUntil: null,
    azav: null,
    contingent: null,
    coursePlaceId: null,
    courseKindId: 1,
    courseTypeId: 1,
    eligible: false,
    enddate: null,
    extendedCost: null,
    totPrice: null,
    points: null,
    productNumberPrivate: null,
    readyToPublished: false,
    startdate: null,
    supportText: null,
    tax: 0,
    timeInfo: null,
    typeOfSale: null
}