import React from 'react';
import {withGlobalState} from "react-globally";

const ListGroupCourse = (p) => {
    return (
        <div className='c-itemlist__container p-b-6'>
            <div className={'p-b-6 fl fl-jc-sb fl-a-items-center'}>
                <p
                    className='f-s-12 f-w-3 is--c-b-grey'
                    tid={p.tid}
                    title={p.info}
                >{p.status}: <span className={`f-w-5 ${p.colorClass}`}>{p.listItems.length}</span></p>
                <button
                    className={'e-btn is--icon is--no-effects ico-info-circled p-none f-s-10 is--hover-info'}
                    title={p.info}
                />
            </div>
            <div>
                <ul className='c-itemlist'>
                    {p.listItems}
                </ul>
            </div>
        </div>
    )
}

export default withGlobalState(ListGroupCourse)
