export const CourseObj = {
    active: false,
    archived: false,
    courseNumber: '',
    courseOccupations: [],
    courseTargets: [],
    descLong: '',
    descShort: '',
    id: 0,
    imageFilename: null,
    imageTitle: null,
    readyToPublished: false,
    services: [],
    title: '',
    titleAddition: '',
    titleLong: ''
}