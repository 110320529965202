import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import {Route, Switch} from "react-router-dom";
import Content from "../components/layout/Content";
import PageCourses from "./course/PageCourses";
import PageCourseSpeaker from "./course/PageCourseSpeaker";
import PageCoursePlace from "./course/PageCoursePlace";
import {apiGet} from "../services/ServiceAPI";
import {
    setCoursePlaces,
    setCourseServices,
    setCourseKinds,
    setCourseSpeaker,
    setCourseTypes,
    setOccupations,
    setTargets,
    setCategories
} from "../store/reducer/courseSlice";
import {useDispatch, useSelector} from "react-redux";
import FormLoad from "../components/elements/form/FormLoad";
import PageCourseService from "./course/PageCourseServices";

const PageCoursesMain = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [loadingCourseMain, setLoadingCourseMain] = useState(true)
    const [loadPlaces, setLoadPlaces] = useState(false)
    const [loadServices, setLoadServices] = useState(false)
    const [coursePlaceLoading, setCoursePlaceLoading] = useState(false)
    const [courseServiceLoading, setCourseServiceLoading] = useState(false)

    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const dispatch = useDispatch()

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (coursesState.courseTypes.length === 0 || coursesState.courseKinds.length) {
            apiGet('course/types', p)
                .then(r => {
                    setLoadingCourseMain(false)
                    dispatch(setCourseTypes(r.data))
                })
            apiGet('course/kinds', p)
                .then(r => {
                    setLoadingCourseMain(false)
                    dispatch(setCourseKinds(r.data))
                })
        }else {
            setLoadingCourseMain(false)
        }

        if (coursesState.categories.length === 0) {
            apiGet('course/categories', p)
                .then(r => {
                    dispatch(setCategories(r.data))
                })
        }

        if (coursesState.occupations.length === 0) apiGet('courses/occupations', p)
            .then(r => {
                dispatch(setOccupations(r.data))
            })

        if (coursesState.occupations.length === 0) apiGet('courses/targets', p)
            .then(r => {
                dispatch(setTargets(r.data))
            })

        if (coursesState.courseSpeaker.length === 0) apiGet('courses/speaker', p)
            .then(r => {
                dispatch(setCourseSpeaker(r.data))
            })

        if (coursesState.coursePlaces.length === 0 && !loadPlaces) getCoursePlaceList()
        if (coursesState.courseServices.length === 0 && !loadServices) getCourseServiceList()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** api events **************/
    const getCoursePlaceList = (callback = null) => {
        setCoursePlaceLoading(true)
        apiGet('courses/products/places', p)
            .then(r => {
                dispatch(setCoursePlaces(r.data))
                setLoadPlaces(true)
                setCoursePlaceLoading(false)
                if (callback !== null) callback()
            })
    }

    const getCourseServiceList = (callback = null) => {
        setCourseServiceLoading(true)
        apiGet('courses/services', p)
            .then(r => {
                dispatch(setCourseServices(r.data))
                setLoadServices(true)
                setCourseServiceLoading(false)
                if (callback !== null) callback()
            })
    }

    return (
        <>
            {loadingCourseMain
                ? <FormLoad a={true}/>
                : <Switch>
                    <Route path="/angebote/speaker">
                        <Content
                            content={<PageCourseSpeaker/>}
                            sidebar
                        />
                    </Route>
                    <Route path="/angebote/orte">
                        <Content
                            content={<PageCoursePlace
                                coursePlaceLoading={coursePlaceLoading}
                                getCoursePlaceList={getCoursePlaceList}
                            />}
                            sidebar
                        />
                    </Route>
                    <Route path="/angebote/zusatzleistungen">
                        <Content
                            content={<PageCourseService
                                courseServiceLoading={courseServiceLoading}
                                getCourseServiceList={getCourseServiceList}
                            />}
                            sidebar
                        />
                    </Route>
                    <Route path="/angebote">
                        <Content
                            content={<PageCourses
                                getCoursePlaceList={getCoursePlaceList}
                            />}
                            sidebar
                        />
                    </Route>
                </Switch>
            }
        </>
    )
}

export default withGlobalState(PageCoursesMain)
