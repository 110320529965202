import React from 'react'
import Sidebar from "./Sidebar";

const Content = (p) => {
    return (
        <main className={`complex-main fl fl-a-items-stretch w-100 zindex-1 overflow-hidden ${p.addClasses}`}>
            {p.sidebar ? <Sidebar/> : ''}
            <div className='fl fl-direction-col h-100 overflow-hidden w-100 p-lr-10 p-b-10 fl-jc-stretch c-c'>
                {p.content}
            </div>
        </main>
    )
}

export default Content
