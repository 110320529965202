import React, {useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import {useSelector} from "react-redux";
import FormLoad from "../../components/elements/form/FormLoad";
import {getInputValue} from "../../components/functions/FuncForm";
import FormInput from "../../components/elements/form/FormInput";
import {getSearchInString} from "../../services/ServiceData";
import ListItemCourseService from "../../components/widgets/list/ListItemCourseService";
import Trans from "../../components/elements/trans/Trans";
import {getTrans} from "../../services/ServiceTrans";

const ComplexCourseServiceList = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form **************/
    const [searchValue, setSearchValue] = useState('')

    /************** global **************/
    const [openServiceList] = useState(true)
    const coursesState = useSelector(state => state.courses)
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    const onSearch = (event) => {
        setSearchValue(getInputValue(event).value)
    }

    /************** button events **************/
    const onItemClick = (id) => {
        p.onItemClick(id)
    }

    const addCourseServiceHandler = () => {
        p.addCourseServiceHandler()
    }

    /************** general **************/
    const getCourseServiceList = () => {
        return coursesState.courseServices.map((service) => {
            return (
                <>
                    {getSearchInString(searchValue, [
                        service.title
                    ]) &&
                    <ListItemCourseService
                        service={service}
                        key={service.id}
                        onclick={onItemClick}
                        selected={(coursesState.actCourseService.id === service.id)? 'is--selected' : ''}
                        search={searchValue}
                    />
                    }
                </>
            )
        })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const serviceListHead = <>
        <div className='c-tab__head-top'>
            <div>
                <p className='c-tab__head-title' tid='727'><Trans id={727}/></p>
            </div>
            <div>
                <nav className='c-tab__head-actions'>
                    <ul>
                        <li>
                            <button
                                className={`e-btn__action ico-plus-circled ${(coursesState.courseServices.length === 0)? 'c-bg-pr-2-dark': ''}`}
                                onClick={addCourseServiceHandler}
                                tid='728'
                                title={getTrans(transState, 728)}
                            />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className='c-tab__head-bottom'>
            <form autoComplete="off">
                <FormInput
                    addClasses='is--visible-border'
                    addClassesWrap={'p-none'}
                    name='searchList'
                    onChange={onSearch}
                    placeholder={getTrans(transState, 729)}
                    tid='729'
                    type='text'
                    value={searchValue}
                />
            </form>
        </div>
    </>

    const courseServiceList = getCourseServiceList()

    const serviceListContent = <>
        {p.courseServiceLoading
            ? <FormLoad a={true}/>
            : <>
                {courseServiceList.length !== 0 &&
                    <ul className='c-itemlist'>
                        {courseServiceList}
                    </ul>
                }
            </>
        }
    </>

    return (
        <ContentTabs
            body={serviceListContent}
            head={serviceListHead}
            open={openServiceList}
            tid='730'
            title={getTrans(transState, 730)}
            width={50}
        />
    )
}

export default withGlobalState(ComplexCourseServiceList)
