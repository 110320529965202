import React from "react";
import {withGlobalState} from "react-globally";
import {showSearchResult} from "../../../services/ServiceData";

const ListItemCoursePlace = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const place = p.place
    const q = p.search

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** button events **************/
    const handleListItemClick = (id) => {
        p.onclick(id)
    }

    return (
        <li
            className={`is--noborder ${p.selected}`}
            onClick={e => handleListItemClick(place.id, e)}
        >
            <div className='c-itemlist__content'>
                <div>
                    <p>
                        <span>{showSearchResult(q, place.zip, 'f-w-7 c-pri-2-dark')} </span>
                        <span>{showSearchResult(q, place.city, 'f-w-7 c-pri-2-dark')}, </span>
                        <span>{showSearchResult(q, place.street, 'f-w-7 c-pri-2-dark')} </span>
                        <span>{showSearchResult(q, place.houseNumber, 'f-w-7 c-pri-2-dark')}</span>
                    </p>
                </div>
                {place.title &&
                <div>
                    <p>{showSearchResult(q, place.title, 'f-w-7 c-pri-2-dark')}</p>
                </div>
                }
            </div>
        </li>
    )
}

export default withGlobalState(ListItemCoursePlace)
