import React from 'react'

const ActionItem = (p) => {
    const handleClick = (e) => {
        if (typeof p.item.onClick == 'function') p.item.onClick(e)
    }

    if (p.item) {
        const it = p.item
        if (it.icon !== undefined && it.title !== undefined && (it.onClick !== undefined || it.href !== undefined)) {
            let button = <button
                className={`e-btn__action ico-${it.icon} ${it.adClasses}`}
                onClick={e => it.onClick(e)} title={it.title}
            />

            if (it.href !== undefined) {
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                button = <a
                    className={`e-btn__action ico-${it.icon}`}
                    href={it.href}
                    onClick={handleClick}
                    rel={it.rel}
                    target={it.target}
                    tid={it.tid}
                    title={it.title}
                />
            }
            return (<li>{button}</li>)
        }
    }
    return ''
}

export default ActionItem
