import React from 'react';
import {withGlobalState} from "react-globally";
import Trans from "../trans/Trans";

const MobileUnuseable = () => {
    return (
        <div className="c-bg-w flex-align-items-c fl vis-sm-none fl-a-items-center fl-jc-center w-100 h-100 pos-fix pos-fix-topleft zindex-overlay">
            <div className="p-14 a-c">
                <span className="child-c-font f-s-36 ico-desktop"/>
                <div className="p-t-38 child-c-font" tid={'390'}>
                    <Trans id={390} html={true}/>
                </div>
            </div>
        </div>
    )
}

export default withGlobalState(MobileUnuseable)
