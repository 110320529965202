export const OrderObj = {
    addressJson: null,
    accountingStatus: null,
    bruttoAmount: null,
    createdAt: null,
    currency: null,
    id: null,
    isProcessedByRetailer: false,
    orderItems: [],
    status: null,
    tax: null
}