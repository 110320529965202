/**
 *
 * @param kind
 * @param addCl
 * @returns {string}
 * @constructor
 */
export function Btn(kind = '', addCl = '') {
    let retString = addCl
    switch (kind) {
        case 'link-pr-3': {
            retString += ' vis-inline c-bg-trans rad-6 c-pr-3 p-tb-10 p-lr-16 hover-outline hover-outline-c-pr-3 hover-outline-w-2'
            break;
        }
        case 'bg-pri-3': {
            retString += ' vis-inline c-bg-pr-3 c-w hover-c-bg-pr-3-darker m-r-10 rad-6 p-tb-10 p-lr-16 outline outline-w-2 outline-c-pr-3 hover-outline-c-pr-3-darker'
            break;
        }
        default: {
            retString += ' vis-inline c-bg-trans c-pr-3 hover-c-bg-pr-3 hover-c-w m-r-10 rad-6 p-tb-10 p-lr-16 outline outline-w-2 outline-c-pr-3 hover-outline-c-pr-3'
        }
    }

    return retString
}
