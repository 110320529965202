import React from 'react';
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import Infobox from "../../../components/elements/message/Infobox";
import Trans from "../../../components/elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

const CourseProductDeleteLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.handleLBArchiveProductBtn}
                            tid='833'
                        ><Trans id={833}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={p.closeLightbox}
                            tid='834'
                        ><Trans id={834}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <div>
                    <Infobox
                        addClasses={'is--bg-pri6 is--no-border is--icon-left c-w'}
                        content={<Trans id={836}/>}
                        icon={'attention'}
                        tid='836'
                    />
                </div>
            }
            onClose={p.closeLightbox}
            open={p.productDeleteLB}
            tid='835'
            title={getTrans(transState, 835)}
        />
    )
}

export default withGlobalState(CourseProductDeleteLB)
