import React from 'react'
import Trans from "../trans/Trans";

const FormLoad = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** classes **************/
    const cC = p.checked? 'is--checked' : ''

    if (!p.a) return (<></>)
    return (
        <div>
            <div className={`e-loader__outer ${p.addClasses}`}>
                <div className={`e-loader ${p.a? 'is--active' : ''} ${cC}`}>
                    <div className={`e-loader__check ${cC}`}>
                        <span className='e-loader__check is--one'/>
                        <span className='e-loader__check is--two'/>
                    </div>
                </div>
            </div>
            <div className={'p-t-16 a-c'}>
                <p tid={'601'}><Trans id={601}/></p>
            </div>
        </div>
    )
}

export default FormLoad
