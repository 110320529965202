import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Header from "./Header";
import Content from "./Content";
import Popup from 'react-popup';
import PageBasedata from "../../pages/PageBasedata";
import PageOrders from "../../pages/PageOrders";
import PageMessages from "../../pages/PageMessages";
import PageProfile from "../../pages/PageProfile";
import PageStart from "../../pages/PageStart";
import PageLogin from "../../pages/login/PageLogin";
import {apiGet} from "../../services/ServiceAPI";
import PageForgot from "../../pages/login/PageForgot";
import PageRegistration from "../../pages/login/PageRegistration";
import FormLoad from "../elements/form/FormLoad";
import Logo from "../elements/img/Logo";
import PageProfileGallery from "../../pages/profil/PageProfileGallery";
import {transGetAll} from "../../services/ServiceTrans";
import PagePassword from "../../pages/basedata/PagePassword";
import MobileUnuseable from "../elements/message/MobileUnuseable";
import PageCoursesMain from "../../pages/PageCoursesMain";
import {useDispatch} from "react-redux";
import {setTrans} from "../../store/reducer/transSlice";
import {setHelps} from "../../store/reducer/helpSlice";
import Trans from "../elements/trans/Trans";
import PageLeads from "../../pages/PageLeads";
import {setFeatures} from "../../store/reducer/featuresSlice";
import PageInvoice from "../../pages/pricing/PageInvoice";
import PageMailApproving from "../../pages/login/PageMailApproving";
import PageUserdata from "../../pages/login/PageUserdata";
import {setOnlyNotActivatedStatus, setPublicStatus} from "../../store/reducer/generalSlice";

const Application = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [isReload, setReload] = useState(true);
    const [isLoading, setLoading] = useState(true);

    /************** states **************/
    const [isLogin, setLogin] = useState(false);

    /************** global **************/
    const dispatch = useDispatch()

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const doReload = () => {
        if (isReload) {
            setReload(false)
            getUser()
            transGetAll()
                .then((r) => {
                    dispatch(setTrans(r.data))
                })
            apiGet('cms/help', p)
                .then(r => {
                    dispatch(setHelps(r.data))
                })

            getUserUpdate()
        }
    }

    /************** api events **************/
    const getUserUpdate = () => {
        apiGet('profile/score', p)
            .then(r => {
                p.setGlobalState({
                    score: r.data
                })
            })

        apiGet('profile/status', p)
            .then(r => {
                p.setGlobalState({
                    status: r.data
                })
            })
    }

    const getUser = () => {
        apiGet('profile/userdata', p)
            .then(r => {
                p.setGlobalState({
                    user: r.data
                })

                if (r.data.allowedFeatures !== undefined) dispatch(setFeatures(r.data.allowedFeatures))
            })
            .finally(() => {
                setLoading(false);
                setLogin(true);
            })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        doReload()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // checks, if status response contains negative status entry => not a public retailer profile
    useEffect(() => {
        let pS = true
        Object.keys(p.globalState.status).forEach((k) => {
            if (pS) pS = !p.globalState.status[k].status
        })
        dispatch(setPublicStatus(pS))
        p.globalState.status['accountMustActivatedByFobi'] && dispatch(setOnlyNotActivatedStatus(!p.globalState.status['accountMustActivatedByFobi'].status))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.globalState.status])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    let content = <div className='fl-jc-center fl p-t-38'>
        <div>
            <div className='p-b-16 a-c'>
                <h1 className='a-c c-pri-2 f-s-18' tid='307'><Trans id={307}/></h1>
            </div>
            <div className='p-b-16'>
                <FormLoad a={true}/>
            </div>
            <div className='fl fl-jc-center'>
                <Logo nopadding/>
            </div>
        </div>
    </div>


    if (isLogin) {
        content = <>
            <Header nomenu/>
            <Switch>
                <Route path="/login">
                    <Content content={
                        <PageLogin
                            getUserUpdate={getUserUpdate}
                        />
                    }/>
                </Route>
                <Route path="/forgot-password">
                    <Content content={<PageForgot/>}/>
                </Route>
                <Route path="/registration">
                    <Content content={<PageRegistration/>}/>
                </Route>
                <Route path="/registration/mailapproving">
                    <Content content={<PageMailApproving/>}/>
                </Route>
                <Route path="/registration/userdata">
                    <Content content={<PageUserdata
                        getUser={getUser}
                        getUserUpdate={getUserUpdate}
                    />}/>
                </Route>
                <Route path="/">
                    <Content content={<PageLogin getUserUpdate={getUserUpdate}/>}/>
                </Route>
            </Switch>
            <Popup className="c-popup" btnClass="e-btn"/>
        </>
    }

    if (p.globalState.auth.logedin && !isLoading && p.globalState.user.timestamp !== false) {
        if (!p.globalState.user.accountMailApproved && p.globalState.user.timestamp !== false) {
            content = <>
                <Header nomenu/>
                <Content content={<PageMailApproving/>}/>
            </>
        }else if (!p.globalState.user.accountLastName && p.globalState.user.timestamp !== false) {
            content = <>
                <Header nomenu/>
                <Content content={<PageUserdata
                    getUser={getUser}
                    getUserUpdate={getUserUpdate}
                />}/>
            </>
        }else {
            content = <>
                <Header/>
                <Switch>
                    <Route path="/stammdaten/zugangsdaten">
                        <Content
                            content={<PagePassword/>}
                            sidebar
                        />
                    </Route>
                    <Route path="/stammdaten">
                        <Content
                            content={<PageBasedata/>}
                            sidebar
                        />
                    </Route>
                    <Route path="/angebote">
                        <PageCoursesMain/>
                    </Route>
                    <Route path="/bestellungen">
                        <Content
                            content={<PageOrders/>}
                        />
                    </Route>
                    <Route path="/anfragen">
                        <Content
                            content={<PageLeads/>}
                        />
                    </Route>
                    <Route path="/mitteilungen">
                        <Content
                            addClasses={'is--bg-body'}
                            content={<PageMessages/>}
                        />
                    </Route>
                    <Route path="/profil/galerie">
                        <Content
                            content={<PageProfileGallery/>}
                            sidebar
                        />
                    </Route>
                    <Route path="/profil">
                        <Content
                            content={<PageProfile
                                getUserUpdate={getUserUpdate}
                            />}
                            sidebar
                        />
                    </Route>
                    <Route path="/abonnement">
                        <Content
                            addClasses={'is--bg-body'}
                            content={<PageInvoice/>}
                        />
                    </Route>
                    <Route path="/">
                        <Content
                            content={<PageStart/>}
                        />
                    </Route>
                </Switch>
                <Popup
                    btnClass="e-btn"
                    className="c-popup"
                />
            </>
        }
    }

    return (
        <BrowserRouter basename="/">
            {content}
            <MobileUnuseable/>
        </BrowserRouter>
    )
}

export default withGlobalState(Application)
