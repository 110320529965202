import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import Trans from "../../../components/elements/trans/Trans";
import FormInput from "../../../components/elements/form/FormInput";
import FormGrouping from "../../../components/elements/form/FormGrouping";
import FormSubmit from "../../../components/elements/form/FormSubmit";

const ComplexBaseDataForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const handleInputChange = p.handleInputChange
    const validationSet = p.setValidated
    const u = p.globalState.user
    const validationAct = p.validated

    /************** form **************/
    const [inputs] = useState({
        accountProfession: [[['maxLength', 32]]],
        accountFirstName: [['required'], [['maxLength', 64]], [['minLength', 2]]],
        accountLastName: [['required'], [['maxLength', 64]], [['minLength', 2]]],
        companyName: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        companyStreet: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        companyHouseNumber: [['required'], [['maxLength', 30]]],
        companyZip: [['required']],
        companyCity: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        companyWebsite: [['required'], ['url'], [['maxLength', 255]]],
        contactMail: [['email'], [['maxLength', 128]]],
        contactTel: [['tel'], [['maxLength', 128]]],
        companyConditions: [['url'], [['maxLength', 255]]]
    })

    return (
        <form onSubmit={event => p.handleFormSubmitMain(event)}>
            <FormGrouping
                title={<span tid='253'><Trans id={253}/></span>}
            >
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={254}/>}
                            name='accountSalutation'
                            onChange={handleInputChange}
                            options={p.globalState.general.salutations}
                            selected={u.accountSalutation}
                            tid='254'
                            type={'select'}
                        />
                        <FormInput
                            label={<Trans id={256}/>}
                            name='accountProfession'
                            onChange={handleInputChange}
                            placeholder={<Trans id={255}/>}
                            tid='255, 256'
                            value={u.accountProfession}
                            validationChecks={inputs.accountProfession}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                    </>
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={258}/>}
                            name='accountFirstName'
                            onChange={handleInputChange}
                            placeholder={<Trans id={257}/>}
                            required
                            tid='257, 258'
                            validationChecks={inputs.accountFirstName}
                            value={u.accountFirstName}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                        <FormInput
                            label={<Trans id={260}/>}
                            name='accountLastName'
                            onChange={handleInputChange}
                            placeholder={<Trans id={259}/>}
                            required
                            tid='259, 260'
                            validationChecks={inputs.accountLastName}
                            value={u.accountLastName}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                    </>
                }/>
            </FormGrouping>
            <FormGrouping
                title={<span tid='85'><Trans id={285}/></span>}
            >
                <FormRow childs={
                    <FormInput
                        label={<Trans id={262}/>}
                        name='companyName'
                        onChange={handleInputChange}
                        placeholder={<Trans id={261}/>}
                        required
                        tid='261, 262'
                        validationChecks={inputs.companyName}
                        value={u.companyName}
                        validationShow={p.validationShow}
                        validationAct={validationAct}
                        validationSet={validationSet}
                    />
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={264}/>}
                            name='companyStreet'
                            onChange={handleInputChange}
                            placeholder={<Trans id={263}/>}
                            required
                            tid='263, 264'
                            validationChecks={inputs.companyStreet}
                            value={u.companyStreet}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                        <FormInput
                            label={<Trans id={266}/>}
                            name='companyHouseNumber'
                            onChange={handleInputChange}
                            placeholder={<Trans id={265}/>}
                            required
                            tid='265, 266'
                            validationChecks={inputs.companyHouseNumber}
                            value={u.companyHouseNumber}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                    </>
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={268}/>}
                            name='companyZip'
                            onChange={handleInputChange}
                            placeholder={<Trans id={267}/>}
                            required
                            tid='267, 268'
                            validationChecks={inputs.companyZip}
                            value={u.companyZip}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                        <FormInput
                            label={<Trans id={270}/>}
                            name='companyCity'
                            onChange={handleInputChange}
                            placeholder={<Trans id={269}/>}
                            required
                            tid='269, 270'
                            validationChecks={inputs.companyCity}
                            value={u.companyCity}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                    </>
                }/>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={272}/>}
                        name='companyUstid'
                        onChange={handleInputChange}
                        placeholder={<Trans id={271}/>}
                        tid='271, 272'
                        value={u.companyUstid}
                    />
                }/>
            </FormGrouping>
            <FormGrouping
                title={<span tid='286'><Trans id={286}/></span>}
            >
                <FormRow childs={
                    <FormInput
                        label={<Trans id={274}/>}
                        name='companyWebsite'
                        onChange={handleInputChange}
                        placeholder={<Trans id={273}/>}
                        required
                        tid='273, 274'
                        validationChecks={inputs.companyWebsite}
                        value={u.companyWebsite}
                        validationShow={p.validationShow}
                        validationAct={validationAct}
                        validationSet={validationSet}
                    />
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={276}/>}
                            name='contactMail'
                            onChange={handleInputChange}
                            placeholder={<Trans id={275}/>}
                            tid='275, 276'
                            type='email'
                            validationChecks={inputs.contactMail}
                            value={u.contactMail}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                        <FormInput
                            label={<Trans id={278}/>}
                            name='contactTel'
                            onChange={handleInputChange}
                            placeholder={<Trans id={277}/>}
                            tid='277, 278'
                            type='tel'
                            value={u.contactTel}
                            validationChecks={inputs.contactTel}
                            validationShow={p.validationShow}
                            validationAct={validationAct}
                            validationSet={validationSet}
                        />
                    </>
                }/>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={284}/>}
                        name='companyConditions'
                        onChange={handleInputChange}
                        placeholder={<Trans id={283}/>}
                        tid='283, 284'
                        validationChecks={inputs.companyConditions}
                        value={u.companyConditions}
                        validationShow={p.validationShow}
                        validationAct={validationAct}
                        validationSet={validationSet}
                    />
                }/>
            </FormGrouping>
            <FormRow childs={
                <FormSubmit
                    loading={p.loading}
                    tid={287}
                />
            }/>
        </form>
    )
}

export default withGlobalState(ComplexBaseDataForm)
