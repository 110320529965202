import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import FormInput from "../../../components/elements/form/FormInput";
import Trans from "../../../components/elements/trans/Trans";

const ComplexCourseSpeakerForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const addCourseSpeaker = p.courseSpeaker

    /************** form **************/
    const [inputs] = useState({
        title: [[['maxLength', 128]]],
        profession: [[['maxLength', 32]]],
        firstName: [['required'], [['maxLength', 128]], [['minLength', 2]]],
        lastName: [['required'], [['maxLength', 128]], [['minLength', 2]]]
    })

    return (
        <>
            <form autoComplete='off'>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={787}/>}
                        name='title'
                        onChange={p.handleInputChange}
                        tid='787'
                        validationChecks={inputs.title}
                        validationShow={p.validationShowNew || p.validationShowDetail}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                        value={addCourseSpeaker.title}
                        visible={true}
                    />
                }
                />
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={788}/>}
                            name='salutation'
                            onChange={p.handleInputChange}
                            options={p.globalState.general.salutations}
                            selected={addCourseSpeaker.salutation}
                            tid='788'
                            type={'select'}
                        />
                        <FormInput
                            label={<Trans id={789}/>}
                            name='profession'
                            onChange={p.handleInputChange}
                            placeholder={<Trans id={789}/>}
                            tid='789'
                            validationChecks={inputs.title}
                            validationShow={p.validationShowNew || p.validationShowDetail}
                            validationAct={p.validated}
                            validationSet={p.setValidated}
                            value={addCourseSpeaker.profession}
                            visible={true}
                        />
                    </>
                }/>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={790}/>}
                            name='firstName'
                            onChange={p.handleInputChange}
                            placeholder={<Trans id={790}/>}
                            required
                            tid='790'
                            validationChecks={inputs.firstName}
                            validationShow={p.validationShowNew || p.validationShowDetail}
                            validationAct={p.validated}
                            validationSet={p.setValidated}
                            value={addCourseSpeaker.firstName}
                            visible={true}
                            validationOnInit={true}
                        />
                        <FormInput
                            label={<Trans id={791}/>}
                            name='lastName'
                            onChange={p.handleInputChange}
                            placeholder={<Trans id={791}/>}
                            required
                            tid='791'
                            validationChecks={inputs.lastName}
                            validationShow={p.validationShowNew || p.validationShowDetail}
                            validationAct={p.validated}
                            validationSet={p.setValidated}
                            value={addCourseSpeaker.lastName}
                            visible={true}
                            validationOnInit={true}
                        />
                    </>
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={792}/>}
                        name='shortDesc'
                        onChange={p.handleInputChange}
                        placeholder={<Trans id={792}/>}
                        tid='792'
                        type={'textarea'}
                        value={addCourseSpeaker.shortDesc}
                        visible={true}
                    />
                }/>
            </form>
            {p.children}
        </>
    )
}

export default withGlobalState(ComplexCourseSpeakerForm)
