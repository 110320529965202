import React from "react"
import FormLoad from "./FormLoad";
import Trans from "../trans/Trans";

const FormSubmit = (p) => {
    return (<div className='w-100 fl fl-jc-center'>
        <FormLoad
            a={p.loading}
            addClasses={'p-b-16'}
        />
        {!p.loading&&
            <button
                className={`c-bg-pr-3 minw-15rem c-w rad-6 p-lr-16 p-tb-10 a-c outline outline-w-2 outline-c-pr-3 hover-c-bg-pr-3-darker hover-outline-c-pr-3-darker ${p.loading? 'is--loading' : ''}`}
                tid={p.tidTitle? p.tidTitle : p.tid}
                title={<Trans id={p.tidTitle? p.tidTitle : p.tid}/>}
                type='submit'
            >
                {p.text
                    ? <>{p.text}</>
                    : <Trans id={p.tid}/>
                }
            </button>
        }
    </div>)
}

export default FormSubmit
