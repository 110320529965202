import React, {useEffect, useState} from 'react';
import Page from "../Page";
import {withGlobalState} from "react-globally";
import {hasKeys} from "../../components/functions/FuncObjects";
import {getInputValue} from "../../components/functions/FuncForm";
import {apiPut} from "../../services/ServiceAPI";
import ComplexBaseDataRegistrationForm from "../../complex/forms/basedata/ComplexBaseDataRegistrationForm";
import {useSelector} from "react-redux";
import {getTrans} from "../../services/ServiceTrans";
import Trans from "../../components/elements/trans/Trans";

const PageUserdata = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)
    const [userdata, setUserdata] = useState({
        companyName: '',
        companyStreet: '',
        companyHouseNumber: '',
        companyZip: '',
        companyCity: '',
        companyWebsite: '',
        accountSalutation: 'f',
        accountProfession: '',
        accountFirstName: '',
        accountLastName: '',
        contactMail: '',
        contactTel: ''
    })
    const [loadingText, setLoadingText] = useState(getTrans(transState, 888))

    /************** loading **************/
    const [loadingUserdata, setLoadingUserdata] = useState(false)
    const [loadingFinish, setLoadingFinish] = useState(false)
    const loadingTime = 4000

    /************** validation **************/
    const [validatedUserdata, setValidatedUserdata] = useState({})

    /************** form **************/
    const [submitUserdata, setSubmitUserdata] = useState(false)
    const [validationShow, setValidationShow] = useState(false)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setSubmitUserdata(!hasKeys(validatedUserdata))
    }, [validatedUserdata])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const handleUserdataInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        setUserdata({
            ...userdata,
            [input.name]: input.value
        })
        setSubmitUserdata(!hasKeys(validatedUserdata))
    }

    const handleFormSubmitUserdata = async (event) => {
        event.preventDefault()
        setValidationShow(true)

        if (submitUserdata) {
            if (!hasKeys(validatedUserdata)) {
                setSubmitUserdata(true)
                setLoadingUserdata(true)

                apiPut('profile/userdata', p, userdata)
                    .then(() => {
                        setLoadingFinish(true)
                        window.setTimeout(() => {
                            setLoadingText('Jetzt geht es los...')
                            window.setTimeout(() => {
                                p.getUser()
                                p.getUserUpdate()
                            }, loadingTime)
                        }, loadingTime)
                    })
                    .finally(() => {
                        setLoadingUserdata(false)
                    })
            }else {
                setSubmitUserdata(false)
                setLoadingUserdata(false)
            }
        }
    }

    return (
        <Page
            addClasses={'is--fullwidth is--scroll'}
            contentElements={
                (
                    <div className='fl fl-jc-center'>
                        <div className='w-100 mw-45rem rad-32 p-lr-12 p-tb-38'>
                            {loadingFinish
                                ? <div className='complex-load-bg w-100 mh-30rem rad-32 fl fl-a-items-center fl-jc-center'>
                                    <div>
                                        <p className='c-w a-c f-w-7 f-s-24' tid='891'><Trans id={891}/></p>
                                        <p className='c-w a-c f-w-3 f-s-24'>{loadingText}</p>
                                    </div>
                                </div>
                                : <>
                                    <h1 className='f-w-9 f-s-18 c-font-darkgrey a-c' tid='889'><Trans id={889} html={true}/></h1>
                                    <h2 className='f-w-4 a-c f-s-18 c-font-darkgrey p-tb-24' tid='890'><Trans id={890}/></h2>
                                    <ComplexBaseDataRegistrationForm
                                        handleFormSubmit={handleFormSubmitUserdata}
                                        onInputChange={handleUserdataInputChange}
                                        loadingUserdata={loadingUserdata}
                                        user={userdata}
                                        setVal={setValidatedUserdata}
                                        submit={submitUserdata}
                                        validated={validatedUserdata}
                                        validationShow={validationShow}
                                    />
                                </>
                            }
                        </div>
                    </div>
                )
            }
            title=''
        />
    )
}

export default withGlobalState(PageUserdata)
