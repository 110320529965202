import React from 'react';
import Page from "./Page";
import Infobox from "../components/elements/message/Infobox";
import {withGlobalState} from "react-globally";
import {hasKeys} from "../components/functions/FuncObjects";
import {getUserSalutation, getUserSalutationFromString, getUserShort} from "../services/ServiceData";
import Trans from "../components/elements/trans/Trans";
import Tile from "../components/widgets/tiles/tile";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {getTrans} from "../services/ServiceTrans";

const PageStart = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const s = p.globalState.status
    const score = p.globalState.score
    const generalState = useSelector((s) => s.general)
    const publicStatus = generalState.publicStatus
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    let sal = getUserSalutation(p)
    if (sal !== '') sal = <span> {sal}</span>
    let fI = s[0]
    let foundFirstNegative = false // true, if first negative status entry was found
    let fullName = getUserSalutationFromString(p.globalState.user.accountFirstName, p.globalState.user.accountLastName, p.globalState.user.accountSalutation, p.globalState.user.accountProfession)

    return (
        <Page
            contentElements={
                <div className='c-c__cols'>
                    <div className='c-tiles__grid12 is--grid-a-s'>
                        {hasKeys(s) &&
                            <>

                            {/*
                                --------------------- if public profile --------------------------------------------------
                            */}
                                {publicStatus &&
                                    <>
                                        <Tile
                                            addClassesContent={' '}
                                            content={
                                                <div tid='387'>
                                                    <div className='p-b-16'>
                                                        <Trans id={387} html={true}/>
                                                    </div>
                                                    <NavLink
                                                        activeClassName="complex-menu-ul"
                                                        className='vis-inline c-bg-w rad-6 child-c-pr-2-dark p-tb-10 p-lr-16 outline outline-w-2 outline-c-pr-2-dark hover-outline-c-pr-2-dark hover-c-bg-pr-2-dark hover-c-w hover-child-c-w'
                                                        tid={'370, 892'}
                                                        title={getTrans(transState, 892)}
                                                        to="/angebote"
                                                    ><Trans id={892}/><span className="ico-a-right-big p-l-6"/></NavLink>
                                                </div>
                                            }
                                            title={<>
                                                <span
                                                    className='c-pri-2-dark'
                                                    tid='221'
                                                ><Trans id={221}/>{sal},</span>
                                                <br/>
                                                <span tid='388'><Trans id={388}/></span>
                                            </>}
                                            width={8}
                                        />
                                        <Tile
                                            content={<>
                                                <div>
                                                    <p className='c-pri-2'>
                                                        <span className='f-w-7 f-s-18' tid='386'>{score.score}%</span> <Trans tid={386}/>
                                                    </p>
                                                    <div className="e-line is--bg-pri6">
                                                        <span style={{width: `${score.score}%`}}/>
                                                    </div>
                                                </div>
                                                {(score.score === 100) &&
                                                    <ul className='e-list__tiles is--message'>
                                                        <li>
                                                            <div>
                                                                <p className='e-list__title'>
                                                                    <span className='e-list__icon ico-info c-bg-pr-2-dark'/>
                                                                    <span tid='893'><Trans id={893}/></span>
                                                                </p>
                                                                <p className='e-list__text' tid='894'><Trans id={894}/></p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                }
                                                {(hasKeys(score) && score.score !== 0) &&
                                                    <ul className='e-list__tiles is--message'>
                                                        {score.messages.map((i) => {
                                                            if (!i.fulfills) {
                                                                return <li>
                                                                    <div>
                                                                        <p className='e-list__title'>
                                                                            <span className='e-list__icon ico-info is--bg-pri6'/>
                                                                            <span><span className='c-pri-6'>{i.points}%</span> {i.title}</span>
                                                                        </p>
                                                                        <p className='e-list__text'>{i.message}</p>
                                                                    </div>
                                                                </li>
                                                            }
                                                            return ''
                                                        })}
                                                    </ul>
                                                }
                                            </>}
                                            title={<Trans id={385}/>}
                                            width={4}
                                        />
                                    </>
                                }

                                {/*
                                    --------------------- if not public profile --------------------------------------------------
                                */}
                                {!publicStatus &&
                                    <Tile
                                        content={<>
                                            <div>
                                                <p tid='223'><Trans id={223}/></p>
                                                <ul className='e-list__tiles p-t-16'>
                                                    <li className='is--inactive'>
                                                        <div className='e-list__tiles-name' title={fullName}>{getUserShort(p)}</div>
                                                        <p tid='224'><Trans id={224}/></p>
                                                    </li>
                                                    {Object.keys(s).map((k) => {
                                                        const i = s[k]
                                                        if (!foundFirstNegative && i.status) {
                                                            fI = i
                                                            foundFirstNegative = true
                                                        }

                                                        return <li key={i.identifier} className={`${(!i.status)? 'is--inactive' : ((foundFirstNegative && fI === s[k])&& 'is--active')}`}>
                                                            <div className='e-list__tiles-name' title={fullName}>{getUserShort(p)}</div>
                                                            <p>{i.title}</p>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                            <div className='p-t-38'>
                                                <Infobox
                                                    addClasses={'c-bg-w is--no-border is--icon-left'}
                                                    content={fI.detail}
                                                    icon={'info'}
                                                    html={true}
                                                />
                                            </div>
                                        </>}
                                        title={<>
                                            <span
                                                className='c-pri-2-dark'
                                                tid='221'
                                            ><Trans id={221}/>{sal},</span>
                                            <br/>
                                            <span tid='222'><Trans id={222}/></span>
                                        </>}
                                        width={4}
                                    />
                                }
                                <Tile
                                    content={
                                        <div className={'p-b-6'}>
                                            <div className={'fl'}>
                                                <div className='w-8rem'>
                                                    <div className={'ratio-1_1 pos-relative rad-16 overflow-hidden'}>
                                                        <img
                                                            alt={getTrans(transState, 895)}
                                                            className='w-100 h-100 img-object-fit-cover pos-abs pos-abs-topleft'
                                                            src={'https://www.fobimarkt.com/media/cache/resolve/filter_75_120/uploads/media/philipp-60142c2a414d19.03963671.jpg'}
                                                            tid='895'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='p-l-16'>
                                                    <p className='f-w-5 p-b-10' tid='895'><Trans id={895}/></p>
                                                    <p className='p-b-10' tid='896'><Trans id={896}/></p>
                                                    <a
                                                        className={'hover-font-ul'}
                                                        href={'mailto:' + getTrans(transState, 897)}
                                                        tid='897, 898'
                                                        title={getTrans(transState, 898)}
                                                    ><Trans id={897}/></a>
                                                    <br/>
                                                    <a
                                                        className={'hover-font-ul'}
                                                        href={'tel:' + getTrans(transState, 900)}
                                                        tid='899, 900'
                                                        title={getTrans(transState, 899)}
                                                    ><Trans id={900}/></a>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    title={<span tid='643'><Trans id={643}/></span>}
                                    width={4}
                                />
                                <Tile
                                    content={
                                        <div className={'p-b-6'}>
                                            <div className={'fl'}>
                                                <div className='p-l-16'>
                                                    <p className='f-w-5 p-b-10' tid='901'><Trans id={901}/></p>
                                                    <p className='p-b-10' tid='902'><Trans id={902}/></p>
                                                    <p>
                                                        <a
                                                            className='vis-inline c-bg-w rad-6 child-c-pr-2-dark p-tb-10 p-lr-16 outline outline-w-2 outline-c-pr-2-dark hover-outline-c-pr-2-dark hover-c-bg-pr-2-dark hover-c-w hover-child-c-w'
                                                            href='https://www.youtube.com/watch?v=K3iwpD7a2E0&t=7s'
                                                            rel='noopener'
                                                            target='__blank'
                                                            tid={'904'}
                                                            title={getTrans(transState, 904)}
                                                        ><Trans id={904}/><span className="ico-a-right-big p-l-6"/></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    title={<span tid='903'><Trans id={903}/></span>}
                                    width={4}
                                />
                            </>
                        }
                    </div>
                </div>
            }
            tid='217'
            title={<Trans id={217}/>}
        />
    )
}

export default withGlobalState(PageStart)