import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import {useDispatch, useSelector} from "react-redux";
import {updateActCourse} from "../../store/reducer/courseSlice";
import {apiPatch, apiPut} from "../../services/ServiceAPI";
import {getInputValue} from "../../components/functions/FuncForm";
import {hasKeys} from "../../components/functions/FuncObjects";
import FormLoad from "../../components/elements/form/FormLoad";
import ArchiveCourseLB from "../lightboxes/courses/ArchiveCourseLB";
import {buildDateStringWithTime} from "../../services/ServiceDate";
import ComplexCourseDetailForm from "../forms/course/ComplexCourseDetailForm";
import Trans from "../../components/elements/trans/Trans";
import FileUpload from "../../components/elements/form/FileUpload";
import {toBase64} from "../../services/ServiceForm";
import {Prompt} from "react-router-dom";
import {getTrans} from "../../services/ServiceTrans";

const ComplexCourseDetail = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
    ---------------------------------- STATES
    »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [loadingDetail, setLoadingDetail] = useState(false)
    const [loadingCourseImage, setLoadingCourseImage] = useState(false)

    /************** form **************/
    /* states */
    const [submitDetail, setSubmitDetail] = useState(false)
    const [submitCourseImage, setSubmitCourseImage] = useState(false)
    /* validation */
    const [validated, setValidated] = useState({})
    /* saved */
    const [saved, setSaved] = useState(true);
    const [savedImage, setSavedImage] = useState(true);
    const [validationShow, setValidationShow] = useState(false)

    /************** lightbox **************/
    const [courseArchiveLB, setCourseArchiveLB] = useState(false)

    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const transState = useSelector((state) => state.trans)
    const actCourse = coursesState.actCourse
    const dispatch = useDispatch()

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        window.onbeforeunload = (saved && savedImage)? () => true : undefined
    }, [saved, savedImage])

    useEffect(() => {
        setSubmitDetail(!hasKeys(validated))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actCourse])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    /* change */
    const handleInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        dispatch(updateActCourse({[input.name]: input.value}))
        setSubmitDetail(!hasKeys(validated))
    }

    const handleCourseImageChange = () => {
        setSubmitCourseImage(true)
        setSavedImage(false)
    }

    /* submit */
    const handleCourseFormSubmit = (event) => {
        event.preventDefault()
        setValidationShow(true)

        if (submitDetail) {
            setLoadingDetail(true)
            if (!hasKeys(validated)) apiPut('courses/' + actCourse.id, p, actCourse)
                .then(() => {
                    setSaved(true)
                    setLoadingDetail(false)
                    p.getCourseList()
                    p.onCourseItemClick(actCourse.id)
                    setValidationShow(false)
                })
        }
    }

    const handleCourseImageSubmit = async (file) => {
        setLoadingCourseImage(true)
        setSubmitCourseImage(false)

        const base64 = await toBase64(file[0])
        apiPatch('courses/' + actCourse.id, p, {
            image: {
                filename: file[0].name,
                filedata: base64
            }
        })
            .then(() => {
                p.getCourseList()
                p.onCourseItemClick(actCourse.id)
            })
            .finally(() => {
                setSavedImage(true)
                setLoadingCourseImage(false)
            })
    }

    /************** button events **************/
    const handleCourseArchive = () => {
        setCourseArchiveLB(true)
    }

    const closeLightbox = () => {
        setCourseArchiveLB(false)
    }

    const handleLBArchiveCourseBtn = () => {
        p.archiveCourse(actCourse.id)
        setCourseArchiveLB(false)
    }

    const handleCourseChangeAbort = (e) => {
        e.preventDefault()
        p.onCourseItemClick(actCourse.id)
    }

    const handleImageDelete = () => {
        if (window.confirm('Möchten Sie das Titelbild der Weiterbildung "' + actCourse.title + '" wirklich löschen?')) {
            setLoadingCourseImage(true)
            setSubmitCourseImage(false)

            apiPatch('courses/' + actCourse.id, p, {
                image: {
                    filename: null,
                    filedata: null
                }
            })
                .then(() => {
                    p.getCourseList()
                    p.onCourseItemClick(actCourse.id)
                })
                .finally(() => {
                    setLoadingCourseImage(false)
                })
        }
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** general **************/
    let openLink = ''
    if (hasKeys(actCourse.courseStatus) && actCourse.courseStatus.published.status) {
        openLink = <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
                className='e-btn__action ico-globe'
                href='https://fobimarkt.com'
                rel='noreferrer'
                target='_blank'
                tid='710'
                title={getTrans(transState, 710)}
            />
        </li>
    }

    let courseDetailContent = <FormLoad a={true}/>
    if (actCourse.id !== 0) {
        let statusMessages = ''
        const statusTrans = {
            inactive: getTrans(transState, 711),
            activeNotPublished: getTrans(transState, 712)
        }
        if (actCourse.courseStatus) {
            statusMessages = Object.keys(actCourse.courseStatus).map(key => {
                if (actCourse.courseStatus[key].status && actCourse.courseStatus[key].messages.length !== 0) {
                    const content = actCourse.courseStatus[key].messages.map(message => <li>
                        <div>
                            <p className='e-list__title'>
                                <span className='e-list__icon ico-info is--bg-pri6'/>
                                <span>{message.title}</span>
                            </p>
                            <p className='e-list__text'>{message.content}</p>
                        </div>
                    </li>)
                    return <div>
                        <h3 className={'f-s-de f-w-7'}>Status</h3>
                        <p className={'f-w-5 f-s-de c-status-warn p-b-6'}>{statusTrans[key]}</p>
                        <ul className='e-list__tiles is--message'>
                            {content}
                        </ul>
                    </div>
                }
                return ''
            })
        }

        courseDetailContent =
            <>
                <Prompt
                    when={!saved || !savedImage}
                    message={() => getTrans(transState, 713)}
                />
                {statusMessages}
                <ComplexCourseDetailForm
                    handleCourseChangeAbort={handleCourseChangeAbort}
                    handleCourseFormSubmit={handleCourseFormSubmit}
                    handleInputChange={handleInputChange}
                    loadingDetail={loadingDetail}
                    setValidated={setValidated}
                    submitDetail={submitDetail}
                    validated={validated}
                    validationShow={validationShow}
                />
                <div className='e-form__grouping m-t-16'>
                    <h4 className='e-form__grouping-title' tid='714'><Trans id={714}/></h4>
                    <FileUpload
                        addActions={<>
                            <button
                                className='e-btn is--orange m-l-6'
                                onClick={handleImageDelete}
                            >Löschen</button>
                        </>}
                        label={'Vorschaubild'}
                        onChange={handleCourseImageChange}
                        required
                        submit={submitCourseImage}
                        tid='235'
                        upload={handleCourseImageSubmit}
                        value={actCourse.imageFilename}
                    />
                    <FormLoad a={loadingCourseImage}/>
                </div>
                <div className={'p-t-16 is--brt m-t-16'}>
                    <p className={'e-def'}>
                        <span tid='715'><Trans id={715}/>:</span>
                        <span>{actCourse.courseNumber}</span>
                    </p>
                    <p className={'e-def'}>
                        <span tid='716'><Trans id={716}/>:</span>
                        <span tid={'495'}>{buildDateStringWithTime(new Date(actCourse.creation))} <Trans id={495}/></span>
                    </p>
                </div>
            </>;
    }

    if (p.loadingCourse) courseDetailContent = <FormLoad a={true}/>

    return (
        <>
            <ContentTabs
                body={courseDetailContent}
                head={<div className='c-tab__head-top'>
                    <div>
                        <p
                            className='c-tab__head-title'
                            tid='396'
                        >{actCourse.title}</p>
                        <p className='c-tab__head-title is--c-f-bgd'><Trans id={396}/> <span className='f-w-4 is--c-f-bgd'>{actCourse.courseNumber}</span></p>
                    </div>
                    <div>
                        <nav className='c-tab__head-actions'>
                            <ul>
                                <li>
                                    <button
                                        className='e-btn__action ico-eye-off'
                                        onClick={handleCourseArchive}
                                        tid='717'
                                        title={getTrans(transState, 717)}
                                    />
                                </li>
                                {openLink}
                            </ul>
                        </nav>
                    </div>
                </div>}
                innerMargin='big'
                onOpen={(actCourse.id !== 0)? p.onTabOpen : false}
                open={p.openCourseDetail}
                tid='718'
                title={getTrans(transState, 718)}
                width={p.width}
            />
            <ArchiveCourseLB
                closeLightbox={closeLightbox}
                courseArchiveLB={courseArchiveLB}
                handleCourseChangeAbort={handleCourseChangeAbort}
                handleInputChange={handleInputChange}
                handleLBArchiveCourseBtn={handleLBArchiveCourseBtn}
                loadingDetail={loadingDetail}
                setValidated={setValidated}
                submitDetail={submitDetail}
                validated={validated}
            />
        </>
    )
}

export default withGlobalState(ComplexCourseDetail)
