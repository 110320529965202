import {withGlobalState} from "react-globally";
import Page from "../Page";
import React, {useEffect, useState} from "react";
import {apiDel, apiGet, apiPost} from "../../services/ServiceAPI";
import FileUpload from "../../components/elements/form/FileUpload";
import FormLoad from "../../components/elements/form/FormLoad";
import {toBase64} from "../../services/ServiceForm";
import update from "react-addons-update";
import LB from "../../components/widgets/message/LB";
import Trans from "../../components/elements/trans/Trans";
import {Prompt} from "react-router-dom";
import FormGrouping from "../../components/elements/form/FormGrouping";
import {getTrans} from "../../services/ServiceTrans";
import {useSelector} from "react-redux";

const PageProfileGallery = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    /************** loading **************/
    const [loadingImage, setLoadingImage] = useState(false)

    /************** form **************/
    const [submitImage, setSubmitImage] = useState(false)
    const [deleteLighbox, setDeleteLighbox] = useState(false)
    const [deleteImageItem, setDeleteImageItem] = useState({})
    const [saved, setSaved] = useState(true);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (!p.globalState.gallery.loaded) {
            apiGet('profile/gallery', p)
                .then((r) => {
                    p.setGlobalState(update(p.globalState, {
                        gallery: {
                            data: {
                                $set: r.data
                            },
                            loaded: {
                                $set: true
                            }
                        }
                    }))
                })
        }
    }, [p, p.globalState.gallery.length])

    useEffect(() => {
        setSaved(submitImage === false)
    }, [submitImage])

    useEffect(() => {
        window.onbeforeunload = (saved)? () => true : undefined
    }, [saved])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const deleteImage = (e, i) => {
        setDeleteImageItem(i)
        setDeleteLighbox(true)
    }

    const handleImageDelete = () => {
        apiDel('profile/gallery/' + deleteImageItem.id, p)
            .then(() => {
                apiGet('profile/gallery', p)
                    .then(r => {
                        setDeleteLighbox(false)
                        p.setGlobalState(update(p.globalState, {
                            gallery: {
                                data: {
                                    $set: r.data
                                }
                            }
                        }))
                    })
            })
    }

    const handleLightboxClose = () => {
        setDeleteLighbox(false)
    }

    const handleFormSubmitImage = async (f) => {
        setLoadingImage(true)
        setSubmitImage(false)

        const base64 = await toBase64(f[0])
        apiPost('profile/gallery', p, {
            file: {
                filename: f[0].name,
                filedata: base64
            },
            title: ''
        })
            .then(() => {
                apiGet('profile/gallery', p)
                    .then(r => {
                        p.setGlobalState(update(p.globalState, {
                            gallery: {
                                data: {
                                    $set: r.data
                                }
                            }
                        }))
                        setLoadingImage(false)
                    })
            })
    }

    const handleImageChange = () => {
        setSubmitImage(true)
    }

    return (
        <Page
            tid='295'
            title={<Trans id={295}/>}
            contentElements={
                (
                    <>
                        <Prompt
                            when={!saved}
                            message={() => getTrans(transState, 880)}
                        />
                        <FormGrouping
                            title={<span tid='297'><Trans id={297}/></span>}
                        >
                            <FileUpload
                                label={<Trans id={296}/>}
                                onChange={handleImageChange}
                                required
                                submit={submitImage}
                                upload={handleFormSubmitImage}
                                tid='296'
                            />
                            <FormLoad a={loadingImage}/>
                            <ul className='e-form__fileupload-images'>
                                {p.globalState.gallery.data.map((i) => {
                                    return <li onClick={(e) => deleteImage(e, i)}>
                                        <img
                                            alt={<Trans id={294}/>}
                                            className='e-form__fileupload-image'
                                            src={`${process.env.REACT_APP_ROOT_URL}/uploads/${i.filename}`}
                                            tid='294'
                                        />
                                    </li>
                                })}
                            </ul>
                        </FormGrouping>
                        <LB
                            actions={
                                <>
                                    <li>
                                        <button
                                            className='e-btn'
                                            onClick={handleImageDelete}
                                            tid='300'
                                        ><Trans id={300}/></button>
                                    </li>
                                    <li>
                                        <button
                                            className='e-btn__text'
                                            onClick={handleLightboxClose}
                                            tid='301'
                                        ><Trans id={301}/></button>
                                    </li>
                                </>
                            }
                            content={
                                <div>
                                    <p tid='299'><Trans id={299}/></p><br/>
                                    <img
                                        alt={<Trans id={294}/>}
                                        className='e-form__fileupload-image'
                                        src={`${process.env.REACT_APP_ROOT_URL}/uploads/${deleteImageItem.filename}`}
                                    />
                                </div>
                            }
                            open={deleteLighbox}
                            tid='298'
                            title={<Trans id={298}/>}
                        />
                    </>
                )
            }
        />
    )
}

export default withGlobalState(PageProfileGallery)
