import React from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import FormLoad from "../../../components/elements/form/FormLoad";
import Trans from "../../../components/elements/trans/Trans";
import FormInput from "../../../components/elements/form/FormInput";
import FormGrouping from "../../../components/elements/form/FormGrouping";

const ComplexProfileRelationsForm = (p) => {
    return (
        <form onSubmit={event => p.handleFormRelations(event)}>
            <FormGrouping
                title={<span tid='237'><Trans id={237}/></span>}
            >
                <FormRow childs={
                    <FormInput
                        label={<Trans id={239}/>}
                        name='occupations'
                        onChange={p.handleOccupationsChange}
                        options={p.allOccupationsOptions}
                        placeholder={<Trans id={383}/>}
                        tid='239, 383'
                        type={'multiselect'}
                        value={p.occupationsOptions}
                    />
                }/>
                <p className={'f-s-16 f-w-5 child-c-font-darkgrey p-b-16'} tid='238'><Trans id={238}/></p>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={240}/>}
                        name='tags'
                        onChange={p.handleTagsChange}
                        options={p.availableTags}
                        placeholder={<Trans id={384}/>}
                        tid='240, 384'
                        type={'multiselect-create'}
                        value={p.tagOptions}
                    />
                }/>
                <FormLoad a={p.loadingRelations}/>
                <FormRow childs={
                    <button
                        className='e-btn'
                        disabled={!p.submitRelations}
                        tid='241'
                        type='submit'
                    ><Trans id={241}/></button>
                }/>
            </FormGrouping>
        </form>
    )
}

export default withGlobalState(ComplexProfileRelationsForm)
