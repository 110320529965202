import * as zxcvbn from 'zxcvbn';
import {getDateString, isValidDate} from "../../services/ServiceDate";
import Trans from "../elements/trans/Trans";
import React from "react";

const val = {
    /**
     *
     * @param p
     * @param t
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    required: function(p, t) {
        if (typeof t === 'string' && t.toString().length !== 0) return {status: true}
        if (typeof t === 'boolean' && t === true) return {status: true}
        if (typeof t === 'number') {
            if (!isNaN(t)) return {status: true}
        }

        return {
            status: false,
            msg: <span tid='313'><Trans id={313}/></span>
        };
    },

    /**
     *
     * @param p
     * @param t
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    email: function(p, t) {
        if (t !== null) {
            const regex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (regex.test(t) || t.length === 0) return {status: true}
        }

        return {
            status: false,
            msg: <span tid='314'><Trans id={314}/></span>
        };
    },

    /**
     *
     * @param p
     * @param t
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    url: function(p, t) {
        if (t !== null) {
            const regex = RegExp(/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi);
            if (regex.test(t) || t.length === 0) return {status: true}
        }

        return {
            status: false,
            msg: <span tid='315'><Trans id={315}/></span>
        };
    },

    /**
     *
     * @param t
     * @returns {{status: boolean}}
     */
    tel: function(p, t) {
        return {status: true}
    },

    /**
     *
     * @param p
     * @param t
     * @param maxLength
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    maxLength: function(p, t, maxLength) {
        if (t === null || t.length <= maxLength) return {status: true}
        return {
            status: false,
            msg: <span tid='318'><Trans id={318} param={{maxLength: maxLength}}/></span>
        };
    },

    /**
     *
     * @param p
     * @param t
     * @param minLength
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    minLength: function(p, t, minLength) {
        if (t !== null && (t.length >= minLength || t.length === 0)) return {status: true}
        return {
            status: false,
            msg: <span tid='317'><Trans id={317} param={{minLength: minLength}}/></span>
        };
    },

    /**
     *
     * @param p
     * @param t
     * @param length
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    length: function(p, t, length) {
        if (t !== null && t.length === length) return {status: true}
        return {
            status: false,
            msg: <span tid='319'><Trans id={319} param={{length: length}}/></span>
        };
    },

    /**
     *
     * @param p
     * @param t
     * @param strength
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    passwordStrength: function(p, t, strength = 3) {
        if (t && t.length !== 0) {
            const r = zxcvbn(t);
            if (r.score >= strength) return {status: true}
        }
        return {
            status: false,
            msg: <span tid='316'><Trans id={316}/></span>
        };
    },

    /**
     *
     * @param p
     * @param t
     * @param obj
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    passwordEqual: function(p, t, obj) {
        if (t === obj) return {status: true}
        return {
            status: false,
            msg: <span tid='320'><Trans id={320}/></span>
        };
    },

    /**
     *
     * @param p
     * @param t
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    greaterNow: function(p, t) {
        if (t !== null  && t !== '') {
            const date = new Date(t)
            if (isValidDate(date)) {
                if (date > new Date()) return {status: true}
                return {
                    status: false,
                    msg: <span tid='591'><Trans id={591}/></span>
                }
            }
        }
        return {status: true}
    },

    /**
     *
     * @param p
     * @param t
     * @param obj
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    smallerThanDate: function(p, t, obj) {
        if (t !== null  && t !== '') {
            if (obj !== null) {
                const date1 = new Date(t)
                const date2 = new Date(obj)
                if (isValidDate(date1)) {
                    if (isValidDate(date2)) {
                        if (date1 < date2) return {status: true}
                        return {
                            status: false,
                            msg: <span tid='592'><Trans id={592} param={{date: getDateString(date2)}}/></span>
                        }
                    }
                    return {status: true}
                }
            }
        }
        return {status: true}
    },

    /**
     *
     * @param p
     * @param t
     * @param obj
     * @returns {{msg: string, status: boolean}|{status: boolean}}
     */
    greaterThanDate: function(p, t, obj) {
        if (t !== null  && t !== '') {
            if (obj !== null) {
                const date1 = new Date(t)
                const date2 = new Date(obj)
                if (isValidDate(date1)) {
                    if (isValidDate(date2)) {
                        if (date1 > date2) return {status: true}
                        return {
                            status: false,
                            msg: <span tid='593'><Trans id={593} param={{date: getDateString(date2)}}/></span>
                        }
                    }
                    return {status: true}
                }
            }
        }
        return {status: true}
    }
}

/**
 *
 * @param v
 * @param obj
 * @param p
 */
export function validation(v, obj, p) {
    let msg = ''
    let status = true

    if (Array.isArray(v)) {
        v.forEach((item) => {
            item = item[0]
            if (status) {
                if (typeof item === "string") {
                    if (typeof val[item] !== "undefined") {
                        const r = val[item](p, obj)
                        status = r.status
                        if (typeof r.msg !== "undefined") msg = r.msg
                    }
                } else if (Array.isArray(item) && item.length === 2) {
                    if (typeof val[item[0]] !== "undefined") {
                        const r = val[item[0]](p, obj, item[1])
                        status = r.status
                        if (typeof r.msg !== "undefined") msg = r.msg
                    }
                }
            }
        })
    }

    return {
        status: status,
        msg: msg
    }
}

export function setValiOfInput(status, validationAct, validationSet, name, setErrorMessage, setErrorLine = null) {
    // if validation error exists
    if (!status.status) {
        if (validationAct !== undefined && validationSet !== undefined) {
            validationSet({
                ...validationAct,
                [name]: status
            })

            setErrorMessage(
                <div className='p-t-6'>
                    <p className='f-s-de child-c-stat-er'>{status.msg}</p>
                </div>
            )

            if (setErrorLine !== null) setErrorLine('is--error')
        }
    }else {
        // if validation error exists
        if (validationAct !== undefined && validationSet !== undefined) {
            setErrorMessage('')
            let validations = JSON.parse(JSON.stringify(validationAct))
            delete validations[name]
            validationSet(validations)
            if (setErrorLine !== null) setErrorLine('')
        }
    }
}

/**
 *
 * @param p
 * @param status
 * @param focused
 * @param firstVali
 * @param notFirst
 * @param onlyline
 * @param setErrror
 * @param setErrorLine
 */
export function setValidationOfInput(p, status, focused, firstVali, notFirst, onlyline, setErrror, setErrorLine) {
    if (p.onValidation) p.onValidation()
    if (!status.status) {
        if ((!p.onlyFocusValidation || focused)) {
            setErrorLine('')
            if (!(firstVali && notFirst)) {
                setErrorLine('is--error')
                if (!onlyline) setErrror((
                    <div className='e-form__error'>
                        <p className='c-w f-s-12'>{status.msg}</p>
                    </div>
                ))
            }
        }

        if (p.validation[1] !== undefined && p.validation[2] !== undefined) {
            p.validation[2]({
                ...p.validation[1],
                [p.name]: status
            })
        }
    }else {
        setErrror('')
        if (!onlyline) setErrorLine('is--ok')

        if (p.validation[1] !== undefined && p.validation[2] !== undefined) {
            let validations = JSON.parse(JSON.stringify(p.validation[1]))
            delete validations[p.name]
            p.validation[2](validations)
        }
    }
}

/**
 *
 * @param p
 * @param setNotFirst
 * @param loaded
 * @param setLoaded
 * @param setValidation
 */
export function onEventActions(p, setNotFirst, loaded, setLoaded, setValidation) {
    if (p.validation) {
        if (p.validation.hasOwnProperty(3) !== undefined) setNotFirst(p.validation[3] !== undefined && p.validation[3])

        if (p.validation[0] && !loaded && p.value !== undefined) {
            setLoaded(true)
            setValidation(validation(p.validation[0], p.value), true, p)
        }
    }
}

/**
 *
 * @param form
 */
export function validateForm(form) {
    const inputs = form.getElementsByTagName('input')
    inputs.forEach(() => {})
}
