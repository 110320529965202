import React from "react";
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import {getUserSalutationFromString} from "../../../services/ServiceData";
import Trans from "../../../components/elements/trans/Trans";

const LeadDetailLB = (p) => {
    if (p.actLead !== null) {
        const l = p.actLead.row.original
        const adRaw = JSON.parse(l.addressJson)
        const ad = adRaw.shippingAddress

        return (
            <LB
                actions={
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeLightbox}
                            tid='503'
                        ><Trans id={503}/></button>
                    </li>
                }
                addClasses='is--big'
                content={
                    <div>
                        <table className='e-table'>
                            <tr>
                                <th tid='499'><Trans id={499}/></th>
                                <th tid='498'><Trans id={498}/></th>
                                <th tid='494'><Trans id={494}/></th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{getUserSalutationFromString(ad.firstname, ad.lastname, ad.salutation, ad.title)}</p>
                                    <p>{ad.street} {ad.housenumber}</p>
                                    {ad.additionalInformation &&
                                    <p>{ad.additionalInformation}</p>
                                    }
                                    <p>{ad.zip} {ad.city}</p>
                                    <p>{ad.country}</p>
                                    <p>{adRaw.customerEmail}</p>
                                </td>
                                <td>
                                    <p><span className='f-w-5' tid='492'><Trans id={492}/>:</span> {l.courseProductNumber}</p>
                                    <p><span className='f-w-5' tid='493'><Trans id={493}/>:</span> {l.courseProductNumberPrivate}</p>
                                </td>
                                <td>
                                    <p><span className='f-w-5' tid='494'><Trans id={494}/>:</span> <span className={l.isProcessedByRetailer? 'c-pri-3' : 'c-status-warn'}>{l.status}</span></p>
                                    <div className='m-t-6'>
                                        {l.isProcessedByRetailer
                                            ? <button
                                                className='e-btn is--orange'
                                                onClick={() => p.setCompleted(false, l)}
                                                tid='501'
                                            ><Trans id={501}/></button>
                                            : <button
                                                className='e-btn'
                                                onClick={() => p.setCompleted(true, l)}
                                                tid='502'
                                            ><Trans id={502}/></button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                }
                onClose={p.closeLightbox}
                open={p.leadDetailLB}
                title={<Trans id={500}/>}
            />
        )
    }else {
        return ('')
    }
}

export default withGlobalState(LeadDetailLB)
