import React from 'react';
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import Infobox from "../../../components/elements/message/Infobox";
import FormRow from "../../../components/elements/form/FormRow";
import {useSelector} from "react-redux";
import ComplexCoursePlaceAddForm from "../../forms/coursePlace/ComplexCoursePlaceAddForm";
import FormInput from "../../../components/elements/form/FormInput";
import Trans from "../../../components/elements/trans/Trans";

const CourseProductPlaceLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const place = p.place

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    const handleSelectChange = (e, params) => {
        p.handleCoursePlaceSelectChange(params.value)
    }

    /************** button events **************/
    const handleSavePlace = () => {
        p.handleSavePlace()
    }

    return (
        <LB
            actions={
                <>
                    <li>
                        <button
                            className='e-btn is--orange'
                            onClick={p.closeLightbox}
                            tid='807'
                        ><Trans id={807}/></button>
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            disabled={!p.placeSubmit}
                            onClick={handleSavePlace}
                            tid='806'
                        ><Trans id={806}/></button>
                    </li>
                </>
            }
            addClasses='is--big is--fullwidth-lb'
            content={
                <>
                    <ul className='fl'>
                        <li className='p-lr-16 border border-w-r-1 border-c-lightgrey m-r-16'>
                            <h3 className={'c-pri-2-dark'} tid='818'><Trans id={818}/></h3>
                            <p className={'f-s-de f-s-16'} tid='823'><Trans id={823}/></p>
                            <div className={'p-t-16'}>
                                <ul>
                                    <li>
                                        <button
                                            className={`e-btn__bigaction ${p.placePage === 'place' ? 'is--active' : ''}`}
                                            onClick={() => p.handleChangePagePlace('place')}
                                            tid='821'
                                        ><Trans id={821}/></button>
                                    </li>
                                    <li className={'p-t-6'}>
                                        <button
                                            className={`e-btn__bigaction ${p.placePage === 'noplace' ? 'is--active' : ''}`}
                                            onClick={() => p.handleChangePagePlace('noplace')}
                                            tid='822'
                                        ><Trans id={822}/></button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className='p-lr-16 border border-w-r-1 border-c-lightgrey m-r-16'>
                            <h3 className={'c-pri-2-dark'} tid='819'><Trans id={819}/></h3>
                            {p.placePage === 'place'
                                ? <div>
                                    <p className={'f-s-de f-s-16'} tid='810'><Trans id={810}/></p>
                                    <div className={'p-t-16'}>
                                        <div className={'p-t-16'}>
                                            <h4 className={'p-b-6'} tid='812'><Trans id={812}/></h4>
                                            <FormRow childs={
                                                <FormInput
                                                    label={<Trans id={813}/>}
                                                    name=''
                                                    onChange={handleSelectChange}
                                                    options={coursesState.coursePlacesOptions}
                                                    selected={p.selectedCoursePlaceId}
                                                    tid='813'
                                                    type={'select'}
                                                />
                                            }/>
                                        </div>
                                    </div>
                                    <p className={'f-s-de f-s-16'} tid='811'><Trans id={811}/></p>
                                    <div className={'p-t-16'}>
                                        <ul>
                                            <li>
                                                <button
                                                    className={`e-btn__bigaction ${p.placePageData === 'new' ? 'is--active' : ''}`}
                                                    onClick={() => p.handleChangePagePlaceData('new')}
                                                    tid='814'
                                                ><Trans id={814}/></button>
                                            </li>
                                            <li className={'p-t-6'}>
                                                <button
                                                    className={`e-btn__bigaction ${p.placePageData === 'select' ? 'is--active' : ''}`}
                                                    onClick={() => p.handleChangePagePlaceData('select')}
                                                    tid='815'
                                                ><Trans id={815}/></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                : <Infobox
                                    addClasses={'is--info-warning is--no-border is--icon-left m-t-16'}
                                    content={<Trans html={true} id={817}/>}
                                    icon={'attention'}
                                    tid='816'
                                    title={<Trans id={816}/>}
                                />
                            }
                        </li>
                        {(p.placePage === 'place' && p.placePageData === 'new') &&
                        <li className='p-lr-16'>
                            <h3 className={'c-pri-2-dark'} tid='820'><Trans id={820}/></h3>
                            <p className={'f-s-de f-s-16'} tid='809'><Trans id={809}/></p>
                            <div className={'p-t-16'}>
                                <ComplexCoursePlaceAddForm
                                    handleInputChangePlace={p.handleInputChangePlace}
                                    place={place}
                                    setValidated={p.setValidated}
                                    validated={p.validated}
                                    validationShow={true}
                                />
                            </div>
                        </li>
                        }
                    </ul>
                </>
            }
            onClose={p.closeLightbox}
            open={p.addPlaceLB}
            tid='808'
            title={<Trans id={808}/>}
        />
    )
}

export default withGlobalState(CourseProductPlaceLB)
