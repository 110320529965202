import React, {useEffect, useState} from 'react';
import Page from "./Page";
import {withGlobalState} from "react-globally";
import {apiGet, apiPut} from "../services/ServiceAPI";
import update from "react-addons-update";
import {toBase64} from "../services/ServiceForm";
import {hasKeys} from "../components/functions/FuncObjects";
import Infobox from "../components/elements/message/Infobox";
import {Link, Prompt} from "react-router-dom";
import ContentTabs from "../components/widgets/structuring/ContentTabs";
import ComplexProfileRelationsForm from "../complex/forms/profile/ComplexProfileRelationsForm";
import ComplexProfileDescForm from "../complex/forms/profile/ComplexProfileDescForm";
import ComplexProfileImagesForm from "../complex/forms/profile/ComplexProfileImagesForm";
import Trans from "../components/elements/trans/Trans";
import {getInputValue} from "../components/functions/FuncForm";
import {getTrans} from "../services/ServiceTrans";
import {useSelector} from "react-redux";

const PageProfile = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** loading **************/
    const [loadedDepedencies, setLoadedDepedencies] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [loadingTitleImage, setLoadingTitleImage] = useState(false);
    const [loadingRelations, setLoadingRelations] = useState(false);
    const [loading, setLoading] = useState(false);

    /************** form **************/
    /* states */
    const [submitDesc, setSubmitDesc] = useState(false)
    const [submitLogo, setSubmitLogo] = useState(false)
    const [submitTitleImage, setSubmitTitleImage] = useState(false)
    const [submitRelations, setSubmitRelations] = useState(false)
    const [savedLogo, setSavedLogo] = useState(true);
    const [savedTitleImage, setSavedTitleImage] = useState(true);
    const [savedDesc, setSavedDesc] = useState(true);
    const [savedRelations, setSavedRelations] = useState(true);

    /* validation */
    const [validatedDesc, setValidatedDesc] = useState({})

    /************** global **************/
    const u = p.globalState.user
    const transState = useSelector((state) => state.trans)
    const [allOccupationsOptions, setAllOccupationsOptions] = useState([])
    const [availableTags, setAvailableTags] = useState([])
    const [profile, setProfile] = useState({
        imageLogo: '',
        imageTitle: '',
        shortDesc: '',
        longDesc: '',
        encodedUrl: ''
    })
    const tagOptions = u.tags.map(t => {return {
        value: t,
        label: t
    }})
    const occupationsOptions = u.occupations.map(o => {return {
        value: o.title,
        label: o.title
    }})

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (!loadedDepedencies) {
            setLoadedDepedencies(true)
            setAvailableTags(u.tags.map(t => {return {
                value: t,
                label: t
            }}))

            apiGet('profile/public', p)
                .then(r => {
                    setProfile(r.data)
                })

            apiGet('profile/occupations', p)
                .then(r => {
                    setAllOccupationsOptions(r.data.map(o => {return {
                        value: o.title,
                        label: o.title
                    }}))
                })
        }
    }, [loadedDepedencies, p, u.tags])

    useEffect(() => {
        setSubmitDesc(!hasKeys(validatedDesc))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatedDesc])

    useEffect(() => {
        setSavedLogo(submitLogo === false)
    }, [submitLogo])

    useEffect(() => {
        setSavedTitleImage(submitTitleImage === false)
    }, [submitTitleImage])

    useEffect(() => {
        window.onbeforeunload = (savedLogo && savedTitleImage && savedDesc && savedRelations)? () => true : undefined
    }, [savedLogo, savedTitleImage, savedDesc, savedRelations])

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    /* change */

    const handleLogoChange = () => {
        setSubmitLogo(true)
    }

    const handleTitleImageChange = () => {
        setSubmitTitleImage(true)
    }

    const handleInputChange = (event, params = {}) => {
        const input = getInputValue(event, params)
        if (input.value !== profile[input.name]) setSavedDesc(false)
        setProfile({...profile, [input.name]: input.value})
    }

    const handleTagsChange = (selectedOption) => {
        p.setGlobalState(update(p.globalState, {
            user: {
                tags: {
                    $set: selectedOption.map((o) => o.value)
                }
            }
        }))
        setSubmitRelations(true)
        setSavedRelations(false)
    }

    const handleOccupationsChange = (selectedOption) => {
        p.setGlobalState(update(p.globalState, {
            user: {
                occupations: {
                    $set: selectedOption.map(o => {return {
                        title: o.value
                    }})
                }
            }
        }))
        setSubmitRelations(true)
        setSavedRelations(false)
    }

    /* submit */

    const handleFormRelations = (event) => {
        event.preventDefault()
        setLoadingRelations(true)
        setSubmitRelations(false)

        let user = JSON.parse(JSON.stringify(u))
        delete user.timestamp;

        apiPut('profile/userdata', p, user)
            .finally(() => {
                setSavedRelations(true)
                setLoadingRelations(false)
            })
    }

    const handleFormSubmitDesc = (event) => {
        event.preventDefault()
        setLoading(true)
        setSubmitDesc(false)

        apiPut('profile/public', p, {
            shortDesc: profile.shortDesc,
            longDesc: profile.longDesc
        }).then(() => {
            setSavedDesc(true)
            setLoading(false)
            p.getUserUpdate(p)
        })
    }

    const handleFormSubmitLogo = async (file) => {
        setLoadingLogo(true)
        setSubmitLogo(false)

        const base64 = await (toBase64(file[0]))
        apiPut('profile/public', p, {
            shortDesc: profile.shortDesc,
            longDesc: profile.longDesc,
            imageLogo: {
                filename: file[0].name,
                filedata: base64
            }
        })
            .then(() => {
                setSavedLogo(true)
                apiGet('profile/public', p)
                    .then(r => {
                        setProfile(r.data)
                        p.getUserUpdate(p)
                    })
                    .then(() => {
                        setLoadingLogo(false)
                    })
                })
            .catch(() => {
                setLoadingLogo(false)
            })
    }

    const handleFormSubmitTitleImage = async (file) => {
        setLoadingTitleImage(true)
        setSubmitTitleImage(false)

        const base64 = await toBase64(file[0])
        apiPut('profile/public', p, {
            shortDesc: profile.shortDesc,
            longDesc: profile.longDesc,
            imageTitle: {
                filename: file[0].name,
                filedata: base64
            }
        })
            .then(() => {
                setSavedTitleImage(true)
                apiGet('profile/public', p)
                    .then(r => {
                        setProfile(r.data)
                        p.getUserUpdate(p)
                    })
                    .finally(() => {
                        setLoadingTitleImage(false)
                    })
            })
            .catch(() => {
                setLoadingTitleImage(false)
            })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    let content = <div className='c-tab__outer is--fullwidth'>
        <Prompt
            message={() => getTrans(transState, 879)}
            tid='879'
            when={!savedLogo || !savedDesc || !savedRelations || !savedTitleImage}
        />
        <ContentTabs
            addClasses={'is--br-none'}
            body={<>
                <ComplexProfileDescForm
                    handleFormSubmitDesc={handleFormSubmitDesc}
                    handleInputChange={handleInputChange}
                    loading={loading}
                    profile={profile}
                    setValidated={setValidatedDesc}
                    submitDesc={submitDesc}
                    validated={validatedDesc}
                />
                <ComplexProfileRelationsForm
                    allOccupationsOptions={allOccupationsOptions}
                    availableTags={availableTags}
                    handleFormRelations={handleFormRelations}
                    handleOccupationsChange={handleOccupationsChange}
                    handleTagsChange={handleTagsChange}
                    loadingRelations={loadingRelations}
                    occupationsOptions={occupationsOptions}
                    submitRelations={submitRelations}
                    tagOptions={tagOptions}
                />
                <div className='p-tb-16'>
                    <ComplexProfileImagesForm
                        handleFormSubmitLogo={handleFormSubmitLogo}
                        handleFormSubmitTitleImage={handleFormSubmitTitleImage}
                        handleLogoChange={handleLogoChange}
                        handleTitleImageChange={handleTitleImageChange}
                        loadingLogo={loadingLogo}
                        loadingTitleImage={loadingTitleImage}
                        profile={profile}
                        submitLogo={submitLogo}
                        submitTitleImage={submitTitleImage}
                    />
                </div>
            </>}
            head={''}
            open={true}
            width={100}
        />
    </div>
    if (hasKeys(p.globalState.status) && p.globalState.status.accountDataComplete.status) {
        content = <div>
            <Infobox
                icon={'info'}
                addClasses={'c-bg-pr-2-dark is--no-border is--icon-left c-w'}
                content={
                    <p>
                        <span tid="293"><Trans id={293}/></span>
                        <br/>
                        <span tid='292'><Trans id={292}/></span>
                        <Link
                            tid='291'
                            title={<Trans id={291}/>}
                            to="/stammdaten"
                        ><Trans id={291}/></Link>.
                    </p>
                }
            />
        </div>
    }

    return (
        <Page
            actions={profile.encodedUrl &&
                [
                    {
                        href: process.env.REACT_APP_ROOT_URL + 'anbieter/detail/' + profile.encodedUrl,
                        icon: 'globe',
                        rel: 'noopener',
                        target: '_blank',
                        title: <Trans id={373}/>
                    }
                ]
            }
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <>
                        {content}
                    </>
                )
            }
            tid='231'
            title={<Trans id={231}/>}
        />
    )
}

export default withGlobalState(PageProfile)
