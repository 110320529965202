import React from 'react'

const FormError = (p) => {
    return (
        <div className='e-form__status'>
            <p>{p.error}</p>
        </div>
    )
}

export default FormError
