import React, {useEffect, useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import {buildDateStringWithTime} from "../../../services/ServiceDate";
import FormLoad from "../../../components/elements/form/FormLoad";
import {useSelector} from "react-redux";
import FormInput from "../../../components/elements/form/FormInput";
import Infobox from "../../../components/elements/message/Infobox";
import Trans from "../../../components/elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";
import DropdownTreeSelect from "react-dropdown-tree-select";
import 'react-dropdown-tree-select/dist/styles.css'

const getProductCategoryTree = (product, tree) => {
    let treeLocal = [...tree]
    treeLocal.forEach((item, i) => {
        let localItem = {...item}
        product.courseCategories.forEach((cat) => {
            if (localItem.value === cat) localItem.checked = true
            if (localItem.children.length !== 0) {
                localItem.children = getProductCategoryTree(product, localItem.children)
            }
        })
        treeLocal[i] = localItem
    })
    return treeLocal
}

const ComplexCourseProductForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const stC = useSelector((s) => s.courses)
    const stG = useSelector((s) => s.general)
    const stF = useSelector((s) => s.features)
    const [categories, setCategories] = useState([])

    /************** objects **************/
    const [typesOfSale, setTypesOfSale] = useState([])
    const transState = useSelector((state) => state.trans)

    /************** form **************/
    const [inputs] = useState({
        totPrice: [['required']],
        extendedCost: [[['maxLength', 350]]],
        productNumberPrivate: [[['maxLength', 32]]],
        contingent: [['required']],
        azav: [[['maxLength', 32]]],
        supportText: [[['maxLength', 600]]]
    })

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        setCategories(getProductCategoryTree(p.actProduct, stC.categories))
    }, [p.actProduct, stC.categories])

    useEffect(() => {
        setTypesOfSale(stF.features.leads.map((i) => {
            if (i !== undefined) return {
                    value: i.identifier,
                    label: i.title
                }
            return []
        }))
    }, [stF.features.leads])

    const onChange = (currentNode, selectedNodes) => {
        p.handleProductChange(null, {
            name: 'courseCategories',
            value: selectedNodes.map((item) => {return item.value})
        })
    }

    const onAction = () => {}
    const onNodeToggle = () => {}

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const aP = p.actProduct
    const cP = p.coursePlace
    const handlePC = p.handleProductChange
    const valiP = p.productValidated
    const setValiP = p.setProductValidated

    return (
        <form onSubmit={e => p.handleProductFormSubmit(e)} name='ProductForm'>
            <FormRow
                childs={
                    <div className={'e-form__grouping m-b-6'}>
                        <h4 className={'e-form__grouping-title'} tid='785'><Trans id={785}/></h4>
                        {aP.activeUntil !== null
                            ? <>
                                <p className={'e-def'}>
                                    <span className={'e-def__title'} tid='520'><Trans id={520}/>:</span>
                                    {aP.startdate !== null
                                        ? <span tid={'495'}>{buildDateStringWithTime(new Date(aP.startdate))} <Trans id={495}/></span>
                                        : <span tid={'784'}><Trans id={784}/></span>
                                    }
                                </p>
                                {aP.enddate !== null &&
                                    <p className={'e-def'}>
                                        <span tid='783'><Trans id={783}/>:</span>
                                        <span>{buildDateStringWithTime(new Date(aP.enddate))} <Trans id={495}/></span>
                                    </p>
                                }
                                <p className={'e-def'}>
                                    <span tid='447'><Trans id={447}/>:</span>
                                    <span>{buildDateStringWithTime(new Date(aP.activeUntil))} <Trans id={495}/></span>
                                </p>
                                {!!aP.timeInfo && aP.timeInfo.trim() &&
                                    <p className={'e-def is--no-line'}>
                                        <span tid='782'><Trans id={782}/>:</span>
                                        <span>{aP.timeInfo}</span>
                                    </p>
                                }
                            </>
                            : <p className={'e-def'}>
                                <span tid='781'><Trans id={781}/></span>
                            </p>
                        }
                        <button
                            className={'e-btn is--light-ok ico-after-calendar is--small'}
                            onClick={p.handleAddTimes}
                            tid='779, 780'
                        >{aP.timeInfo !== null && aP.activeUntil !== null
                            ? getTrans(transState, 779)
                            : getTrans(transState, 780)}</button>
                    </div>
                }
            />
            <FormRow
                childs={
                    <div className={'e-form__grouping m-b-6'}>
                        <h4 className={'e-form__grouping-title'} tid='778'><Trans id={778}/></h4>
                        {aP.coursePlaceId !== null && cP !== null && typeof cP !== 'undefined'
                            ? <p className={'e-def'}>
                                <span className={'e-def__content'}>
                                    {cP.title !== null &&
                                    <>{cP.title}<br/></>
                                    }
                                    <>{cP.street} {cP.houseNumber}<br/>{cP.zip} {cP.city}</>
                                </span>
                            </p>
                            : <p className={'e-def'}>
                                <span tid='777'><Trans id={777}/></span>
                            </p>
                        }
                        <button
                            className={'e-btn is--light-ok ico-after-location is--small'}
                            onClick={p.handleAddPlace}
                            tid='775, 776'
                        >{aP.coursePlace !== null
                            ? getTrans(transState, 775)
                            : getTrans(transState, 776)}</button>
                    </div>
                }
            />
            <FormRow
                childs={
                    <div className={'e-form__grouping m-b-6'}>
                        <h4 className={'e-form__grouping-title'} tid='772'><Trans id={772}/> {aP.courseSpeaker.length !== 0 &&
                        <span className={'e-text__tag is--filled'}>{aP.courseSpeaker.length}</span>
                        }</h4>
                        <button
                            className={'e-btn is--light-ok ico-after-mic is--small'}
                            onClick={p.handleAddSpeaker}
                            tid='773, 774'
                        >{aP.courseSpeaker.length !== 0
                            ? getTrans(transState, 773)
                            : getTrans(transState, 774)}</button>
                    </div>
                }
            />
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'} tid='771'><Trans id={771}/></h4>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={770}/>}
                        name='readyToPublishedProduct'
                        onChange={handlePC}
                        tid='770'
                        type={'switch'}
                        value={aP.readyToPublished}
                    />
                }
                />
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={757}/>}
                            name='totPrice'
                            onChange={handlePC}
                            required
                            step={'0.01'}
                            tid='757'
                            type={'number'}
                            validationChecks={inputs.totPrice}
                            validationShow={p.validationShow}
                            validationAct={valiP}
                            validationSet={setValiP}
                            value={aP.totPrice}
                            visible={true}
                        />
                        <FormInput
                            label={<Trans id={511}/>}
                            name='tax'
                            onChange={handlePC}
                            options={stG.tax}
                            required
                            tid='511'
                            type={'select'}
                            selected={aP.tax}
                            dataType={'int'}
                        />
                    </>
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={768}/>}
                        name='extendedCost'
                        onChange={handlePC}
                        placeholder={getTrans(transState, 769)}
                        tid='768'
                        type={'textarea'}
                        validationChecks={inputs.extendedCost}
                        validationShow={p.validationShow}
                        validationAct={valiP}
                        validationSet={setValiP}
                        value={aP.extendedCost}
                        visible={true}
                    />
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={761}/>}
                        name='productNumberPrivate'
                        onChange={handlePC}
                        tid='761'
                        validationChecks={inputs.productNumberPrivate}
                        validationShow={p.validationShow}
                        validationAct={valiP}
                        validationSet={setValiP}
                        value={aP.productNumberPrivate}
                        visible={true}
                    />
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={762}/>}
                        options={stC.courseTypeOptions}
                        name='courseTypeId'
                        onChange={handlePC}
                        required
                        tid='762'
                        type={'select'}
                        selected={aP.courseTypeId}
                    />
                }
                />
                <FormRow childs={
                    <FormInput
                        label={'Veranstaltungsart'}
                        options={stC.courseKindsOptions}
                        name='courseKindId'
                        onChange={handlePC}
                        required
                        type={'select'}
                        selected={aP.courseKindId}
                    />
                }
                />
            </div>
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'} tid='445'><Trans id={445}/></h4>
                <div className='p-b-16'>
                    <Infobox
                        addClasses={'is--no-border is--icon-left is--small'}
                        content={getTrans(transState, 535)}
                        html={true}
                        icon={'info'}
                        title={<Trans id={534}/>}
                    />
                </div>
                <FormInput
                    label={<Trans id={445}/>}
                    max={999}
                    name='contingent'
                    onChange={handlePC}
                    required
                    tid='445'
                    type={'number'}
                    validationChecks={inputs.contingent}
                    validationShow={p.validationShow}
                    validationAct={valiP}
                    validationSet={setValiP}
                    value={aP.contingent}
                    visible={true}
                />
            </div>
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'}>Kategorien</h4>
                <FormRow childs={
                    <div className={'complex-treesel'}>
                        <DropdownTreeSelect
                            className={'w-100'}
                            data={categories}
                            onChange={onChange}
                            onAction={onAction}
                            onNodeToggle={onNodeToggle}
                            texts={{
                                placeholder: 'Nach Kategorie suchen',
                                inlineSearchPlaceholder: 'Suchen...',
                                noMatches: 'Keine Kategorien gefunden'
                            }}
                        />
                    </div>
                }
                />
            </div>
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'} tid='767'><Trans id={767}/></h4>
                <FormRow childs={
                    <>
                        <FormInput
                            label={<Trans id={758}/>}
                            name='eligible'
                            onChange={handlePC}
                            tid='758'
                            type={'switch'}
                            value={aP.eligible}
                        />
                        <FormInput
                            label={<Trans id={759}/>}
                            name='points'
                            onChange={handlePC}
                            tid='759'
                            type={'number'}
                            value={aP.points}
                            visible={true}
                        />
                    </>
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={766}/>}
                        name='azav'
                        onChange={handlePC}
                        tid='766'
                        validationChecks={inputs.azav}
                        validationShow={p.validationShow}
                        validationAct={valiP}
                        validationSet={setValiP}
                        value={aP.azav}
                        visible={true}
                    />
                }
                />
                <FormRow childs={
                    <FormInput
                        label={<Trans id={760}/>}
                        name='supportText'
                        onChange={handlePC}
                        tid='760'
                        type={'textarea'}
                        validationChecks={inputs.supportText}
                        validationShow={p.validationShow}
                        validationAct={valiP}
                        validationSet={setValiP}
                        value={aP.supportText}
                        visible={true}
                    />
                }
                />
            </div>
            <div className={'e-form__grouping m-b-6'}>
                <h4 className={'e-form__grouping-title'} tid='763'><Trans id={763}/></h4>
                <div className='p-b-16'>
                    <Infobox
                        addClasses={'is--no-border is--icon-left is--small'}
                        content={getTrans(transState, 532)}
                        html={true}
                        icon={'info'}
                        title={<Trans id={533}/>}
                    />
                </div>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={764}/>}
                        options={typesOfSale}
                        name='typeOfSale'
                        onChange={handlePC}
                        required
                        tid='764'
                        type={'select'}
                        selected={aP.typeOfSale}
                    />
                }
                />
            </div>
            <FormRow childs={
                <>
                    <div className='w-100'>
                        <FormLoad
                            a={p.loadingProductDetail}
                            addClasses={'p-b-16'}
                        />
                        {!p.loadingProductDetail &&
                            <button
                                className={`c-bg-pr-3 c-w rad-6 w-100 p-lr-16 p-tb-10 a-c outline outline-w-2 outline-c-pr-3 hover-c-bg-pr-3-darker hover-outline-c-pr-3-darker ${p.loading? 'is--loading' : ''}`}
                                tid='765'
                                title={<Trans id={765}/>}
                                type='submit'
                            >
                                <Trans id={765}/>
                            </button>
                        }
                    </div>
                </>
            }/>
        </form>
    )
}

export default withGlobalState(ComplexCourseProductForm)
