import React from 'react'

const ActionMenu = (p) => {
    if (p.items) {
        return (<div>
            <nav>
                <ul className='e-actions__menu'>
                    {p.items}
                </ul>
            </nav>
        </div>)
    }

    return ''
}

export default ActionMenu
