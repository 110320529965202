import React, {useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import {useSelector} from "react-redux";
import ListItemCoursePlace from "../../components/widgets/list/ListItemCoursePlace";
import FormLoad from "../../components/elements/form/FormLoad";
import {getInputValue} from "../../components/functions/FuncForm";
import FormInput from "../../components/elements/form/FormInput";
import Trans from "../../components/elements/trans/Trans";
import {getTrans} from "../../services/ServiceTrans";

const ComplexCoursePlaceList = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form **************/
    const [searchValue, setSearchValue] = useState('')

    /************** global **************/
    const [openPlaceList] = useState(true)
    const coursesState = useSelector(state => state.courses)
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    const onSearch = (event) => {
        setSearchValue(getInputValue(event).value)
    }

    /************** button events **************/
    const onItemClick = (id) => {
        p.onItemClick(id)
    }

    const addCoursePlaceHandler = () => {
        p.addCoursePlaceHandler()
    }

    /************** general **************/
    const searchInCoursePlaces = (place) => {
        return (
            searchValue === ''
            || place.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            || place.city.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            || place.zip.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            || place.street.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            || place.houseNumber.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            || place.additional.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        )
    }

    const getCoursePlaceList = () => {
        return coursesState.coursePlaces.map((place) => {
            return (
                <>
                    {
                        searchInCoursePlaces(place) &&
                        <ListItemCoursePlace
                            place={place}
                            key={place.id}
                            onclick={onItemClick}
                            selected={(coursesState.actCoursePlace.id === place.id)? 'is--selected' : ''}
                            search={searchValue}
                        />
                    }
                </>
            )
        })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const coursePlaceList = getCoursePlaceList()

    return (
        <ContentTabs
            body={<>
                {p.coursePlaceLoading
                    ? <FormLoad a={true}/>
                    : <>
                        {coursePlaceList.length !== 0 &&
                        <ul className='c-itemlist'>
                            {coursePlaceList}
                        </ul>
                        }
                    </>
                }
            </>}
            head={<>
                <div className='c-tab__head-top'>
                    <div>
                        <p className='c-tab__head-title' tid={605}><Trans id={605}/></p>
                    </div>
                    <div>
                        <nav className='c-tab__head-actions'>
                            <ul>
                                <li>
                                    <button
                                        className={`e-btn__action ico-plus-circled ${(coursesState.coursePlaces.length === 0)? 'c-bg-pr-2-dark': ''}`}
                                        onClick={addCoursePlaceHandler}
                                        tid={'606'}
                                        title={getTrans(transState, 606)}
                                    />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className='c-tab__head-bottom'>
                    <form autoComplete="off">
                        <FormInput
                            addClasses='is--visible-border'
                            addClassesWrap={'p-none'}
                            name='searchList'
                            onChange={onSearch}
                            placeholder={getTrans(transState, 607)}
                            tid={'607'}
                            type='text'
                            value={searchValue}
                        />
                    </form>
                </div>
            </>}
            open={openPlaceList}
            tid={'608'}
            title={getTrans(transState, 608)}
            width={50}
        />
    )
}

export default withGlobalState(ComplexCoursePlaceList)
