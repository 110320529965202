import { configureStore } from '@reduxjs/toolkit';
import coursesReducer from './reducer/courseSlice';
import transReducer from './reducer/transSlice';
import helpReducer from './reducer/helpSlice';
import generalSlice from './reducer/generalSlice';
import archivedReducer from './reducer/archivedSlice';
import ordersReducer from './reducer/ordersSlice';
import featuresSlice from './reducer/featuresSlice';
import invoiceSlice from "./reducer/invoiceSlice";

export default configureStore({
    reducer: {
        archived: archivedReducer,
        courses: coursesReducer,
        features: featuresSlice,
        orders: ordersReducer,
        trans: transReducer,
        help: helpReducer,
        general: generalSlice,
        invoice: invoiceSlice
    }
});
