import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import Trans from "../../../components/elements/trans/Trans";
import FormInput from "../../../components/elements/form/FormInput";

const ComplexAddCourseForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form **************/
    const [inputs] = useState({
        title: [['required'], [['maxLength', 130]], [['minLength', 10]]],
        titleLong: [[['maxLength', 250]], [['minLength', 10]]],
        descShort: [[['maxLength', 350]]]
    })

    return (
        <form autoComplete='off'>
            <FormRow childs={
                <FormInput
                    label={<Trans id={397}/>}
                    name='title'
                    onChange={p.handleNewCourseChange}
                    required
                    tid='397'
                    validationChecks={inputs.title}
                    value={p.newCourse.title}
                    visible={true}
                    validationShow={p.validationShow}
                    validationAct={p.validated}
                    validationSet={p.setValidated}
                />
            }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={398}/>}
                    name='titleLong'
                    onChange={p.handleNewCourseChange}
                    tid='398'
                    validationChecks={inputs.titleLong}
                    value={p.newCourse.titleLong}
                    validationShow={p.validationShow}
                    validationAct={p.validated}
                    validationSet={p.setValidated}
                    visible={true}
                />
            }
            />
            <FormRow childs={
                <FormInput
                    label={<Trans id={399}/>}
                    name='descShort'
                    onChange={p.handleNewCourseChange}
                    placeholder={<Trans id={402}/>}
                    required
                    tid='399, 402'
                    type={'textarea'}
                    validationChecks={inputs.descShort}
                    value={p.newCourse.descShort}
                    validationShow={p.validationShow}
                    validationAct={p.validated}
                    validationSet={p.setValidated}
                    visible={true}
                />
            }
            />
            <FormRow childs={
                <FormInput
                    data={p.newCourse.descLong}
                    label={<Trans id={400}/>}
                    name='descLong'
                    onEditorChange={p.handleNewCourseChange}
                    required
                    tid='400'
                    type={'richarea'}
                    value={p.newCourse.descLong}
                />
            }
            />
        </form>
    )
}

export default withGlobalState(ComplexAddCourseForm)
