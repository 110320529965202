import React, {useEffect, useState} from 'react';
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import ListItemCourseArchived from "../../../components/widgets/list/ListItemCourseArchived";
import Trans from "../../../components/elements/trans/Trans";
import FormInput from "../../../components/elements/form/FormInput";
import ContentTabs from "../../../components/widgets/structuring/ContentTabs";
import {useSelector} from "react-redux";
import ListItemCourseProductArchived from "../../../components/widgets/list/ListItemCourseProductArchived";
import FormLoad from "../../../components/elements/form/FormLoad";

const ArchivedCourseLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** global **************/
    const coursesState = useSelector((state) => state.courses)
    const archivedState = useSelector((state) => state.archived)
    const archivedProducts = archivedState.archivedProducts

    /************** form **************/
    const [courseSearchValue, setCourseSearchValue] = useState('')
    const [courseProductSearchValue, setCourseProductSearchValue] = useState('')

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (archivedProducts.length === 0) p.getArchivedProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursesState.actCourse]);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/
    /************** input events **************/
    const onCourseSearch = (e) => {
        setCourseSearchValue(e.target.value)
    }

    const onCourseProductSearch = (e) => {
        setCourseProductSearchValue(e.target.value)
    }

    /************** general **************/
    const searchInCourses = (q, course) => {
        return (
            q === ''
            || course.title.toLowerCase().indexOf(q.toLowerCase()) !== -1
            || course.courseNumber.toLowerCase().indexOf(q.toLowerCase()) !== -1
        )
    }

    const searchInCourseProducts = (q, courseProduct) => {
        return (
            q === ''
            || courseProduct.productNumber.toLowerCase().indexOf(q.toLowerCase()) !== -1
        )
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const courseList = p.courses.filter(course => course.courseStatus['archived'].status).map((course) => {
        return (
            <>
                {searchInCourses(courseSearchValue, course) &&
                    <ListItemCourseArchived
                        course={course}
                        key={course.id}
                        search={courseSearchValue}
                        status={'inactive'}
                        rearchiveCourse={p.rearchiveCourse}
                    />
                }
            </>
        )
    })

    const courseProductList = archivedProducts.map((courseProduct) => {
        return (
            <>
                {searchInCourseProducts(courseProductSearchValue, courseProduct) &&
                    <ListItemCourseProductArchived
                        courseProduct={courseProduct}
                        key={courseProduct.id}
                        search={courseProductSearchValue}
                        status={'inactive'}
                        rearchiveCourseProduct={p.rearchiveCourseProduct}
                    />
                }
            </>
        )
    })

    return (
        <LB
            actions={
                <li>
                    <button
                        className='e-btn is--orange'
                        onClick={() => p.closeLightbox()}
                    >Abbrechen</button>
                </li>
            }
            addClasses='is--big'
            content={
                <>
                    <div className='c-tab__outer is--fullwidth is--max-height'>
                        <ContentTabs
                            body={
                                <div className='c-itemlist__container p-b-6'>
                                    <div>
                                        <ul className='c-itemlist'>
                                            {courseList}
                                        </ul>
                                    </div>
                                </div>
                            }
                            head={
                                <>
                                    <div className='c-tab__head-top'>
                                        <div>
                                            <p className='c-tab__head-title'>Archivierte Weiterbildungen</p>
                                        </div>
                                    </div>
                                    <div className='c-tab__head-bottom'>
                                        <form autoComplete="off">
                                            <FormInput
                                                addClasses='is--visible-border'
                                                addClassesWrap={'p-none'}
                                                name='searchList'
                                                onChange={onCourseSearch}
                                                placeholder={<Trans id={391}/>}
                                                tid='391'
                                                type='text'
                                                value={courseSearchValue}
                                            />
                                        </form>
                                    </div>
                                </>
                            }
                            open={true}
                            title='Weiterbildungen'
                            width={50}
                        />
                        <ContentTabs
                            body={
                                <>
                                    {
                                        p.loadingArchivedProducts
                                            ? <FormLoad a={true}/>
                                            : <div className='c-itemlist__container p-b-6'>
                                                <div>
                                                    <ul className='c-itemlist'>
                                                        {courseProductList}
                                                    </ul>
                                                </div>
                                            </div>
                                    }
                                </>
                            }
                            head={
                                <>
                                    <div className='c-tab__head-top'>
                                        <div>
                                            <p className='c-tab__head-title'>Archivierte Veranstaltungen</p>
                                        </div>
                                    </div>
                                    <div className='c-tab__head-bottom'>
                                        <form autoComplete="off">
                                            <FormInput
                                                addClasses='is--visible-border'
                                                addClassesWrap={'p-none'}
                                                name='searchList'
                                                onChange={onCourseProductSearch}
                                                placeholder={<Trans id={391}/>}
                                                tid='391'
                                                type='text'
                                                value={courseProductSearchValue}
                                            />
                                        </form>
                                    </div>
                                </>
                            }
                            open={true}
                            title='Veranstaltungen'
                            width={50}
                        />
                    </div>
                    {/*<td>
                                <div className='c-itemlist__container p-b-6 p-l-16'>
                                    <div className={'p-b-6 fl fl-jc-sb fl-a-items-center'}>

                                    </div>
                                    <div>
                                        <ul className='c-itemlist'>
                                            {courseProductList}
                                        </ul>
                                    </div>
                                </div>
                            </td>*/}
                </>
            }
            onClose={p.closeLightbox}
            open={p.courseArchivedLB}
            title={'Archivierte Weiterbildungen und Veranstaltungen'}
        />
    )
}

export default withGlobalState(ArchivedCourseLB)
