import React from 'react';
import {withGlobalState} from "react-globally";

const Infobox = (p) => {
    return (
        <div className={`e-infobox is--mb ico-${p.icon? p.icon : 'info'} ${p.addClasses}`}>
            <div className='e-infobox__inner'>
                {p.title &&
                <p className={`f-w-5 ${p.addClassesTitle}`} tid={p.titleTid}>{p.title}</p>
                }
                {(typeof p.content !== 'string' && p.html) &&
                    <div className={`e-infobox__text ${p.addClassesContent}`}>{p.content}</div>
                }
                {(typeof p.content === 'string' && p.html) &&
                    <div className={`e-infobox__text ${p.addClassesContent}`} dangerouslySetInnerHTML={{__html: p.content}}/>
                }
                {(p.content && !p.html) &&
                <div className={'e-infobox__text'}>
                    <p className={p.addClassesContent}>{p.content}</p>
                </div>
                }
            </div>
        </div>
    )
}

export default withGlobalState(Infobox)
