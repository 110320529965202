import React, {useState} from 'react';
import {withGlobalState} from "react-globally";
import ContentTabs from "../../components/widgets/structuring/ContentTabs";
import {useSelector} from "react-redux";
import FormLoad from "../../components/elements/form/FormLoad";
import {getInputValue} from "../../components/functions/FuncForm";
import ListItemCourseSpeaker from "../../components/widgets/list/ListItemCourseSpeaker";
import FormInput from "../../components/elements/form/FormInput";
import Trans from "../../components/elements/trans/Trans";
import {getTrans} from "../../services/ServiceTrans";

const ComplexCourseSpeakerList = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** form **************/
    const [searchValue, setSearchValue] = useState('')

    /************** global **************/
    const [openSpeakerList] = useState(true)
    const coursesState = useSelector( state => state.courses)
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** input events **************/
    const onSearch = (event) => {
        setSearchValue(getInputValue(event).value)
    }

    /************** button events **************/
    const onItemClick = (id) => {
        p.onItemClick(id)
    }

    const addCourseSpeakerHandler = () => {
        p.addCourseSpeakerHandler()
    }

    /************** general **************/
    const searchInCourseSpeaker = (speaker) => {
        return (
            searchValue === ''
            || speaker.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            || speaker.firstName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            || speaker.lastName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            || speaker.shortDesc.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        )
    }

    const getCourseSpeakerList = () => {
        return coursesState.courseSpeaker.map((speaker) => {
            return (
                <>
                    {
                        searchInCourseSpeaker(speaker) &&
                        <ListItemCourseSpeaker
                            speaker={speaker}
                            key={speaker.id}
                            onclick={onItemClick}
                            selected={(coursesState.actCourseSpeaker.id === speaker.id)? 'is--selected' : ''}
                            search={searchValue}
                        />
                    }
                </>
            )
        })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const speakerListHead = <>
        <div className='c-tab__head-top'>
            <div>
                <p className='c-tab__head-title' tid='734'><Trans id={734}/></p>
            </div>
            <div>
                <nav className='c-tab__head-actions'>
                    <ul>
                        <li>
                            <button
                                className={`e-btn__action ico-plus-circled ${(coursesState.courseSpeaker.length === 0)? 'c-bg-pr-2-dark': ''}`}
                                onClick={addCourseSpeakerHandler}
                                tid='735'
                                title={getTrans(transState, 735)}
                            />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className='c-tab__head-bottom'>
            <form autoComplete="off">
                <FormInput
                    addClasses='is--visible-border'
                    addClassesWrap={'p-none'}
                    name='searchList'
                    onChange={onSearch}
                    placeholder={getTrans(transState, 736)}
                    tid='736'
                    type='text'
                    value={searchValue}
                />
            </form>
        </div>
    </>

    const courseSpeakerList = getCourseSpeakerList()

    const speakerListContent = <>
        {p.courseSpeakerLoading
            ? <FormLoad a={true}/>
            : <>
                {courseSpeakerList.length !== 0 &&
                    <ul className='c-itemlist'>
                        {courseSpeakerList}
                    </ul>
                }
            </>
        }
    </>

    return (
        <ContentTabs
            body={speakerListContent}
            head={speakerListHead}
            open={openSpeakerList}
            tid='737'
            title={getTrans(transState, 737)}
            width={50}
        />
    )
}

export default withGlobalState(ComplexCourseSpeakerList)
