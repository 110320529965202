import Application from "./components/layout/Application";
import {Provider} from "react-globally";
import ServiceForm from "./services/ServiceForm";
import store from './store/store'
import { Provider as ProviderRedux } from 'react-redux'
import ErrorBoundaryGlobal from "./error/ErrorBoundaryGlobal";
import Popup from "react-popup";
import Prompt from "./components/widgets/message/Promt";
import React from "react";
import {apiPost} from "./services/ServiceAPI";

const initialState = {
    user: {
        accountFirstName: '',
        accountLastName: '',
        accountMailApproved: false,
        accountProfession: '',
        accountSalutation: '-',
        accountMail: '',
        companyCity: '',
        companyConditions: '',
        companyHouseNumber: '',
        companyImprint: '',
        companyName: '',
        companyPrivacy: '',
        companyStreet: '',
        companyUstid: '',
        companyZip: '',
        companyWebsite: '',
        contactMail: '',
        contactTel: '',
        occupations: [
            {}
        ],
        retailerNumber: '',
        tags: [],
        timestamp: false
    },
    status: {},
    score: {},
    gallery: {
        data: [],
        loaded: false
    },
    general: {
        salutations: [
            {value: 'f', label: 'Frau'},/*TRANS:HC*/
            {value: 'm', label: 'Herr'},/*TRANS:HC*/
            {value: '-', label: '-'}
        ]
    },
    services: {
        form: new ServiceForm()
    },
    auth: {
        logedin: false
    }
}

export function handleLogout(p) {
    apiPost('account/logout', p)
        .then(() => {
            window.location.reload()
        })
}

function App() {
    Popup.registerPlugin('prompt', function (title, message, closeAfterSeconds = null, callback = null) {
        if (closeAfterSeconds !== null) setTimeout(function(){
            Popup.close()
        }, closeAfterSeconds*1000);

        this.create({
            title: title,
            content: <Prompt message={message}/>,
            buttons: {
                right: [{
                    text: 'Ok',
                    key: '⌘+m, ctrl+m',
                    className: 'success',
                    action: function () {
                        if (callback !== null) callback()
                        Popup.close()
                    }
                }]
            }
        })
    })

    return (
        <ErrorBoundaryGlobal>
            <ProviderRedux store={store}>
                <Provider globalState={initialState}>
                    <Application fallback={<div>Loading...</div>}/>{/*TRANS:HC*/}
                </Provider>
            </ProviderRedux>
        </ErrorBoundaryGlobal>
    );
}

export default App;
