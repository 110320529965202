import React, {useEffect, useState} from 'react'
import Page from "./Page";
import {withGlobalState} from "react-globally";
import ContentTabs from "../components/widgets/structuring/ContentTabs";
import {useDispatch, useSelector} from "react-redux";
import {apiGet, apiPatch} from "../services/ServiceAPI";
import {setLeads} from "../store/reducer/ordersSlice";
import {getDateString} from "../services/ServiceDate";
import { useTable, usePagination, useSortBy, useExpanded } from 'react-table'
import FormLoad from "../components/elements/form/FormLoad";
import ComplexTableBottom from "../components/elements/table/ComplexTableBottom";
import LeadDetailLB from "../complex/lightboxes/leads/LeadDetailLB";
import Trans from "../components/elements/trans/Trans";
import {getTrans} from "../services/ServiceTrans";

const PageLeads = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** lightbox **************/
    const [leadDetailLB, setLeadDetailLB] = useState(false)

    /************** loading **************/
    const [loadingLeadsList, setLoadingLeadsList] = useState(false)

    /************** global **************/
    const [actLead, setActLead] = useState(null)
    const ordersState = useSelector((state) => state.orders)
    const dispatch = useDispatch()
    const transState = useSelector((state) => state.trans)

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- LIFECYCLE HOOKS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    useEffect(() => {
        if (ordersState.leads.length === 0) getLeads()
        // eslint-disable-next-line
    }, [ordersState.leads.length]);

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- FUNCTIONS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** lightbox **************/
    const openLeadLB = (row) => {
        setLeadDetailLB(true)
        setActLead(row)
    }

    const closeLightbox = () => {
        setLeadDetailLB(false)
    }

    /************** api calls **************/
    const getLeads = () => {
        setLoadingLeadsList(true)
        apiGet('leads', p)
            .then((r) => {
                dispatch(setLeads(r.data))
            })
            .finally(() => {
                setLoadingLeadsList(false)
            })
    }

    const setCompleted = (status, lead) => {
        apiPatch('leads/' + lead.id + '/completed', p, {
            isCompleted: status
        })
            .finally(() => {
                closeLightbox()
                getLeads()
            })
    }

    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- RENDER VARS
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const columns = React.useMemo(
        () => [
            {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }) => (
                    <span {...row.getToggleRowExpandedProps()}><span className={'ico-down'}/></span>
                )
            },
            {
                Header: <Trans id={490}/>,
                accessor: 'leadNumber'
            },
            {
                Header: <Trans id={491}/>,
                accessor: 'createdAt'
            },
            {
                Header: <Trans id={492}/>,
                accessor: 'courseProductNumber'
            },
            {
                Header: <Trans id={493}/>,
                accessor: 'courseProductNumberPrivate'
            },
            {
                Header: getTrans(transState, 494),
                Cell: ({ row }) => {
                    return (<span className={row.original.isProcessedByRetailer? 'c-pri-3' : 'c-status-warn'}>{row.original.status}</span>)
                }
            }
        ],
        [transState]
    )

    const data = React.useMemo(
        () => ordersState.leads.map((row) => {
            return {
                addressJson: row.addressJson,
                courseProductNumber: row.courseProductNumber,
                courseProductNumberPrivate: row.courseProductNumberPrivate,
                createdAt: getDateString(new Date(row.createdAt)) + ' ' + getTrans(transState, 495),
                id: row.id,
                isProcessedByRetailer: row.isProcessedByRetailer,
                leadNumber: row.leadNumber,
                status: row.status
            }
        }),
        [ordersState.leads, transState]
    )

    const renderRowSubComponent = React.useCallback(()  => {return ('')}, [])

    const tableInstance = useTable(
        {
            columns,
            data,
            renderRowSubComponent
        },
        useSortBy,
        useExpanded,
        usePagination
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = tableInstance

    return (
        <Page
            addClasses={'is--fullwidth'}
            contentElements={
                (
                    <div className='c-tab__outer is--fullwidth'>
                        <ContentTabs
                            body={<>
                                {loadingLeadsList
                                    ? <FormLoad a={true}/>
                                    : <div>
                                        <table className={'e-table'} {...getTableProps()}>
                                            <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            <span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                            {page.map((row) => {
                                                prepareRow(row)
                                                return (
                                                    <React.Fragment {...row.getRowProps()}>
                                                        <tr {...row.getRowProps()} {...row.getToggleRowExpandedProps()} onClick={() => openLeadLB({ row })}>
                                                            {
                                                                row.cells.map(cell => {
                                                                    return (
                                                                        <td {...cell.getCellProps()}>
                                                                            {cell.render('Cell')}
                                                                        </td>
                                                                    )
                                                                })}
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                        <ComplexTableBottom
                                            ComplexTableBottom
                                            canNextPage={canNextPage}
                                            canPreviousPage={canPreviousPage}
                                            gotoPage={gotoPage}
                                            nextPage={nextPage}
                                            pageCount={pageCount}
                                            pageIndex={pageIndex}
                                            pageOptions={pageOptions}
                                            pageSize={pageSize}
                                            previousPage={previousPage}
                                            setPageSize={setPageSize}
                                        />
                                    </div>
                                }
                            </>}
                            head={<>
                                <div className='c-tab__head-top'>
                                    <div>
                                        <p className='c-tab__head-title' tid='496'><Trans id={496}/></p>
                                    </div>
                                </div>
                                <div className='c-tab__head-bottom'>
                                </div>
                            </>}
                            open={true}
                            width={100}
                        />
                        <LeadDetailLB
                            actLead={actLead}
                            closeLightbox={closeLightbox}
                            leadDetailLB={leadDetailLB}
                            setCompleted={setCompleted}
                        />
                    </div>
                )
            }
            title={<Trans id={497}/>}
        />
    )
}

export default withGlobalState(PageLeads)