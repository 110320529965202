import React, {useState} from "react";
import {withGlobalState} from "react-globally";
import FormRow from "../../../components/elements/form/FormRow";
import FormInput from "../../../components/elements/form/FormInput";
import Trans from "../../../components/elements/trans/Trans";

const ComplexCourseServiceForm = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const addCourseService = p.courseService

    /************** form **************/
    const [inputs] = useState({
        title: [['required'], [['maxLength', 128]]]
    })

    return (
        <div>
            <form autoComplete='off'>
                <FormRow childs={
                    <FormInput
                        label={<Trans id={786}/>}
                        name='title'
                        onChange={p.handleInputChange}
                        required
                        tid='786'
                        validationChecks={inputs.title}
                        validationShow={p.validationShow || p.validationShowNew}
                        validationAct={p.validated}
                        validationSet={p.setValidated}
                        value={addCourseService.title}
                        visible={true}
                    />
                }
                />
            </form>
            {p.children}
        </div>
    )
}

export default withGlobalState(ComplexCourseServiceForm)
