import React from 'react'

const FormLabel = (p) => {
    return (
        <label
            className='e-form__label'
            htmlFor={p.name}
            required={typeof p.required !== 'undefined'}
        >
            {p.error}{p.label}{typeof p.required !== 'undefined' && <span className='c-pr-2 f-w-9 f-s-20'>&nbsp;*</span>}
        </label>
    )
}

export default FormLabel
