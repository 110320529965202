import React from 'react';
import {withGlobalState} from "react-globally";
import Infobox from "../../../components/elements/message/Infobox";
import LB from "../../../components/widgets/message/LB";
import Trans from "../../../components/elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

const ActiveCourseProductLB = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    /************** global **************/
    const transState = useSelector((state) => state.trans)

    return (
        <LB
            actions={
                <>
                    <li>
                        {p.readyToPublished
                            ? <button
                                className='e-btn is--orange'
                                onClick={() => p.inactiveCourseProduct(p.cid, p.pid, false)}
                                tid='824'
                            ><Trans id={824}/></button>
                            : <button
                                className='e-btn is--orange'
                                onClick={() => p.inactiveCourseProduct(p.cid, p.pid, true)}
                                tid='825'
                            ><Trans id={825}/></button>
                        }
                    </li>
                    <li>
                        <button
                            className='e-btn'
                            onClick={() => p.closeLightbox()}
                            tid='826'
                        ><Trans id={826}/></button>
                    </li>
                </>
            }
            addClasses='is--big'
            content={
                <Infobox
                    addClasses={'is--bg-pri6 is--no-border is--icon-left c-w'}
                    content={p.readyToPublished
                        ? <Trans id={827}/>
                        : <Trans id={828}/>
                    }
                    icon={'attention'}
                    tid='827, 828, 829, 830'
                    title={p.readyToPublished
                        ? getTrans(transState, 829)
                        : getTrans(transState, 830)
                    }
                />
            }
            onClose={p.closeLightbox}
            open={p.courseProductInactiveLB}
            tid='831, 832'
            title={p.readyToPublished
                ? getTrans(transState, 831)
                : getTrans(transState, 832)
            }
        />
    )
}

export default withGlobalState(ActiveCourseProductLB)
