import React from "react";
import {withGlobalState} from "react-globally";
import LB from "../../../components/widgets/message/LB";
import {getDoubleString, getUserSalutationFromString} from "../../../services/ServiceData";
import Trans from "../../../components/elements/trans/Trans";
import {getDateString} from "../../../services/ServiceDate";
import FormLoad from "../../../components/elements/form/FormLoad";

const OrderDetailLB = (p) => {
    if (p.actO !== null) {
        const o = p.actO.row.original
        const ad = JSON.parse(o.addressJson)
        const ba = ad.billingAddress
        const sa = ad.shippingAddress

        return (
            <LB
                actions={
                    <>
                        <li>
                            <button
                                className='e-btn is--orange'
                                onClick={p.cL}
                                tid='503'
                            ><Trans id={503}/></button>
                        </li>
                        <li>
                            {o.isProcessedByRetailer
                                ? <button
                                    className={'e-btn is--orange'}
                                    onClick={() => p.setActOrderStatus(o.id, false)}
                                    title={<Trans id={518}/>}
                                    tid='518'
                                ><Trans id={518}/></button>
                                : <button
                                    className={'e-btn'}
                                    onClick={() => p.setActOrderStatus(o.id)}
                                    title={<Trans id={519}/>}
                                    tid='519'
                                ><Trans id={519}/></button>
                            }
                        </li>
                    </>
                }
                addClasses='is--big'
                content={
                    <div>
                        {!p.lO
                            ? <>
                                <table className='e-table'>
                                    <tr>
                                        <th tid='515'><Trans id={515}/></th>
                                        <th tid='499'><Trans id={499}/></th>
                                        <th tid='514'><Trans id={514}/></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>{getUserSalutationFromString(ba.firstname, ba.lastname, ba.salutation, ba.title)}</p>
                                            <p>{ba.street} {ba.housenumber}</p>
                                            {ba.additionalInformation &&
                                            <p>{ba.additionalInformation}</p>
                                            }
                                            <p>{ba.zip} {ba.city}</p>
                                            <p>{ba.country}</p>
                                        </td>
                                        <td>
                                            <p>{getUserSalutationFromString(sa.firstname, sa.lastname, sa.salutation, sa.title)}</p>
                                            <p>{sa.street} {sa.housenumber}</p>
                                            {sa.additionalInformation &&
                                            <p>{sa.additionalInformation}</p>
                                            }
                                            <p>{sa.zip} {sa.city}</p>
                                            <p>{sa.country}</p>
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td tid='492'><Trans id={492}/></td>
                                                    <td tid='520'><Trans id={520}/></td>
                                                    <td tid='510'><Trans id={510}/></td>
                                                    <td tid='511'><Trans id={511}/></td>
                                                </tr>
                                                {o.orderItems.map((item) => {
                                                    try {
                                                        const p = JSON.parse(item.productJson)
                                                        return (
                                                            <tr key={p.productNumber}>
                                                                <td>{p.productNumber} {p.productNumberpublic &&
                                                                <> / {p.productNumberpublic}</>
                                                                }</td>
                                                                <td>
                                                                    {p.startdate &&
                                                                    <>{getDateString(new Date(p.startdate))}</>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {getDoubleString(p.totPrice)}&euro;
                                                                </td>
                                                                <td>
                                                                    {p.tax}
                                                                </td>
                                                            </tr>
                                                        )
                                                    } catch (e) {
                                                        return ('')
                                                    }
                                                })}
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <table className='e-table'>
                                    <tr>
                                        <th tid='530'><Trans id={530}/></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>E-Mail: {ad.customerEmail}</p>
                                            {ad.customerEmail &&
                                            <p>Tel: {ad.customerEmail}</p>
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </>
                            : <FormLoad a={true}/>
                        }
                    </div>
                }
                onClose={p.cL}
                open={p.oDLB}
                title={<Trans id={500}/>}
            />
        )
    }else {
        return ('')
    }
}

export default withGlobalState(OrderDetailLB)
