import React from 'react'
import Breadcrumb from "../components/widgets/structuring/Breadcrumb";
import PageHead from "../components/layout/PageHead";
import {withGlobalState} from "react-globally";

const Page = (p) => {
    return (
        <>
            <Breadcrumb breadcrumb={p.breadcrumb}/>
            <PageHead
                actions={p.actions}
                tid={(p.tid)? p.tid : ''}
                title={p.title}
            />
            <div className={`c-c__content ${(p.addClasses)? p.addClasses : ''}`}>
                {p.contentElements}
            </div>
        </>
    )
}

export default withGlobalState(Page)
