import React from 'react';
import {withGlobalState} from "react-globally";
import {getDateString} from "../../../services/ServiceDate";
import Trans from "../../elements/trans/Trans";
import {getTrans} from "../../../services/ServiceTrans";
import {useSelector} from "react-redux";

const ListItemUnit = (p) => {
    /** »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»
     ---------------------------------- STATES
     »»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»» **/

    const unit = p.unit
    const transState = useSelector((state) => state.trans)

    return (
        <li className='is--info' data-seq={p.seq}>
            <div className='c-itemlist__content'>
                <div className='p-b-6'>
                    <p className='c-font-darkgrey'>{unit.title}</p>
                </div>
                {unit.startDate !== null &&
                    <p className='c-itemlist__content-title f-s-12' tid={'495'}>von {getDateString(new Date(unit.startDate))} <Trans id={495}/></p>
                }
                {unit.endDate !== null &&
                    <p className='c-itemlist__content-title f-s-12' tid={'495'}>bis {getDateString(new Date(unit.endDate))} <Trans id={495}/></p>
                }
            </div>
            <div className='fl'>
                {p.index !== (p.amount - 1) &&
                    <button
                        className={`e-btn__action ico-down m-r-6`}
                        onClick={e => p.handleChangePosBtn(unit.sequence, 'up', e)}
                        tid={'906'}
                        title={getTrans(transState, 906)}
                    />
                }
                {p.index !== 0 &&
                    <button
                    className='e-btn__action ico-up m-r-6'
                    onClick={e => p.handleChangePosBtn(unit.sequence, 'down', e)}
                    tid={'905'}
                    title={getTrans(transState, 905)}
                    />
                }
                <button
                    className='e-btn__action ico-pencil'
                    onClick={e => p.onclick(unit.id, e)}
                    tid={'586'}
                    title={getTrans(transState, 586)}
                />
            </div>
        </li>
    )
}

export default withGlobalState(ListItemUnit)
